import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useParams } from 'react-router-dom';
import './css/user.css'
import error from '../img/error.svg'
import Grid from '../components/Grid'

export default function User() {
  // Initialise hook to check if page is loaded
  const [isUserValid, setUserValidity] = useState(null);

  // Getting user id from url
  const { username } = useParams();
  document.title = "Girls 4 You | " + username;

  // Hook for Storing Server Response
  const [serverResponse, updateServerResponse] = useState([{}]);
  const [services, setServices] = useState([]);
  const [ProfilePhotos, setProfilePhotos] = useState([{}]);
  const [profiles, loadMoreProfiles] = useState([]);
  const [Working_Hours, Set_Working_Hours] = useState([]);

  function formatTime(time) {
    var hours = parseInt(time.substring(0, 2));
    var minutes = time.substring(3);
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ':' + minutes + ' ' + ampm;
  }



  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? ProfilePhotos.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === ProfilePhotos.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  }

  useEffect(() => {
    setUserValidity(null);
    const fetchProfile = async () => {
      // Data for API CALL
      const dataForAPICall = {
        "apiKey": "!IO23egz7!&!Gguow9x",
        "function_called": "getUserById",
        "User_ID": username
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(dataForAPICall),
        header: {
          "Content-Type": "application/json",
        }
      };
      await fetch(process.env.REACT_APP_API, options)
        .then(response => response.json())
        .then(response => {
          if (response["status"] === true) {
            setUserValidity(true);
            updateServerResponse(response["data"][0]);
            setCurrentIndex(0);
            if (JSON.parse(response["data"][0]["Working_Hours"] !== null) && JSON.parse(response["data"][0]["Working_Hours"].length) > 0) {
              Set_Working_Hours(JSON.parse(response["data"][0]["Working_Hours"]));
            }
          } else {
            setUserValidity(false);
          }
        }
        )
        .catch(err => console.error(err));
    }
    fetchProfile();
  }, [username]);

  function lastActive(Last_Logged_In) {
    // Set the timezone
    const timezone = 'Pacific/Auckland';
    const options = { timeZone: timezone };

    // Create a new date object with the timezone set
    const date = new Date().toLocaleString('en-US', options);

    // console.log(date); // Output: "3/27/2023, 4:34:10 AM"

    const loggedInAt = new Date(Last_Logged_In);
    const now = new Date(date);
    const diff = now.getTime() - loggedInAt.getTime(); // get difference in milliseconds
    const diffInSeconds = Math.round(diff / 1000); // convert to seconds and round

    let timeAgo;
    if (diffInSeconds < 60) {
      timeAgo = `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60); // convert to minutes and round down
      timeAgo = `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600); // convert to hours and round down
      const diffInMinutes = Math.floor((diffInSeconds % 3600) / 60); // get remaining minutes
      timeAgo = `${diffInHours} hr${diffInHours === 1 ? '' : 's'} ${diffInMinutes} min${diffInMinutes === 1 ? '' : 's'} ago`;
    } else {
      const diffInDays = Math.floor(diffInSeconds / 86400); // convert to days and round down
      timeAgo = `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
    }

    return timeAgo;
  }

  useEffect(() => {
    if (serverResponse.Account_Type === "Agency") {
      // Get Affiliated Profiles
      const getAffiliatedProfiles = async () => {
        // Data for Fetching user services
        const dataForAPICall = {
          "apiKey": "!IO23egz7!&!Gguow9x",
          "function_called": "getAffiliatedProfiles",
          "id": username
        }
        const options = {
          method: 'POST',
          body: JSON.stringify(dataForAPICall),
          header: {
            "Content-Type": "application/json",
          }
        };
        await fetch(process.env.REACT_APP_API, options)
          .then(response => response.json())
          .then(response => {
            if (response["status"] === true) {
              loadMoreProfiles(response["data"]);
            } else {
              loadMoreProfiles([]);
            }
          }
          )
          .catch(err => console.error(err));
      }
      getAffiliatedProfiles();
    } else {
      const fetchSupremeProfiles = async () => {

        const data = {
          "apiKey": "!IO23egz7!&!Gguow9x",
          "function_called": "filteredProfiles",
          "Account_Type": "Individual",
          "Subs_Category": "SUPREME",
          "filterBy": "ALL"
        }
        const options = {
          method: 'POST',
          body: JSON.stringify(data),
          header: {
            "Content-Type": "application/json",
          }
        };
        await fetch(process.env.REACT_APP_API, options)
          .then(response => response.json())
          .then(response => {
            response["status"] === true ? loadMoreProfiles(response["data"]) : loadMoreProfiles([])
          }
          )
          .catch(err => console.error(err));
      }
      fetchSupremeProfiles();
    }
  }, [serverResponse.Account_Type, username])

  useEffect(() => {
    if (isUserValid === true) {
      const fetchProfilePhotos = async () => {
        // Data for Fetching user services
        const dataForAPICall = {
          "apiKey": "!IO23egz7!&!Gguow9x",
          "function_called": "ProfilePhotos",
          "id": username
        }

        const options = {
          method: 'POST',
          body: JSON.stringify(dataForAPICall),
          header: {
            "Content-Type": "application/json",
          }
        };
        await fetch(process.env.REACT_APP_API, options)
          .then(response => response.json())
          .then(response => {
            if (response.status === true) {
              setProfilePhotos(response.data);
            } else {
              setProfilePhotos([]);
            }
          }
          )
          .catch(err => console.error(err));
      }
      const fetchUserServices = async () => {
        // Data for Fetching user services
        const dataForAPICall = {
          "apiKey": "!IO23egz7!&!Gguow9x",
          "function_called": "Services",
          "LogIn_ID": username
        }

        const options = {
          method: 'POST',
          body: JSON.stringify(dataForAPICall),
          header: {
            "Content-Type": "application/json",
          }
        };
        await fetch(process.env.REACT_APP_API, options)
          .then(response => response.json())
          .then(response => {
            if (response["status"] === true) {
              setServices(response["data"]);
            }
          }
          )
          .catch(err => console.error(err));
      }
      const setProfileViewCount = async () => {
        let viewsSetFor = [];
        if (localStorage.getItem('viewsSetFor')) {
          viewsSetFor = JSON.parse(localStorage.getItem('viewsSetFor'));
        }
        if (viewsSetFor.includes(username)) return;

        // Data for API CALL
        const dataForAPICall = {
          "apiKey": "!IO23egz7!&!Gguow9x",
          "function_called": "setProfileViewCount",
          "User_ID": username
        }

        const options = {
          method: 'POST',
          body: JSON.stringify(dataForAPICall),
          header: {
            "Content-Type": "application/json",
          }
        };
        await fetch(process.env.REACT_APP_API, options)
          .then(response => response.json())
          .then(response => {
            if (response["status"] === true) {
              viewsSetFor.push(username);
              localStorage.setItem("viewsSetFor", JSON.stringify(viewsSetFor));
            }
          }
          )
          .catch(err => console.error(err));
      }
      setProfileViewCount();
      fetchProfilePhotos();
      fetchUserServices();
    }
  }, [isUserValid, username])

  const dataForGrid = {
    heading: serverResponse.Account_Type === "Agency" ? serverResponse.User_Name + "'s Listings" : "Related Profiles",
    icon: serverResponse.Account_Type === "Agency" ? "agency-icon" : "supreme-icon",
    profiles: profiles
  }


  return (
    <>
      <Header />
      <div className='primary-bg'>
        <div className="user-profile-layout">
          {
            (isUserValid === true) ?
              <>
                <div className="profile-grid primary-bg">

                  <div className="photo-gallery">

                    <div className="slideshow">
                      <div className="slides">
                        <label htmlFor="imageNumberText">{ProfilePhotos.length > 0 ? currentIndex + 1 : 0} / {ProfilePhotos.length}</label>
                        <span className="prev" onClick={prevSlide}>❮</span>
                        <span className="next" onClick={nextSlide}>❯</span>
                        <img src={ProfilePhotos.length > 0 ? ProfilePhotos[currentIndex].url : "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"} alt="" />
                      </div>
                      <div className="photo-indicator">
                        {
                          ProfilePhotos.map((index, slideIndex) => {
                            return <span key={slideIndex} className={`dot${slideIndex === currentIndex ? " active_dot" : ""}`} onClick={() => goToSlide(slideIndex)}></span>
                          })
                        }
                      </div> <br />
                      <h1 style={{ textTransform: "capitalize", textAlign: "center", fontSize: "larger" }} className='secondary-text'>{serverResponse.Tagline}</h1> <br />
                    </div>
                  </div>

                  <div className='profile-details'>
                    <div className="info">

                      <h2 className='secondary-text'>{serverResponse.User_Name} <label htmlFor="icon" className={serverResponse.Subs_Category === "SUPREME" ? "crown-icon" : serverResponse.Subs_Category === "DIAMOND" ? 'diamond' : ''}></label> {serverResponse.Is_Photos_Verified === "YES" ? <span className='verifiedText'><i className='bx bxs-camera'></i> Verified Photos</span> : <span className='unverifiedText'><i className='bx bxs-camera-off'></i> Photos Not Verified</span>}</h2>
                      <ul>
                        <li className='secondary-text'><i className='bx bxs-map'></i> {serverResponse.City}</li>
                        <li className={serverResponse.Is_Active === '0' ? 'offline' : 'online'}><i className='bx bxs-time-five'></i> {serverResponse.Is_Active === '1' ? "Online" : lastActive(serverResponse.Last_Logged_In)} <i className='bx bx-radio-circle-marked bx-flashing' ></i></li>
                      </ul>

                      {
                        serverResponse.Account_Type === 'Individual' ?
                          <>
                            <div className='major-tokens'>

                              <label htmlFor="token">
                                <span className='token-text'>Age</span>
                                <span className='token-value'>{serverResponse.Age}</span>
                              </label>

                              <label htmlFor="token">
                                <span className='token-text'>Height</span>
                                <span className='token-value'>{serverResponse.Height}</span>
                              </label>

                              <label htmlFor="token">
                                <span className='token-text'>Weight</span>
                                <span className='token-value'>{serverResponse.Weight}</span>
                              </label>

                              <label htmlFor="token">
                                <span className='token-text'>Gender</span>
                                <span className='token-value'>{serverResponse.Gender}</span>
                              </label>

                              <label htmlFor="token">
                                <span className='token-text'>Price <sub>/ Hour</sub></span>
                                <span className='token-value'>${serverResponse.Charges}</span>
                              </label>

                              <label htmlFor="token">
                                <span className='token-text'>Price <sub>/ 30min</sub></span>
                                <span className='token-value'>${serverResponse.Charges30min}</span>
                              </label>
                            </div>
                            <div className='minor-tokens'>
                              {(serverResponse.Gender === "Female" || serverResponse.Gender === "Trans") ?
                                <>
                                  <label>Breast Size: {serverResponse.Breast_Size}</label>
                                  <label>Breast Type: {serverResponse.Breast_Type}</label>
                                </>
                                :
                                <>
                                  <label>Penis Size: {serverResponse.Penis_Size} inch</label>
                                </>
                              }
                              <label>Body Type: {serverResponse.Body_Type}</label>
                              <label>Hair Color: {serverResponse.Hair_Color}</label>
                              <label>Eye Color: {serverResponse.Eye_Color}</label>
                              <label>Pubic Hair: {serverResponse.Pubic_Hair}</label>
                              <label>Meeting With: {serverResponse.Meeting_With}</label>
                              <label>In Call: {serverResponse.InCall}</label>
                              <label>Out Call: {serverResponse.OutCall}</label>
                              <label>Geography: {serverResponse.Geography}</label>
                              {
                                (serverResponse.Nationality !== null && serverResponse.Nationality.length > 0) ?
                                  <label>Nationality: {serverResponse.Nationality}</label> :
                                  <></>
                              }
                            </div>
                          </>
                          :
                          <></>
                      }

                      <p className='gray-text' dangerouslySetInnerHTML={{ __html: serverResponse.Account_Description }}>

                      </p>

                      <div className="contact">
                        <h3 className='secondary-text'>Contact <i className='bx bx-mobile' ></i></h3>

                        <label className='white-text' htmlFor="text">Tell you saw advertisement in girls4you, thanks!
                          Preferred contact method: <span>{serverResponse.Preferred_Contact_Method}</span></label>

                        <div>
                          <label className='white-text' htmlFor="mobileno"><i className='bx bx-mobile' ></i> {serverResponse.Contact_No}</label>
                          <label className='white-text' htmlFor="whatsapp"><i className='bx bxs-send bx-flashing' ></i> {serverResponse.Preferred_Contact_Method}</label>
                        </div>
                      </div>
                      {
                        services.length > 0
                          ?
                          <div>
                            <h3 className='secondary-text'>Services Offered</h3><br /><br />
                            <table>
                              <thead className='white-text'>
                                <tr>
                                  <th>Service</th>
                                  <th>Charges</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  services.map(service => {
                                    return (
                                      <tr className='white-text' key={service.SR_ID}>
                                        <td>{service.Service_Name}</td>
                                        <td>{service.Service_Charge}</td>
                                      </tr>
                                    )
                                  })
                                }

                              </tbody>
                            </table>
                            <br />

                            {
                              (Working_Hours.filter((h) => !h.isHoliday).length > 0)
                                ?
                                <>
                                  <h3 className='secondary-text'>Availablity</h3><br /><br />
                                  <table>
                                    <thead className='white-text'>
                                      <tr>
                                        <th>Day</th>
                                        <th>From</th>
                                        <th>To</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Working_Hours.filter((h) => !h.isHoliday).map(({ day, startTime, endTime, isHoliday }) => (
                                        <tr key={day} className='white-text'>
                                          <td>{day}</td>
                                          <td>{formatTime(startTime)}</td>
                                          <td>{formatTime(endTime)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                                :
                                <></>
                            }
                          </div>
                          :
                          <></>
                      }

                      {
                        serverResponse.Video_Url !== null ?
                          <div className="display-video-profile">
                            <h3 className='white-text'>Check Out <span className='secondary-text'>{serverResponse.User_Name}</span> Video Profile &nbsp; <i className='bx bxs-video' ></i></h3>
                            <video src={serverResponse.Video_Url} controls muted hidden={serverResponse.Video_Url === null ? true : false}></video>
                          </div>
                          :
                          <></>
                      }

                    </div>
                    <br />
                    <div className="profile-photos">
                      {
                        ProfilePhotos.length > 0 ?
                          <h3 className='white-text'><span className='secondary-text'>{serverResponse.User_Name}'s</span> Stunning Photos &nbsp; <i className='bx bxs-photo-album'></i></h3>
                          :
                          <></>
                      }

                      {
                        ProfilePhotos.map((photo, index) => {
                          return (
                            <img key={index} src={photo.url} alt="" />
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <br /><br />
                {
                  (profiles.length === 0) ? "" : <Grid GridData={dataForGrid} />
                }
              </>
              :
              (isUserValid === false) ?
                <div className="invalid-user-template">
                  <img src={error} alt="" />
                </div>
                :
                <div style={{ height: "90vh" }} className="blank_area">
                  <div className="loader"></div>
                </div>
          }
        </div >
      </div>
    </>
  )
}