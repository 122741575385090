import React, { useEffect, useState } from 'react'
import './css/login.css'
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';

export default function Login() {
    document.title = "Girls 4 You | Login";
    const { authType } = useParams();
    const [whichFormToShow, updateWhichFormToShow] = useState(authType);
    useEffect(() => {
        updateWhichFormToShow(authType);
    }, [authType])

    const navigate = useNavigate();
    // Login User
    const [userId, setUserId] = useState("");
    const [userPass, setUserPass] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const getID = (e) => {
        setUserId(e.target.value);
    }

    const getPassword = (e) => {
        setUserPass(e.target.value);
    }

    useEffect(() => {
        const isCredentialsStored = () => {
            const credentials = JSON.parse(localStorage.getItem("credentials"));
            if (credentials !== null && credentials.length > 0) {
                setIsLoggedIn(true);
            }
        }
        isCredentialsStored();
    }, []);



    const checkIfLoggedIn = () => {
        if (isLoggedIn === true) {
            const credentials = JSON.parse(localStorage.getItem("credentials"));
            navigate(generatePath("/dashboard/:username", { username: credentials[0].username }));
        }
    }


    const LoginUser = async () => {
        // Data for API CALL
        const dataForAPICall = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "login",
            "id": userId.substring(0, userId.indexOf("@")),
            "password": userPass
        }

        if (userId.length !== 0 && userPass.length !== 0) {
            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        setIsLoggedIn(true);
                        const credentials = [{ "accountType": response["accountType"], "username": userId.substring(0, userId.indexOf("@")), "password": userPass }];
                        localStorage.setItem("credentials", JSON.stringify(credentials));
                        navigate(generatePath("/dashboard/:username", { username: userId.substring(0, userId.indexOf("@")) }));
                    } else {
                        alert("Invalid Credentials");
                    }
                }

                )
                .catch(err => console.error(err));
        } else {
            alert("Please Enter Login Details");
        }
    }

    // Create Account
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [createPass, setCreatePass] = useState("");
    const [Subs_Plan, setSubs_Plan] = useState("");
    const [SignUpFormCounter, UpdateSignUpFormCounter] = useState(0);

    const createAccount = async () => {

        function validateUserName(fullName) {
            const regex = /^[a-zA-Z\s]+$/;
            return regex.test(fullName);
        }


        function isValidEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        }


        function isValidPhoneNumber(phoneNumber) {
            const phoneRegex = /^\d+$/; // Matches any numeric string
            return phoneRegex.test(phoneNumber);
        }


        if (fullName.trim().length === 0 || email.trim().length === 0 || phone.trim().length === 0 || createPass.trim().length === 0 || Subs_Plan.trim().length === 0) {
            alert("Please Fill Out All Required Fields");
            return;
        }

        if (!validateUserName(fullName)) {
            // Full name is valid
            alert('User name should only contain letters');
            return;
        }


        if (!isValidEmail(email)) {
            // show an error message to the user
            alert('Please enter a valid email address');
            return;
        }

        if (!isValidPhoneNumber(phone)) {
            // show an error message to the user
            alert('Mobile Number must be numeric');
            return;
        }

        // Data for API CALL
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "createAccount",
            "accountType": accountType.trim(),
            "fullName": fullName.trim(),
            "email": email.trim(),
            "phone": phone.trim(),
            "createPass": createPass.trim(),
            "Subs_Plan": Subs_Plan.trim()
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setFullName("");
                    setPhone("");
                    setCreatePass("");
                    setIsLoggedIn(true);
                    const credentials = [{ "accountType": accountType, "username": email.substring(0, email.indexOf("@")), "password": createPass }];
                    localStorage.setItem("credentials", JSON.stringify(credentials));
                    navigate(generatePath("/dashboard/:username", { username: email.substring(0, email.indexOf("@")) }));
                } else {
                    alert(response["data"]);
                }
            }
            )
            .catch(err => console.error(err));

    }
    const [accountType, setAccountType] = useState("Individual");

    useEffect(() => {
        if (accountType === "Individual") {
            setSubs_Plan("DIAMOND-30")
        } else if (accountType === "Client") {
            setSubs_Plan("CLIENT-PACK")
        } else if (accountType === "Agency") {
            setSubs_Plan("ADIAMOND-30")
        }
    }, [accountType]);


    const handleFormVisibility = (event) => {
        if (event.target.id === 'showSignUpForm') {
            navigate('/auth/signUp');
        } else if (event.target.id === 'showLoginForm') {
            navigate('/auth/login');
        } else {
            navigate('/auth/reset');
        }
    }

    // Reset Password
    const [resetPassFor, setResetPassForEmail] = useState("");
    const [OTP, setOTP] = useState("");
    const [newPass, setNewPass] = useState("");
    const [OTPSentStatus, setOTPSentStatus] = useState(false);
    const [resetMessage, setResetMessage] = useState("");
    const [LoadingIcon, showLoadingIcon] = useState(false);
    const sendOTP = async (e) => {
        e.preventDefault();
        function isValidEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        }


        if (!isValidEmail(resetPassFor)) {
            // show an error message to the user
            alert('Please enter a valid email address');
            return;
        }
        showLoadingIcon(true);
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "sendOTP",
            "email": resetPassFor.trim(),
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setOTPSentStatus(true);
                    setResetMessage("OTP Sent Successfully");
                    showLoadingIcon(false);
                } else {
                    setResetMessage(response["data"]);
                    showLoadingIcon(false);
                }
            }
            )
            .catch(err => console.error(err));

    }

    const changeUserPassword = async (e) => {
        e.preventDefault();
        function isValidEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        }


        if (!isValidEmail(resetPassFor)) {
            // show an error message to the user
            alert('Please enter a valid email address');
            return;
        }

        showLoadingIcon(true);

        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "changeUserPassword",
            "email": resetPassFor.trim(),
            "otp": OTP,
            "newPassword": newPass
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setResetMessage(response['data']);
                    setResetPassForEmail("");
                    setOTP("");
                    setNewPass("");
                    setOTPSentStatus("");
                    showLoadingIcon(false);
                } else {
                    setResetMessage(response['data']);
                    showLoadingIcon(false);
                }
            }
            )
            .catch(err => console.error(err));


    }


    return (
        <>
            <Header />
            <div className='form_holder primary-bg'>
                <div className="forms_container">

                    <div className={whichFormToShow === "login" ? "visible" : "hidden"}>
                        <div className='login_form_container'>
                            <form action="">
                                <h4>Sign In <i className='bx bxs-lock-alt bx-tada' ></i></h4>
                                <div className={isLoggedIn === true ? "hidden" : "visible"}>
                                    <input className='input' type="text" id='Login_ID' placeholder='Email' value={userId} onChange={getID} /> <br /><br />
                                    <input className='input' type="password" name="password" id="Password" placeholder='Password' value={userPass} onChange={getPassword} />
                                </div>
                                <h3 className={isLoggedIn === true ? "visible" : "hidden"}>Welcome Back</h3>
                                <input onClick={isLoggedIn === true ? checkIfLoggedIn : LoginUser} className='submit_btn' type="button" value={isLoggedIn === true ? "Go To Dashboard" : "Login"} />
                                <label id='' htmlFor="text" onClick={handleFormVisibility}>Forgot Password?</label>
                                <label id='showSignUpForm' onClick={handleFormVisibility} htmlFor="text">New User? Create Account</label>
                            </form>
                        </div>
                    </div>

                    <div className={whichFormToShow === "reset" ? "visible" : "hidden"}>
                        <div className='reser_Password_form_container'>
                            <form action="">
                                <h4>Reset Password <i className='bx bxs-lock-alt bx-tada' ></i></h4>
                                <input className='input' type="text" id='reset_login_id' placeholder='Email' value={resetPassFor} onChange={(e) => { setResetPassForEmail(e.target.value) }} required />
                                <input className='input' type="text" placeholder='Enter OTP' onChange={(e) => { setOTP(e.target.value) }} hidden={OTPSentStatus === true ? false : true} />
                                <input className='input' type="text" placeholder='New Password' onChange={(e) => { setNewPass(e.target.value) }} hidden={OTPSentStatus === true ? false : true} />
                                {/* <input onClick={OTPSentStatus === true ? changeUserPassword : sendOTP} className='submit_btn' type="button" value={OTPSentStatus === true ? "Change Password" : "Send OTP"} /> */}
                                <button onClick={OTPSentStatus === true ? changeUserPassword : sendOTP} className='submit_btn'>{OTPSentStatus === true ? "Change Password" : "Send OTP"} {LoadingIcon === true ? <i className='bx bx-loader-circle bx-spin' ></i> : <></>}</button>
                                <span style={{ fontSize: "small", textAlign: "center" }}>{resetMessage}</span>
                            </form>
                        </div>
                    </div>

                    <div className={whichFormToShow === "signUp" ? "visible" : "hidden"}>
                        <div className="signup_form_container">
                            <form action="">
                                <h4>Create New Account <i className='bx bxs-lock-alt bx-tada' ></i></h4>

                                <div className={SignUpFormCounter === 0 ? "visible" : "hidden"}>
                                    <div className='accountType'>
                                        <strong>Who are you?</strong>
                                        <input onChange={(e) => { setAccountType(e.target.value) }} className="radio isHidden" type="radio" name="accountType" id="Client" value="Client" checked={accountType === 'Client'} />
                                        <label className='label' htmlFor="Client">
                                            <span>Client</span>
                                        </label>
                                        <input onChange={(e) => { setAccountType(e.target.value) }} className="radio isHidden" type="radio" name="accountType" id="Individual" value="Individual" checked={accountType === 'Individual'} />
                                        <label className='label' htmlFor="Individual">
                                            <span>Escort</span>
                                        </label>
                                        <input onChange={(e) => { setAccountType(e.target.value) }} className="radio isHidden" type="radio" name="accountType" id="Agency" value="Agency" checked={accountType === 'Agency'} />
                                        <label className='label' htmlFor="Agency">
                                            <span>Agency</span>
                                        </label>
                                    </div>
                                </div>

                                <div className={SignUpFormCounter === 1 ? "visible" : "hidden"}>
                                    {(accountType === 'Individual') ?
                                        <div className='subscription_plans'>
                                            <div className='plan_group'>
                                                <div className="regular_plans">
                                                    <input className="radio isHidden" type="radio" name="subs_plan" id="BASIC365" value="BASIC365" onChange={(e) => { setSubs_Plan(e.target.value) }} checked={Subs_Plan === 'BASIC365'} />
                                                    <label className='label' htmlFor="BASIC365">
                                                        <span>BASIC</span>
                                                        <span>$0 <sub>/year</sub></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='plan_group'>

                                                <div className="diamond_plans">

                                                    <input className="radio isHidden" type="radio" name="subs_plan" id="DIAMOND-30" value="DIAMOND-30" onChange={(e) => { setSubs_Plan(e.target.value) }} checked={Subs_Plan === 'DIAMOND-30'} />
                                                    <label className="label" htmlFor="DIAMOND-30">
                                                        <span>DIAMOND</span>
                                                        <span>$49 <sub>/month</sub></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='plan_group'>

                                                <div className="supreme_plans">
                                                    <input className="radio isHidden" type="radio" name="subs_plan" id="SUPREME-30" value="SUPREME-30" onChange={(e) => { setSubs_Plan(e.target.value) }} checked={Subs_Plan === 'SUPREME-30'} />
                                                    <label className="label" htmlFor="SUPREME-30">
                                                        <span>SUPREME</span>
                                                        <span>$99 <sub>/month</sub></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div> :
                                        (accountType === 'Client') ?
                                            <div className='subscription_plans'>
                                                <div className='plan_group'>

                                                    <div className="diamond_plans">

                                                        <input className="radio isHidden" type="radio" name="subs_plan" id="CLIENT-PACK" value="CLIENT-PACK" onChange={(e) => { setSubs_Plan(e.target.value) }} checked={Subs_Plan === 'CLIENT-PACK'} />
                                                        <label className="label" htmlFor="CLIENT-PACK">
                                                            <span>PRO CLIENT</span>
                                                            <span>$9.99 <sub>/ Month</sub></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> :
                                            (accountType === 'Agency') ?
                                                <div className='subscription_plans'>
                                                    <div className='plan_group'>

                                                        <div className="diamond_plans">

                                                            <input className="radio isHidden" type="radio" name="subs_plan" id="ADIAMOND-30" value="ADIAMOND-30" onChange={(e) => { setSubs_Plan(e.target.value) }} checked={Subs_Plan === 'ADIAMOND-30'} />
                                                            <label className="label" htmlFor="ADIAMOND-30">
                                                                <span>DIAMOND</span>
                                                                <span>$149 <sub>/month</sub></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='plan_group'>

                                                        <div className="supreme_plans">
                                                            <input className="radio isHidden" type="radio" name="subs_plan" id="ASUPREME-30" value="ASUPREME-30" onChange={(e) => { setSubs_Plan(e.target.value) }} checked={Subs_Plan === 'ASUPREME-30'} />
                                                            <label className="label" htmlFor="ASUPREME-30">
                                                                <span>SUPREME</span>
                                                                <span>$249 <sub>/month</sub></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <></>
                                    }

                                </div>

                                <div className={SignUpFormCounter === 2 ? "visible" : "hidden"}>
                                    <div className="CreateAccountInputs">
                                        <input className='input' placeholder='Full Name' type="text" value={fullName} onChange={(e) => { setFullName(e.target.value) }} />
                                        <input className='input' placeholder='Email' type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        <input className='input' placeholder='Phone Number' type="text" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                        <input className='input' type="password" name="create_pass" id="create_pass" placeholder='Create Password' value={createPass} onChange={(e) => { setCreatePass(e.target.value) }} />
                                    </div>
                                </div>

                                <input onClick={SignUpFormCounter === 2 ? createAccount : () => { UpdateSignUpFormCounter(SignUpFormCounter + 1) }} className='submit_btn' type="button" value={SignUpFormCounter === 2 ? "Create Account" : "Next"} />
                                <label id='showLoginForm' onClick={handleFormVisibility} htmlFor="text">Already have an account? Log In here</label>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
