import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import '../components/css/cart.css'
import Header from '../components/Header';
import emptyCart from '../img/empty-cart.png'


export default function Cart() {
    document.title = "Girls 4 You | Shopping Cart";
    const navigate = useNavigate();
    // check if cart_items exists and is defined

    const cart_items_exist = localStorage.getItem('cart_items') !== null;
    const [cart_items, set_cart_items] = useState(cart_items_exist ? JSON.parse(localStorage.getItem('cart_items')) : []);

    // Calculate total price of items in cart
    let total_price = 0;
    if (cart_items_exist) {
        for (let i = 0; i < cart_items.length; i++) {
            total_price += cart_items[i].p_price * cart_items[i].quantity;
        }
    }


    const remove = (e) => {
        const id = e.target.getAttribute("data-pid");
        if (window.confirm("Do you want to remove this product from shopping cart?")) {
            set_cart_items(cart_items.filter(item => item.id !== id))
        }
    }
    useEffect(() => {
        localStorage.setItem("cart_items", JSON.stringify(cart_items));
    }, [cart_items]);

    function ProceedToProductPayment() {
        const buttonRef = useRef(null);

        useEffect(() => {
            const script = document.createElement('script');
            script.src = 'https://dev.shift4.com/checkout.js';
            script.async = true;
            script.setAttribute('data-env', 'sandbox');

            script.onload = () => {
                const buttonElement = buttonRef.current;

                const handleProductPurchase = async () => {
                    try {
                        const cart_items = JSON.parse(localStorage.getItem('cart_items'));
                        const response = await fetch('https://www.girls4you.co.nz/backend/payments/create_checkout.php', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                payment_type: 'products',
                                cart_items: cart_items
                            })
                        });
                        const data = await response.json();
                        window.Shift4Checkout.key = 'pk_test_7FmEbOnpzwTvklX9Tn2MRioc';
                        window.Shift4Checkout.success = function (result) {
                            // handle successful payment by hitting the API
                            fetch('https://www.girls4you.co.nz/backend/payments/payment_success.php', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    payment_type: 'products',
                                    cart_items: cart_items,
                                    result: result,
                                    total_price: total_price
                                })
                            })
                                .then(response => response.json())
                                .then(data => {
                                    // handle response from API
                                    if (data["status"] === true) {
                                        localStorage.removeItem("cart_items");
                                        navigate('/order-confirmation/success/' + data["message"]);
                                    } else {
                                        navigate('/order-confirmation/error/');
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        };
                        window.Shift4Checkout.error = function (errorMessage) {
                            // handle integration errors (e.g. send error notification to your server)
                        };
                        window.Shift4Checkout.open({
                            checkoutRequest: data.signedCheckoutRequest,
                            name: 'Checkout',
                            description: '',
                            shippingAddress: true
                        });
                    } catch (error) {
                        console.error(error);
                    }
                };


                if (buttonElement) {
                    buttonElement.addEventListener('click', handleProductPurchase);
                }


                return () => {
                    buttonElement.removeEventListener('click', handleProductPurchase);
                };
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }, []);

        return (
            <button style={{ padding: "1em", textTransform: "uppercase", backgroundColor: "transparent", outline: "none", border: "2px solid var(--secondary-color)", color: "white", fontWeight: "600", fontSize: "medium", cursor: "pointer", borderRadius: "0.4em" }} ref={buttonRef}>Proceed To Pay</button>
        );
    }

    if (cart_items_exist && cart_items.length > 0) {
        return (
            <>
                <Header />
                {/* <div className='shopping-cart primary-bg'>
                    <div className="cart-container">
                        <h2 style={{ color: "whitesmoke", display: "flex", alignItems: "center", gap: "0.4em", flexWrap: "nowrap" }}>Shopping Cart <i className='bx bxs-cart'></i></h2> <br />
                        <div className='cart-items'>
                            {
                                cart_items.map((item, index) => {
                                    return (
                                        <div className="cart-item" key={index}>
                                            <img src={item.thumbnail} alt="" />
                                            <div style={{ cursor: "pointer" }} className='item-info'>
                                                <div>
                                                    <label style={{ fontSize: "large", fontWeight: "600" }} className='secondary-text' htmlFor="p_name">{item.p_name}</label>
                                                    <label style={{ fontWeight: "600" }} className='secondary-text' htmlFor="p_price">$ {item.p_price}</label>
                                                </div>
                                                <div>
                                                    <label className='gray-text' htmlFor="quantity">Quantity: {item.quantity}</label>
                                                    <label data-pid={item.id} onClick={remove} style={{ cursor: "pointer" }} className='accent-text' htmlFor="quantity">Remove <i className='bx bxs-trash-alt'></i></label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <ProceedToProductPayment />
                        </div>
                    </div>
                </div> */}
                <div className="shopping_cart_container primary-bg">
                    <div className="shopping_cart">
                        {/* Products */}
                        <div className="display_cart_items">
                            <h3 className='white-text'>Shopping Cart &nbsp; <i className='bx bxs-shopping-bags'></i></h3>
                            {
                                cart_items.map((item, index) => {
                                    return (
                                        <div className="cart_item" key={index}>
                                            <img src={item.thumbnail} alt="" />
                                            <div className="item_description">
                                                <label htmlFor="item_name">{item.p_name}</label>
                                                <label htmlFor="item_price">$ {item.p_price} <sub>/ unit</sub> (NZD)</label>
                                                <div>
                                                    <label htmlFor="item_quantity">Quanity: {item.quantity}</label>
                                                    <label htmlFor="delete_btn" data-pid={item.id} onClick={remove}>Remove <i data-pid={item.id} className='bx bxs-trash-alt'></i></label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <button style={{ padding: "1em", textTransform: "uppercase", backgroundColor: "transparent", outline: "none", border: "2px solid var(--secondary-color)", color: "white", fontWeight: "600", fontSize: "medium", cursor: "pointer", borderRadius: "0.4em" }} onClick={() => { navigate('/shop') }}>Add More Products</button>
                        </div>
                        {/* Checkout Form */}
                        <div className="checkout_details">
                            <h3 style={{ textAlign: "start" }} className='white-text'>Order Summary</h3>
                            <div className='order_summary'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Price x Quantity</th>
                                            <th>Subtotal (In NZD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cart_items.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.p_name}</td>
                                                        <td>{item.p_price} x {item.quantity}</td>
                                                        <td>{(item.p_price * item.quantity).toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr style={{ borderTop: "1px solid #242424" }}>
                                            <td colSpan={2}>Total Amount</td>
                                            <td>$ {total_price.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} style={{ textAlign: "end" }}><ProceedToProductPayment /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Header />
                <div style={{ minHeight: "90vh" }} className='shopping-cart primary-bg'>
                    <div style={{ display: 'flex', flexFlow: "column nowrap", alignItems: "center" }} className="cart-container">
                        <h2 style={{ color: "whitesmoke", display: "flex", alignItems: "center", gap: "0.4em", flexWrap: "nowrap" }}>Shopping Cart <i className='bx bxs-cart'></i></h2> <br />
                        <img style={{ width: "100%", height: "400px", objectFit: "contain" }} src={emptyCart} alt="" />
                        <button onClick={() => { navigate('/shop') }} style={{ padding: "1em", textTransform: "uppercase", backgroundColor: "transparent", outline: "none", border: "2px solid var(--secondary-color)", color: "white", fontWeight: "600", fontSize: "medium", cursor: "pointer", borderRadius: "0.4em" }}>Continue Shopping</button>
                    </div>
                </div>
            </>
        )
    }

}
