import React, { useState } from 'react'

export default function TrackOrder() {
    document.title = "Girls 4 You | Track Order";

    const [orderID, setOrderID] = useState("");
    const [loader, setLoader] = useState(null);
    const [trackOrderDetails, updateTrackOrderDetails] = useState([]);
    const TrackOrder = async () => {
        if (orderID.length === 0) {
            return;
        }
        setLoader(true);
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "TrackOrder",
            "order_id": orderID
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    updateTrackOrderDetails(response["data"][0]);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
            )
            .catch(err => console.error(err));
    }
    return (
        <>
            <div className='track-order primary-bg' style={{
                maxWidth: "1600px",
                minHeight: "100vh",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1em"
            }}>
                <div className='track-order-container' style={{
                    width: "400px",
                    maxWidth: "95%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    backgroundColor: "rgba(73, 73, 73, 0.266)",
                    padding: "3em 1em",
                    borderRadius: "0.4em",
                    border: "2px solid var(--secondary-color)"
                }}>
                    <h2 style={{ textAlign: "center", fontSize: "4em" }} className='secondary-text'>Track Your Order</h2>
                    <label htmlFor="track-input" style={{ backgroundColor: "rgba(73, 73, 73, 0.266)", borderRadius: "4rem", padding: " 0.8em 1em", display: "flex", flexFlow: "row nowrap", alignItems: "center", gap: "0.4em" }}>
                        <input style={{ outline: "none", border: "none", backgroundColor: "transparent", color: "whitesmoke", width: "100%", cursor: "pointer" }} type="text" placeholder='Order ID' value={orderID} onChange={(e) => { setOrderID(e.target.value) }} />
                        <i onClick={TrackOrder} className={(loader === null) ? 'bx bxs-send' : (loader === true) ? 'bx bx-loader-circle bx-spin' : 'bx bxs-send'} style={{ color: "white", cursor: "pointer" }}></i>

                    </label>
                    <div style={{ display: trackOrderDetails.length !== 0 ? "block" : "none" }}>
                        <table style={{ width: "100%", color: "whitesmoke", backgroundColor: "rgba(73, 73, 73, 0.266)", padding: "0.6em", borderRadius: "0.4em" }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: "0.6em 0" }}>Customer Name:</td>
                                    <td style={{ textAlign: "end", textTransform: "capitalize" }}>{trackOrderDetails.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "0.6em 0" }}>Order Status:</td>
                                    <td style={{ textAlign: "end", textTransform: "capitalize" }}>{trackOrderDetails.order_status}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <br /><br />
                <div style={{ borderRadius: "0.6em", backgroundColor: "var(--secondary-color)" }}>
                    <p style={{ padding: "8px", textAlign: "justify", lineHeight: "2em", fontSize: "small" }}>
                        Dear valued customer,

                        We understand that you are eager to track the status of your order. To check the status of your order, simply enter the order ID that was provided to you at the time of purchase on our website.

                        Once you have entered your order ID, you will be able to view the current status of your order, including any tracking information that is available. This will give you a clear understanding of when your order will be delivered.

                        If you have any questions or concerns about your order, please don't hesitate to reach out to our support team. We are always here to help you and ensure that your shopping experience with us is a positive one.

                        Thank you for choosing our website for your purchase. We appreciate your business and look forward to serving you again in the future.

                        Best regards,

                        [girls4you.co.nz]
                    </p>
                </div>
            </div>
        </>
    )
}
