import React from 'react'
import Header from '../components/Header'
import TrackOrderForm from '../components/TrackOrderForm'

export default function TrackOrder() {
    document.title = "Girls 4 You | Track Order";

    return (
        <>
            <Header />
            <TrackOrderForm />
        </>
    )
}
