import React, { useEffect, useState, useRef } from 'react';
import './css/banner.css';
import { useNavigate } from 'react-router-dom';

export default function Banner(props) {
  const navigate = useNavigate();
  const [offers, setOffers] = useState(null);
  const marqueeRef = useRef(null);

  useEffect(() => {
    const fetchCoupons = async () => {
      // Data for API CALL
      const data = {
        apiKey: "!IO23egz7!&!Gguow9x",
        function_called: "fetchCouponWinners",
      };

      const options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await fetch(process.env.REACT_APP_API, options);
        const data = await response.json();
        if (data.status === true) {
          setOffers(data.data);
        } else {
          // Handle error case
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchCoupons();
  }, []);

  useEffect(() => {
    const marqueeElement = marqueeRef.current;
    if (!marqueeElement) return;

    const marqueeContentElement = marqueeElement.querySelector('.marquee-content');
    if (!marqueeContentElement) return;

    if (marqueeContentElement.offsetWidth > marqueeElement.offsetWidth) {
      const animationDuration = marqueeContentElement.offsetWidth / 50;
      marqueeContentElement.style.animationDuration = `${animationDuration}s`;
      marqueeElement.style.width = `${marqueeContentElement.offsetWidth}px`;
    }
  }, [offers]);

  const handleMouseEnter = () => {
    const marqueeElement = marqueeRef.current;
    if (marqueeElement) {
      marqueeElement.classList.add('paused');
    }
  };

  const handleMouseLeave = () => {
    const marqueeElement = marqueeRef.current;
    if (marqueeElement) {
      marqueeElement.classList.remove('paused');
    }
  };

  return (
    <div className="banner">
      <div className="overlay"></div>
      <div className="stars" aria-hidden="true"></div>
      <div className="starts2" aria-hidden="true"></div>
      <div className="stars3" aria-hidden="true"></div>
      <div className="banner-content" id="content">
        <h1 className="secondary-text txt-center">{props.banner.h1}</h1>
        <h2 className="gray-text txt-center">{props.banner.h2}</h2>
        <button
          onClick={() => {
            props.banner.BannerFor === 'Home'
              ? navigate('/auth/signUp')
              : document.getElementById('products-grid').scrollIntoView({
                behavior: 'smooth',
              });
          }}
          className="primary-btn secondary-bg primary-text"
        >
          {props.banner.buttonText} <i className="bx bxs-rocket bx-tada"></i>
        </button>
        {offers !== null && (
          <div
            className="offers-marquee"
            ref={marqueeRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => navigate('/auth/signUp')}
          >
            <span className="marquee-content">
              {offers.map((offer, index) => (
                <span key={index} className="offer-item">
                  {offer.User_Name} {offer.Winner_Line}&nbsp;|&nbsp;
                </span>

              ))}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
