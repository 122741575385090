import React, { useEffect, useState } from 'react'
import './css/header.css'
import logo from '../img/IMG_20221222_114102-removebg-preview.png'
import { Link, NavLink } from 'react-router-dom'

export default function Header(props) {
  const [username, setUsername] = useState("");
  useEffect(() => {
    if (localStorage.getItem('credentials') !== null && localStorage.getItem('credentials') !== undefined) {
      // credentials is set in localStorage
      //  Parse the JSON string into a JavaScript object
      const users = JSON.parse(localStorage.getItem("credentials"));

      // Access the username property of the object
      setUsername("Hi, " + users[0].username);
    } else {
      // credentials is not set in localStorage
      setUsername("Sign In");
    }
  }, [])
  const [mobileMenuState, setMobileMenuState] = useState(false);

  window.addEventListener("resize", function () {
    if (window.innerWidth >= 800) {
      var menu = document.querySelector("ul");
      menu.className = "nav-menu";
      setMobileMenuState(false);
    }
  })

  const toggleMenu = () => {
    var menu = document.querySelector(".header-items ul");
    if (menu.className === "nav-menu") {
      menu.className = "mobile-menu"
      setMobileMenuState(true);
    } else {
      menu.className = "nav-menu";
      setMobileMenuState(false);
    }
  }
  const handleClick = () => {
    var menu = document.querySelector(".header-items ul");
    menu.className = "nav-menu";
    setMobileMenuState(false);
  }

  return (
    <header>
      <div className="header-items">

        <div className="logo">
          <Link className='link white-text' to="/">
            <img src={logo} alt="logo" />
            <span>Girls 4 You</span>
          </Link>
        </div>


        <ul className='nav-menu'>
          <li>
            <NavLink className='link white-text' onClick={handleClick} to="/mystery-box"><i className='bx bxs-box'></i> Lucky Box</NavLink>
          </li>
          <li>
            <NavLink className='link white-text' onClick={handleClick} to="/agency"><i className='bx bxs-heart-circle' ></i> Agencies</NavLink>
          </li>
          <li>
            <NavLink className='link white-text' onClick={handleClick} to="/pricing"><i className='bx bxs-purchase-tag'></i> Pricing</NavLink>
          </li>
          {/* <li>
            <NavLink className='link white-text' onClick={handleClick} to="/shop"><i className='bx bxs-shopping-bags'></i> Shop</NavLink>
          </li> */}
          <li>
            <NavLink className='link white-text' onClick={handleClick} to="/services"><i className='bx bxs-camera'></i> Services</NavLink>
          </li>
          <li>
            <NavLink className='link white-text' onClick={handleClick} to="/auth/login"><i className="bx bxs-lock" ></i> {(mobileMenuState === true && username !== "Sign In") ? "Go To Dashboard" : username} </NavLink>
          </li>
        </ul>


        <div className="toogle-menu">
          {
            props.link !== undefined ? <i style={{
              color: "white"
            }} className='bx bxs-box' id='2' onClick={props.handleActivePane} ></i> : ""
          }
          <i style={{
            color: mobileMenuState === true ? "var(--secondary-color)" : "white"
          }} onClick={toggleMenu} className={username === "Sign In" ? "bx bx-menu-alt-right" : "bx bxs-user-circle"} ></i>
        </div>
      </div>
    </header>
  )
}
