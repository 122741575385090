import React from 'react'
import '../components/css/pricings.css'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header';

export default function Pricings() {
    document.title = "Girls 4 You | Pricing";
    const navigate = useNavigate();
    return (
        // Container for the pricing table 
        <>
            <Header />
            <div className="pricing-table primary-bg">

                {/* Second pricing card  */}

                <div className="pricing-card">
                    <h3>BASIC ESCORT <i className='bx bxs-diamond' ></i></h3>
                    <div className="price">$0/<sub>month</sub></div>
                    <ul>
                        <li>Video Profile <i className='bx bx-x'></i></li>
                        <li>Profile Photos <span>2</span></li>
                        <li>Daily Free Credits <i className='bx bx-x'></i></li>
                        <li>Credits <span>10</span></li>
                    </ul>
                    <button className='secondary-bg' onClick={() => { navigate('/auth/signUp') }}>Get Membership</button>
                </div>


                {/* Third pricing card  */}

                <div className="pricing-card">
                    <h3>DIAMOND ESCORT <i className='bx bxs-diamond' ></i></h3>
                    <div className="price">$49/<sub>month</sub></div>
                    <ul>
                        <li>Video Profile <i className='bx bx-check'></i></li>
                        <li>Profile Photos <span>5</span></li>
                        <li>Daily Free Credits <i className='bx bx-check'></i></li>
                        <li>Credits <span>30</span></li>
                    </ul>
                    <button className='secondary-bg' onClick={() => { navigate('/auth/signUp') }}>Get Membership</button>
                </div>


                {/* Fourth pricing card  */}
                <div className="pricing-card">
                    <h3>SUPREME ESCORT <i className='bx bxs-diamond' ></i></h3>
                    <div className="price">$99/<sub>month</sub></div>
                    <ul>
                        <li>Video Profile <i className='bx bx-check'></i></li>
                        <li>Profile Photos <span>10</span></li>
                        <li>Daily Free Credits <i className='bx bx-check'></i></li>
                        <li>Credits <span>60</span></li>
                    </ul>
                    <button className='secondary-bg' onClick={() => { navigate('/auth/signUp') }}>Get Membership</button>
                </div>

                {/* First pricing card  */}
                <div className="pricing-card">
                    <h3>PRO CLIENT <i className='bx bxs-diamond' ></i></h3>
                    <div className="price">$9.99/<sub>month</sub></div>
                    <ul>
                        <li>Exciting Rewards <i className='bx bx-check'></i></li>
                        <li>Win Free Massage <i className='bx bx-check'></i></li>
                        <li>Access To New features <i className='bx bx-check'></i></li>
                        <li>Access To Our Partner Stores <i className='bx bx-check'></i></li>
                    </ul>
                    <button className='secondary-bg' onClick={() => { navigate('/auth/signUp') }}>Get Membership</button>
                </div>

                {/* Fifth pricing card  */}
                <div className="pricing-card">
                    <h3>DIAMOND AGENCY <i className='bx bxs-diamond' ></i></h3>
                    <div className="price">$149/<sub>month</sub></div>
                    <ul>
                        <li>Video Profile <i className='bx bx-check'></i></li>
                        <li>Profile Photos <span>5</span></li>
                        <li>Credits <span>25</span></li>
                        <li>DIAMOND Profiles <span>UNLIMITED</span></li>
                    </ul>
                    <button className='secondary-bg' onClick={() => { navigate('/auth/signUp') }}>Get Membership</button>
                </div>

                {/* Sixth pricing card  */}
                <div className="pricing-card">
                    <h3>SUPREME AGENCY <i className='bx bxs-diamond' ></i></h3>
                    <div className="price">$249/<sub>month</sub></div>
                    <ul>
                        <li>Video Profile <i className='bx bx-check'></i></li>
                        <li>Profile Photos <span>10</span></li>
                        <li>Credits <span>25</span></li>
                        <li>SUPREME Profiles <span>UNLIMITED</span></li>
                    </ul>
                    <button className='secondary-bg' onClick={() => { navigate('/auth/signUp') }}>Get Membership</button>
                </div>


            </div>
        </>
    )
}
