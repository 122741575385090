import React from 'react'
import Header from '../components/Header'
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../components/css/product.css'
import noImage from '../img/no_image.jpg'

export default function Product() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isPageLoaded, updateIsPageLoaded] = useState(false);
  const [serverResponse, updateServerResponse] = useState({
    p_ID: "",
    p_name: "",
    p_price: "",
    thumbnail: "",
    description: ""
  });
  const [productPhotos, setProductPhotos] = useState([]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      // Data for API CALL
      const data = {
        "apiKey": "!IO23egz7!&!Gguow9x",
        "function_called": "fetchProductDetailsByID",
        "p_ID": id
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(data),
        header: {
          "Content-Type": "application/json",
        }
      };
      await fetch(process.env.REACT_APP_API, options)
        .then(response => response.json())
        .then(response => {
          if (response["status"] === true) {
            updateServerResponse(response["data"][0]);
            updateIsPageLoaded(true);
          }
        }
        )
        .catch(err => console.error(err));
    }
    const fetchProductPhotos = async () => {
      // Data for Fetching user services
      const data = {
        "apiKey": "!IO23egz7!&!Gguow9x",
        "function_called": "fetchProductPhotos",
        "p_ID": id
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(data),
        header: {
          "Content-Type": "application/json",
        }
      };
      await fetch(process.env.REACT_APP_API, options)
        .then(response => response.json())
        .then(response => {
          if (response.status === true) {
            setProductPhotos(response.data);
          }
        }
        )
        .catch(err => console.error(err));
    }
    fetchProductDetails();
    fetchProductPhotos();
  }, [id]);
  document.title = "Girls 4 You | " + serverResponse.p_name;


  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    if (productPhotos.length === 0) return;
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? productPhotos.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }
  const nextSlide = () => {
    if (productPhotos.length === 0) return;
    const isLastSlide = currentIndex === productPhotos.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  }
  const [quantity, updateQuantity] = useState(1);

  const AddToCart = (e) => {
    e.preventDefault();
    let cart_items = [];
    const p_name = serverResponse.p_name;
    const p_price = serverResponse.p_price;
    const thumbnail = serverResponse.thumbnail;
    if (localStorage.getItem('cart_items')) {
      cart_items = JSON.parse(localStorage.getItem('cart_items'));
    }

    const existingItem = cart_items.find(item => item.id === id);
    if (existingItem) {
      existingItem.quantity = quantity;
      alert("Already In Cart")
    } else {
      alert("Added To Cart");
      cart_items.push({ id, p_name, p_price, quantity, thumbnail });
    }
    localStorage.setItem('cart_items', JSON.stringify(cart_items));
    doRefreshCount(true);
  }


  const [cartCount, updateCartCount] = useState(null);
  const [refreshCount, doRefreshCount] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("cart_items") !== null) {
      let cart_items = [];
      cart_items = JSON.parse(localStorage.getItem('cart_items'));
      updateCartCount(cart_items.length);
    }
  }, [refreshCount]);

  return (
    <>
      <Header/>
      <div className='primary-bg'>
        <div className={isPageLoaded === false ? "hidden" : "visible"}>
          <div className='details-page primary-bg'>
            <div className="details-wrapper">
              <div className="details-grid">
                {/* Gird Item 1 */}
                <div className='photo-gallery'>
                  <div className="slideshow">
                    <div className="slides">
                      <label htmlFor="imageNumberText">{productPhotos.length === 0 ? 0 : currentIndex + 1} / {productPhotos.length}</label>
                      <span className="prev" onClick={prevSlide}>❮</span>
                      <span className="next" onClick={nextSlide}>❯</span>
                      <img src={productPhotos.length === 0 ? noImage : productPhotos[currentIndex].url} alt="" />
                    </div>
                    <div className="photo-indicator">
                      {
                        productPhotos.map((index, slideIndex) => {
                          return <span key={slideIndex} className={`dot${slideIndex === currentIndex ? " active_dot" : ""}`} onClick={() => goToSlide(slideIndex)}></span>
                        })
                      }
                    </div>
                  </div>
                </div>
                {/* Grid Item 2 */}
                <div className='product-info'>
                  <h1 className='secondary-text'>{serverResponse.p_name}</h1>
                  <label htmlFor="price">USD {serverResponse.p_price}</label>
                  <p className='description' dangerouslySetInnerHTML={{ __html: serverResponse.description }}></p>
                  <h4 className='secondary-text'>Select Quantity</h4>
                  <div className="qty">
                    <button onClick={() => { quantity > 1 ? updateQuantity(quantity - 1) : updateQuantity(quantity) }}>-</button>
                    <input type="text" value={quantity} required disabled />
                    <button onClick={() => { updateQuantity(quantity + 1) }}>+</button>
                  </div>
                  <div className='checkout-actions'>

                    <button onClick={AddToCart}>Add To Cart</button>

                    <button style={{
                      display: cartCount > 0 ? "block" : "none"
                    }} onClick={() => { navigate('/cart') }}>Checkout <span style={{
                      visibility: cartCount > 0 ? "visible" : "hidden"
                    }} id='cart-count'>{cartCount}</span></button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={isPageLoaded === false ? 'visible' : 'hidden'}>
          <div className="blank_area">
            <div className="loader"></div>
          </div>
        </div>
      </div>
    </>
  )
}
