import React, { useState } from 'react';
import './css/card.css';
import { generatePath, useNavigate } from 'react-router-dom';

export default function Card(props) {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  function formatName(fullName) {
    const nameParts = fullName.toString().split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.slice(-1)[0];

    if (fullName.length > 12) {
      if (lastName && lastName.length >= 6) {
        const lastNameInitial = lastName.charAt(0) + '.';
        const formattedName = `${firstName} ${lastNameInitial}`;
        return formattedName;
      } else {
        return firstName;
      }
    } else {
      return fullName;
    }
  }

  function lastActive(Last_Logged_In) {
    const timezone = 'Pacific/Auckland';
    const options = { timeZone: timezone };

    const date = new Date().toLocaleString('en-US', options);

    const loggedInAt = new Date(Last_Logged_In);
    const now = new Date(date);
    const diff = now.getTime() - loggedInAt.getTime();
    const diffInSeconds = Math.round(diff / 1000);

    let timeAgo;
    if (diffInSeconds < 60) {
      timeAgo = `${diffInSeconds} Seconds Ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      timeAgo = `${diffInMinutes} Minute${diffInMinutes === 1 ? '' : 's'} Ago`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      const diffInMinutes = Math.floor((diffInSeconds % 3600) / 60);
      timeAgo = `${diffInHours}h ${diffInMinutes}m Ago`;
    } else {
      const diffInDays = Math.floor(diffInSeconds / 86400);
      timeAgo = `${diffInDays} Day${diffInDays === 1 ? '' : 's'} Ago`;
    }

    return timeAgo;
  }

  function handleImageLoad() {
    setImageLoaded(true);
  }

  const formattedName = formatName(props.cardInfo.User_Name);

  return (
    <div
      className="card"
      onClick={() => {
        navigate(generatePath('/profile/:username', { username: props.cardInfo.LogIn_ID }));
      }}
    >
      <div className="upper-card">
        <label
          style={{
            position: 'absolute',
            top: '-14px',
            left: '-9px',
            zIndex: '1',
            transform: 'rotate(-20deg)',
          }}
          htmlFor="profile-message"
          className={
            props.cardInfo.Subs_Category === 'SUPREME'
              ? 'crown-icon'
              : props.cardInfo.Subs_Category === 'DIAMOND'
                ? 'diamond'
                : ''
          }
        ></label>
        <label
          htmlFor="badge"
          className={
            props.cardInfo.Is_Photos_Verified === 'YES' ? 'profile-verified' : ''
          }
        ></label>
        <img
          style={{ filter: isImageLoaded ? 'none' : 'blur(12px)' }}
          src={props.cardInfo.thumbnail}
          alt=""
          onLoad={handleImageLoad}
        />
        <label
          htmlFor="online-status"
          className={
            props.cardInfo.Is_Active === '1' ? 'online' : 'offline'
          }
        >
          <i className="bx bx-radio-circle-marked bx-flashing"></i>{' '}
          {props.cardInfo.Is_Active === '1'
            ? 'Online'
            : lastActive(props.cardInfo.Last_Logged_In)}
        </label>
      </div>

      <div className="lower-card">
        <div>
          <label
            htmlFor="Tagline"
            style={{
              color: 'var(--secondary-color)',
              textTransform: 'capitalize',
              textAlign: 'center',
              fontSize: 'small',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: '3em',
            }}
          >
            {props.cardInfo.Tagline}
          </label>
        </div>

        <div className="profile-details">
          <label htmlFor="profile-name" className="gray-text">
            <span>{formattedName}</span>&nbsp;
            <span style={{ fontWeight: '400', fontSize: 'small' }}>
              | {props.cardInfo.Is_Affiliated === 'YES' ? 'Club' : 'Private'}
            </span>
          </label>
          {props.cardInfo.Account_Type === 'Individual' ? (
            <label htmlFor="price" className={`secondary-text`}>
              ${props.cardInfo.Charges}/hr
            </label>
          ) : (
            <></>
          )}
        </div>

        {props.cardInfo.Account_Type === 'Individual' ? (
          <div className="profile-details">
            <label
              htmlFor="age-label"
              className="gray-text"
              style={{ fontSize: 'small' }}
            >
              <i className="bx bx-female"></i> Age
            </label>
            <label
              htmlFor="age"
              className="gray-text"
              style={{ fontSize: 'small' }}
            >
              {props.cardInfo.Age} Years
            </label>
          </div>
        ) : (
          <></>
        )}

        <div className="profile-details">
          <label
            htmlFor="city-label"
            className="gray-text"
            style={{ fontSize: 'small', textTransform: 'capitalize' }}
          >
            <i className="bx bxs-map"></i>{' '}
            {props.cardInfo.Region.length > 0 ? props.cardInfo.Region : 'City'}
          </label>
          <label
            htmlFor="city"
            className="gray-text"
            style={{ fontSize: 'small' }}
          >
            {props.cardInfo.City}
          </label>
        </div>

        <div className="contact-info">
          <label
            htmlFor="contact-label"
            className="gray-text"
            style={{ fontSize: 'small' }}
          >
            {' '}
            <i className="bx bxs-phone"></i> Mobile
          </label>
          <label
            htmlFor="call-icon"
            className="gray-text"
            style={{ fontWeight: '600' }}
          >
            {props.cardInfo.Contact_No}
          </label>
        </div>
      </div>
    </div>
  );
}
