import React from 'react'
import Footer from './components/Footer'
// import Header from './components/Header'
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import User from './components/User';
import ScrollToTop from './components/ScrollToTop';
import Login from './components/Login';
import Agency from './pages/Agency';
import Dashboard from './components/Dashboard';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Pricings from './pages/Pricings';
import Shop from './pages/Shop';
import Product from './pages/Product';
import Admin from './pages/Admin';
import OrderConfirmation from './pages/OrderConfirmation';
import TrackOrder from './pages/TrackOrder';
import Mystery from './components/Mystery';
import Cart from './pages/Cart';
import Services from './pages/Services';
// import UnderMaintenance from './pages/UnderMaintenance'


export default function App() {
  return (
    <Router>
      <ScrollToTop />
      {/* <Header /> */}
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/auth/:authType' element={<Login />} />
        <Route exact path='/pricing' element={<Pricings />} />
        <Route exact path='/agency' element={<Agency />} />
        <Route exact path='/shop' element={<Shop />} />
        <Route exact path='/shop/:id' element={<Product />} />
        <Route exact path='/cart' element={<Cart />} />
        <Route exact path='/terms' element={<TermsConditions />} />
        <Route exact path='/privacy' element={<PrivacyPolicy />} />
        <Route exact path='/services' element={<Services/>} />
        <Route exact path='/profile/:username' element={<User />} />
        <Route exact path='/dashboard/:username' element={<Dashboard />} />
        <Route exact path='/admin' element={<Admin />} />
        <Route exact path='/order-confirmation/:status/:order_id' element={<OrderConfirmation />} />
        <Route exact path='/order-confirmation/:status/' element={<OrderConfirmation />} />
        <Route exact path='/trackOrder/' element={<TrackOrder />} />
        <Route exact path='/mystery-box/' element={<Mystery />} />
      </Routes>
      <Footer />
    </Router>
    // <UnderMaintenance/>
  )
}

