import React from 'react'
import './css/footer.css'
import logo from '../img/IMG_20221222_114102-removebg-preview.png'
import { NavLink } from 'react-router-dom'


export default function Footer() {
    return (
        <footer className='primary-bg'>
            <div className="footer-banner">
                <div className="content primary-bg">
                    <h4 className='secondary-text'>What is Girls 4 You ?</h4>
                    <p className='x-light-txt'>
                        Girls 4 You © is a beautiful and revolutionary escort / adult dating directory in New-Zealand. This directory has been made for all adult advertisements – you will find New-Zealand escorts, erotic massage providers and other high class adult entertainers across the country. We believe that adult entertainers and adult dating advertisements deserve a beautiful social platform.<b> All of the profiles have been posted by independent people in New Zealand or agencies and other adult establishments.  Girls 4 You has no connection with the advertisers. </b> <br /><br />
                        Girls 4 You is all about   freedom, love and honesty. NZ escorts deserve a place to advertise which is easy, smooth and smart. Our platform is packed with all the latest features which are needed for you to shine.   <br />
                        What you find here? You can find beautiful escort profiles and other adult dating advertisements  from all of the bigger cities in New-Zealand. Easily navigate between the cities or choose if you want to view escort or sensual massage profiles. Weather it is Wellington, Auckland or Christchurch – there are hundreds of profile to discover. Girls 4 You © is the sexiest and smartest adult directory! There are independent NZ escorts, agencies and other adult establishments. <br /><br />
                        <b>Who has posted the advertisements? </b><br /><br />
                        All of the advertisements have been posted by either independent adult entertainers (NZ escorts or massage therapists, strippers etc) or adult establishments like massage salons, strip clubs, escort agencies. Each advertiser is responsible for the content they post. Posting a profile or advertisement is automatic.  There is a lot to discover on Girls 4 You©. We do not monitor, edit or control the advertisements in any way, so all of the advertisers are solely responsible for the legality, truthfulness and validity of the advertisements.  Caution while browsing user generated advertisements is advised.
                        <br /><br />
                        What is the law in regards to escorts and adult advertisers in New Zealand? <br /><br />
                        Sex work and escorting are regulated and allowed by New Zealand´s government. For more information about the regulation you can navigate to government website to see the regulations.
                    </p>
                </div>
            </div>
            <div className="footer-sitemap primary-bg">
                <div className="footer-grid">
                    <div className="footer-grid-item">
                        <div className="logo x-light-txt">
                            <a className='link white-text' href="/"><img src={logo} alt="" />Girls 4 You</a>
                            <br /><br />
                            <address>
                                <b>Office Address:</b> 540 MT Albert Rd, Three Kings Plaza, Auckland.<br /><br />
                                <b>Email:</b> gcgtechconz@gmail.com
                            </address>
                        </div>
                    </div>
                    <div className="footer-grid-item">
                        <label htmlFor="footer-title" className='secondary-text'>About &amp; Help</label>
                        <table>
                            <tbody>
                                <tr>
                                    <td><NavLink className='link white-text' to="/pricing">Pricing</NavLink></td>
                                    <td><NavLink className='link white-text' to="/trackOrder">Track Order</NavLink></td>
                                </tr>
                                <tr>
                                    <td><NavLink className='link white-text' to="/privacy">Privacy Policy</NavLink></td>
                                    <td><NavLink className='link white-text' to="/terms">Terms Of Service</NavLink></td>
                                </tr>
                                <tr>
                                    <td><NavLink className='link white-text' to="/services">Services</NavLink></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="footer-grid-item">
                        <label htmlFor="footer-title" className='secondary-text'>Follow Us</label>
                        <div className="social-links">
                            <a href="/"><i className='bx bxl-instagram-alt' ></i></a>
                            <a href="/"><i className='bx bxl-twitter' ></i></a>
                            <a href="/"><i className='bx bxl-facebook-circle' ></i></a>
                        </div>
                    </div>
                </div>
                <hr /> <br />
                <p className='description xx-light-txt'>© 2018 - 2022 girls4you.co.nz is a trademark. All rights reserved
                    This website allows adult advertisements from independent users. Girls 4 You has no connection with the advertisers. All users are solely responsible for the content posted. Independent advertisers are the sole source of all advertisements listed on the website. We do not validate, authenticate or endorse any of the content posted.
                </p>
            </div>
        </footer>
    )
}
