import React, { useState, useEffect } from "react";
import "../components/css/mystery.css";
import betterLuck from '../img/better-luck-next-time.png'
import money from '../img/money.png'
import Header from "./Header";

export default function Mystery(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [LogIn_ID, setLogIn_ID] = useState();
    const [creditsEarned, updateCreditsEarned] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [awardCount, setAwardCount] = useState(0);

    useEffect(() => {
        const isCredentialsStored = () => {
            const credentials = JSON.parse(localStorage.getItem("credentials"));
            if (credentials !== null && credentials.length > 0) {
                setIsLoggedIn(true);
                setLogIn_ID(credentials[0].username)
            }
        }
        isCredentialsStored();
    }, []);

    const openCube = () => {
        const cube = document.querySelector("#cube");
        const cback = document.querySelector(".back");
        const ctop = document.querySelector(".top");
        const cleft = document.querySelector(".left");
        const cright = document.querySelector(".right");
        const glow = document.querySelector(".hexagon");
        const powerup = document.querySelector(".powerup");
        const message = document.querySelector("#message");
        const transitionTime = "750ms";

        ctop.style.transition = `all ${transitionTime}`;
        cleft.style.transition = `all ${transitionTime}`;
        cright.style.transition = `all ${transitionTime}`;
        cube.style.transition = `all ${transitionTime}`;
        powerup.style.transition = `all ${transitionTime}`;
        glow.style.transition = `all ${transitionTime}`;
        cback.style.transition = `all ${transitionTime}`;

        if (!isOpen) {
            award();
            ctop.style.transform = "translateY(-3rem)";
            cleft.style.transform = "translateX(-3rem)";
            cright.style.transform = "translateX(3rem)";
            ctop.style.opacity = 0.1;
            cleft.style.opacity = 0.1;
            cright.style.opacity = 0.1;
            cback.style.opacity = 0.1;
            glow.style.opacity = 0.5;
            powerup.style.opacity = 1;
            setIsOpen(true);
            cube.style.animationPlayState = "paused";
            powerup.style.zIndex = 10;
            powerup.style.height = "80px";
            powerup.style.width = "80px";
            message.style.display = 'block';
        } else {
            ctop.style.transform = "translateY(0)";
            cleft.style.transform = "translateX(0)";
            cright.style.transform = "translateX(0)";
            cube.style.opacity = 1;
            setIsOpen(false);
            ctop.style.opacity = 1;
            cleft.style.opacity = 1;
            cright.style.opacity = 1;
            cback.style.opacity = 1;
            glow.style.opacity = 1;
            powerup.style.opacity = 0;
            powerup.style.zIndex = 0;
            cube.style.animationPlayState = "running";
            powerup.style.height = "48px";
            powerup.style.width = "48px";
            changeVar("rgba(255,195,26,0.4)");
            message.style.display = 'none';
        }
    }

    const awards = [
        {
            message: "Better luck next time",
            credits: 0,
            imageUrl: betterLuck,
            color: "orange"
        },
        {
            message: "Wow, You got 1 free credit",
            credits: 1,
            imageUrl: money,
            color: "rgba(69,185,251,0.33)"
        },
        {
            message: "Congrats, You got 2 free credit",
            credits: 2,
            imageUrl: money,
            color: "rgba(69,185,251,0.33)"
        },
        {
            message: "Hurrah! You got 2 free credit",
            credits: 3,
            imageUrl: money,
            color: "rgba(69,185,251,0.33)"
        }
    ];

    // Function to check if mystery box was opened within the last 24 hours
    function isOpenedWithinLast24Hours() {
        const credentials = JSON.parse(localStorage.getItem("credentials"));
        const mysteryBox = JSON.parse(localStorage.getItem("mysteryBox"));

        if (credentials && mysteryBox) {
            const loggedInID = credentials[0]?.username;
            const lastOpenedTime = mysteryBox?.lastOpenedTime;
            const openedBy = mysteryBox?.openedBy;

            if (loggedInID && lastOpenedTime && loggedInID === openedBy) {
                const currentTime = Date.now();
                const timeDifference = currentTime - lastOpenedTime;
                const hoursDifference = timeDifference / (1000 * 60 * 60);
                return hoursDifference < 24;
            }
        }

        return false;
    }

    const award = () => {
        const cube = document.querySelector("#cube");
        const cback = document.querySelector(".back");
        const ctop = document.querySelector(".top");
        const cleft = document.querySelector(".left");
        const cright = document.querySelector(".right");
        const glow = document.querySelector(".hexagon");
        const powerup = document.querySelector(".powerup");
        const message = document.querySelector("#message");

        const transitionTime = "750ms";
        ctop.style.transition = `all ${transitionTime}`;
        cleft.style.transition = `all ${transitionTime}`;
        cright.style.transition = `all ${transitionTime}`;
        cube.style.transition = `all ${transitionTime}`;
        powerup.style.transition = `all ${transitionTime}`;
        glow.style.transition = `all ${transitionTime}`;
        cback.style.transition = `all ${transitionTime}`;

        if (isLoggedIn === true) {
            if (!isOpenedWithinLast24Hours()) {
                const mysteryBox = {
                    lastOpenedTime: Date.now(),
                    openedBy: LogIn_ID
                };
                localStorage.setItem("mysteryBox", JSON.stringify(mysteryBox));

                // Randomly select an award based on the specified chances
                let randomAward;
                const random = Math.random() * 100;
                if (random < 60) {
                    randomAward = awards[1]; // 1 credit award (60% chance)
                } else if (random < 90) {
                    randomAward = awards[2]; // 2 credits award (30% chance)
                } else {
                    randomAward = awards[3]; // 3 credits award (10% chance)
                }

                powerup.style.backgroundImage = `url('${randomAward.imageUrl}')`;
                changeVar(randomAward.color);
                updateCreditsEarned(randomAward.credits);
                message.innerHTML = randomAward.message;
                if (randomAward.credits === 0) {
                    document.querySelector("#claim").style.display = "none";
                } else {
                    document.querySelector("#claim").style.display = "block";
                }
            } else {
                // Function has already been called within the last 24 hours
                const randomAward = awards[0];
                powerup.style.backgroundImage = `url('${randomAward.imageUrl}')`;
                changeVar(randomAward.color);
                message.innerHTML = "Mystery Box opens once. Return tomorrow.";
                if (randomAward.credits === 0) {
                    document.querySelector("#claim").style.display = "none";
                }
            }
        } else {
            const randomAward = awards[Math.floor(Math.random() * awards.length)];
            powerup.style.backgroundImage = `url('${randomAward.imageUrl}')`;
            changeVar(randomAward.color);
            updateCreditsEarned(randomAward.credits);
            message.innerHTML = randomAward.message;
            if (randomAward.credits === 0) {
                document.querySelector("#claim").style.display = "none";
            } else {
                document.querySelector("#claim").style.display = "block";
            }
        }



        ctop.style.transform = "translateY(-3rem)";
        cleft.style.transform = "translateX(-3rem)";
        cright.style.transform = "translateX(3rem)";
        ctop.style.opacity = 0.1;
        cleft.style.opacity = 0.1;
        cright.style.opacity = 0.1;
        cback.style.opacity = 0.1;
        glow.style.opacity = 0.5;
        powerup.style.opacity = 1;
        cube.style.animationPlayState = "paused";
        powerup.style.zIndex = 10;
        powerup.style.height = "80px";
        powerup.style.width = "80px";
        setAwardCount(awardCount + 1);
    };


    const changeVar = (glow) => {
        document.documentElement.style.setProperty("--glow", glow);
    };

    const updateUserCredits = async (e) => {
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "updateUserCredits",
            "id": LogIn_ID,
            "creditsEarned": e.target.getAttribute("data-credits-earned")
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    alert("Credits Transferred Successfully To Your Account");
                    window.location.reload();
                } else {
                    alert("Failed to logout");
                }
            }
            )
            .catch(err => console.error(err));
    }

    return (
        <>
            <Header />
            <section className="primary-bg" style={{ height: "80vh" }}>
                <div id="cube" onClick={openCube} style={{ height: "80%" }}>
                    <div className="hexagon" style={{ scale: "1.8" }}></div>
                    <div className="cube back"></div>
                    <div className="cube top"></div>
                    <div className="cube left"></div>
                    <div className="cube right"></div>
                    <div className="powerup"></div>
                </div>
                <p id="message" className="secondary-text"></p><br />
                <input data-credits-earned={creditsEarned} type="button" id="claim" className="primary-btn secondary-bg" value={isLoggedIn === true ? "Claim" : "Login To Claim Credits"} onClick={isLoggedIn === true ? updateUserCredits : () => { alert("Login To Claim Credits") }}></input>
            </section>
        </>
    );
}
