import React from 'react'
import Header from '../components/Header'
import ShopProducts from '../components/ShopProducts';

export default function Shop() {
    document.title = "Girls 4 You | Shop";

    return (
        <>
            <Header />
            <ShopProducts />
        </>
    )
}
