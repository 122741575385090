import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import IndividualCpanel from './IndividualCpanel';
import AgencyCpanel from './AgencyCpanel';
import Admin from '../pages/Admin'
import ClientsCpanel from './ClientsCpanel';


export default function Dashboard() {
    document.title = "Girls 4 You | Dashboard";
    const navigate = useNavigate();
    const [isUserValid, setUserValidity] = useState();
    const [ActiveDashboard, setActiveDashboard] = useState();

    const [username, setUsername] = useState("");
    const [accountType, setAccountType] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const isCredentialsStored = () => {
            const credentials = JSON.parse(localStorage.getItem("credentials"));
            if (credentials !== null && credentials.length > 0) {
                setUsername(credentials[0].username);
                setAccountType(credentials[0].accountType);
                setPassword(credentials[0].password);
            }
            else {
                navigate('/auth/login');
                return;
            }
        }
        isCredentialsStored();
    }, [navigate]);

    useEffect(() => {
        const isUserValid = async () => {
            // Data for API CALL
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "isUserValid",
                "username": username,
                "accountType": accountType,
                "password": password
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        setUserValidity(true);
                    }
                }
                )
                .catch(err => console.error(err));
        }
        isUserValid();

    }, [username, accountType, password]);

    const data = {
        username: username,
        accountType: accountType
    }

    useEffect(() => {
        if (accountType === 'Individual' && isUserValid === true) {
            setActiveDashboard(0);
        } else if (accountType === 'Agency' && isUserValid === true) {
            setActiveDashboard(1);
        } else if (accountType === "Admin" && isUserValid === true) {
            setActiveDashboard(2);
        } else if (accountType === 'Client' && isUserValid === true) {
            setActiveDashboard(3);
        }
    }, [isUserValid, accountType])

    return (

        (ActiveDashboard === 0) ? <IndividualCpanel obj={data} />
            : (ActiveDashboard === 1) ? <AgencyCpanel obj={data} />
                : (ActiveDashboard === 2) ? <Admin obj={data} />
                    : (ActiveDashboard === 3) ? <ClientsCpanel obj={data} />
                        : <div className='blank_area primary-bg' style={{ minHeight: "100vh" }}>
                            <div className="blank_area">
                                <div className="loader"></div>
                            </div>
                        </div>

    )
}
