import React, { useEffect, useState } from 'react'
import adminProfile from '../img/admin-profile-photo.png'
import ManageProductsDashboard from '../img/Barcode-bro.svg'
import '../components/css/admin.css'
import { generatePath, useNavigate } from 'react-router-dom'
import axios from "axios";
import Header from '../components/Header';
import ShopProducts from '../components/ShopProducts';




const ManageProducts = (props) => {
    const navigate = useNavigate();

    // Display Product Component
    const DisplayProducts = (props) => {
        const [products, setProducts] = useState([]);
        const [isProductsSet, setIsProductsSet] = useState();
        const fetchProducts = async () => {

            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "fetchAllProducts",
            }
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        setIsProductsSet(true);
                        setProducts(response["data"]);
                    } else {
                        setIsProductsSet(false);
                    }
                }
                )
                .catch(err => console.error(err));
        }
        // Getting Products from database
        useEffect(() => {
            fetchProducts()
        }, []);

        const setProductStatus = async (e) => {
            if (window.confirm("Are you sure to hide (" + e.target.getAttribute("data-pname") + ") product from shop?")) {
                const data = {
                    "apiKey": "!IO23egz7!&!Gguow9x",
                    "function_called": "setProductStatus",
                    "productToBeUnlisted": e.target.getAttribute("data-pid"),
                    "isListedStatus": e.target.getAttribute("data-status") === "Yes" ? "No" : "Yes"
                }
                const options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    header: {
                        "Content-Type": "application/json",
                    }
                };
                await fetch(process.env.REACT_APP_API, options)
                    .then(response => response.json())
                    .then(response => {
                        if (response["status"] === true) {
                            e.target.getAttribute("data-status") === "Yes" ? alert("Product is now Inactive") : alert("Product is now Active");
                            fetchProducts();
                        }
                    }
                    )
                    .catch(err => console.error(err));
            }
        }
        const [filterProducts, setProductsFilter] = useState("Yes");
        return (
            (isProductsSet === true || isProductsSet === false) ?
                <table className='products-display-table '>
                    <thead>
                        <tr>
                            <th>S. No</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>
                                <select name="ShowProducts" id="" value={filterProducts} onChange={(e) => { setProductsFilter(e.target.value) }}>
                                    <option value="Yes">Listed Products</option>
                                    <option value="No">Unlisted Only</option>
                                </select>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map((product, index) => {
                                return (
                                    product.is_listed === filterProducts ?
                                        <tr style={{
                                            color: product.is_listed === "Yes" ? "white" : "crimson"
                                        }} key={product.p_ID}>
                                            <td>{index + 1}</td>
                                            <td>{product.p_name}</td>
                                            <td>${product.p_price}</td>
                                            <td>
                                                <button onClick={() => { navigate(generatePath("/shop/:id", { id: product.p_ID })) }}>View</button>
                                                <button data-pid={product.p_ID} onClick={(e) => { setActiveComponent(<SelectPhotos p_ID={product.p_ID} LogIn_ID={props.LogIn_ID} />) }}>Photos</button>
                                                <button data-pid={product.p_ID} data-status={product.is_listed} data-pname={product.p_name} onClick={setProductStatus}>{product.is_listed === "Yes" ? "Hide" : "Unhide"}</button>
                                            </td>
                                        </tr>
                                        : ""
                                )
                            })
                        }
                    </tbody>
                </table> :
                <div className='primary-bg'>
                    <div className="blank_area">
                        <div className="loader"></div>
                    </div>
                </div>
        )
    }

    // Select Product Photo Component
    const SelectPhotos = (props) => {

        // Components
        const ProductPhotosBucket = (props) => {
            const [reloadProductPhotos, setReloadProductPhotos] = useState();
            const [productPhotos, updateProductPhotos] = useState([]);
            const [isProductPhotosSet, setIsProductPhotosSet] = useState();

            useEffect(() => {
                // Fetching Product Photos
                const fetchProductPhotos = async () => {
                    const data = {
                        "apiKey": "!IO23egz7!&!Gguow9x",
                        "function_called": "fetchProductPhotos",
                        "p_ID": props.p_ID
                    }
                    const options = {
                        method: 'POST',
                        body: JSON.stringify(data),
                        header: {
                            "Content-Type": "application/json",
                        }
                    };
                    await fetch(process.env.REACT_APP_API, options)
                        .then(response => response.json())
                        .then(response => {
                            if (response["status"] === true) {
                                setIsProductPhotosSet(true);
                                updateProductPhotos(response["data"]);
                            } else {
                                setIsProductPhotosSet(false);
                            }
                        }
                        )
                        .catch(err => console.error(err));
                }
                fetchProductPhotos();
            }, [props.p_ID, reloadProductPhotos]);

            const removeProductPhoto = async (e) => {
                if (window.confirm("Are you sure to remove this photo")) {
                    if (productPhotos.length === 1) {
                        alert("You cann't delete this photo. Product should have at least one photo");
                        return;
                    }
                    // Data 
                    const data = {
                        "apiKey": "!IO23egz7!&!Gguow9x",
                        "function_called": "removeProductPhoto",
                        "photoToBeRemoved": e.target.getAttribute('data-id')
                    }

                    const options = {
                        method: 'POST',
                        body: JSON.stringify(data),
                        header: {
                            "Content-Type": "application/json",
                        }
                    };
                    await fetch(process.env.REACT_APP_API, options)
                        .then(response => response.json())
                        .then(response => {
                            if (response["status"] === true) {
                                setReloadProductPhotos(true);
                            } else {
                                alert("Unable to remove this photo");
                            }
                        }
                        )
                        .catch(err => console.error(err));
                }
            }
            const setProductThumbnail = async (e) => {
                if (window.confirm("Are you sure to set this as thumbnail photo")) {

                    // Data 
                    const data = {
                        "apiKey": "!IO23egz7!&!Gguow9x",
                        "function_called": "setProductThumbnail",
                        "p_ID": props.p_ID,
                        "photoToBeSetAsThumbnail": e.target.getAttribute('data-thumbnail')
                    }

                    const options = {
                        method: 'POST',
                        body: JSON.stringify(data),
                        header: {
                            "Content-Type": "application/json",
                        }
                    };
                    await fetch(process.env.REACT_APP_API, options)
                        .then(response => response.json())
                        .then(response => {
                            if (response["status"] === true) {
                                alert("Successfully Set As Thumbnail");
                            } else {
                                alert("Unable to set this photo as thumbnail");
                            }
                        }
                        )
                        .catch(err => console.error(err));
                }
            }
            return (
                (isProductPhotosSet === true || isProductPhotosSet === false) ?
                    <div>
                        <h2 className='panel-heading'><button onClick={() => { setActiveComponent(<DisplayProducts />) }}>Back</button> <button onClick={() => { setWhichBucketToLoad(<AllPhotosBucket p_ID={props.p_ID} LogIn_ID={props.LogIn_ID} />) }}>Choose From Photo Bucket</button></h2>
                        <div className='product-photos-gallery'>
                            <br />
                            {
                                (productPhotos.length === 0) ? <h4 className='secondary-text' style={{
                                    textAlign: "center"
                                }}>No Photo Found</h4> :
                                    ""
                            }
                            <div className='photos-wrapper'>
                                {
                                    productPhotos.map((photo, index) => {
                                        return (
                                            <div className='photo' key={photo.u_id}>
                                                <div className='photo-actions'>
                                                    <button data-id={photo.u_id} onClick={removeProductPhoto}>Delete</button> <br />
                                                    <button data-thumbnail={photo.url} onClick={setProductThumbnail}>Set As Thumbnail</button>
                                                </div>
                                                <img src={photo.url} alt='' />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div> :
                    <div className='primary-bg'>
                        <div className="blank_area">
                            <div className="loader"></div>
                        </div>
                    </div>
            )
        }

        const AllPhotosBucket = (props) => {
            const [isReloadRequired, setIsReloadRequired] = useState();
            const [BucketPhotos, setBucketPhotos] = useState([]);
            const [isBucketPhotosSet, setIsBucketPhotosSet] = useState();
            const handleSelectedPhotos = (event) => {
                document.getElementById("AddPhotoToBucket").innerHTML = "Uploading...";
                const data = new FormData();
                data.append("function_called", JSON.stringify("UploadImages"));
                data.append("apiKey", JSON.stringify("!IO23egz7!&!Gguow9x"));
                data.append("id", JSON.stringify(props.LogIn_ID));
                for (let index = 0; index < event.target.files.length; index++) {
                    data.append("file[]", event.target.files[index]);
                }

                let url = "https://www.girls4you.co.nz/backend/Product_Photos_Upload.php";
                axios
                    .post(url, data, {
                        // receive two parameter endpoint url ,form data
                    })
                    .then((res) => {
                        // then print response status
                        if (res.data.status === true) {
                            setIsReloadRequired(true);
                            document.getElementById("AddPhotoToBucket").innerHTML = "Add Photo To Bucket"
                        } else {
                            console.log(res.data);
                        }
                    }, error => {
                        alert(error);
                    });
            }
            const AddPhotoToBucket = (e) => {
                document.getElementById("AddPhoto").click();
            }
            const SavePhotos = async () => {
                // get all checkboxes with name "img_select"
                const selectedPhotos = document.querySelectorAll('input[name="img_select"]:checked');
                if (selectedPhotos.length === 0) {
                    alert("Select Some Photos");
                    return;
                }

                // create an array to store the values
                const values = [];

                // loop through the checked checkboxes and add their values to the array
                selectedPhotos.forEach((checkbox) => {
                    values.push(checkbox.value);
                });

                // Saving To Database
                const data = {
                    "apiKey": "!IO23egz7!&!Gguow9x",
                    "function_called": "SavePhotos",
                    "PhotosFor": props.p_ID,
                    "selectedPhotos": values
                }
                const options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    header: {
                        "Content-Type": "application/json",
                    }
                };
                await fetch(process.env.REACT_APP_API, options)
                    .then(response => response.json())
                    .then(response => {
                        console.log(response)
                        if (response["status"] === true) {
                            setWhichBucketToLoad(<ProductPhotosBucket p_ID={props.p_ID} />)
                            alert("Selected photos have been saved for this product")
                        }
                    }
                    )
                    .catch(err => console.error(err));
            }
            // Fetching Bucket Photos
            const fetchBucketPhotos = async () => {
                const data = {
                    "apiKey": "!IO23egz7!&!Gguow9x",
                    "function_called": "fetchBucketPhotos",
                }
                const options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    header: {
                        "Content-Type": "application/json",
                    }
                };
                await fetch(process.env.REACT_APP_API, options)
                    .then(response => response.json())
                    .then(response => {
                        if (response["status"] === true) {
                            setIsBucketPhotosSet(true);
                            setBucketPhotos(response["data"])
                        } else {
                            setIsBucketPhotosSet(false);
                        }
                    }
                    )
                    .catch(err => console.error(err));
            }
            useEffect(() => {
                fetchBucketPhotos();
            }, [isReloadRequired])
            return (
                (isBucketPhotosSet === true || isBucketPhotosSet === false) ?
                    <div className='product-photos-gallery'>
                        <input type="file" id='AddPhoto' onChange={handleSelectedPhotos} accept='image/jpeg, image/jpg, image/png, .jpeg, .jpg, .png' multiple hidden />
                        <h2 className='panel-heading'><button onClick={() => { setWhichBucketToLoad(<ProductPhotosBucket p_ID={props.p_ID} />) }}>Back</button><button id='AddPhotoToBucket' onClick={AddPhotoToBucket}>Add Photo To Bucket</button><button onClick={SavePhotos}>Save Selected</button></h2>
                        <br />
                        {
                            (BucketPhotos.length === 0) ? <h4 className='secondary-text' style={{
                                textAlign: "center"
                            }}>No Photo Found</h4> :
                                ""
                        }
                        <div className='photos-wrapper'>
                            {
                                BucketPhotos.map((photo, index) => {
                                    return (
                                        <div className='photo' key={index}>
                                            <input className='checkbox isHidden' name='img_select' type="checkbox" id={photo.photo_id} value={photo.photo_id} />
                                            <label htmlFor={photo.photo_id}>
                                                <img src={photo.url} alt='' />
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> :
                    <div className='primary-bg'>
                        <div className="blank_area">
                            <div className="loader"></div>
                        </div>
                    </div>
            )
        }
        const [whichBucketToLoad, setWhichBucketToLoad] = useState(<ProductPhotosBucket p_ID={props.p_ID} LogIn_ID={props.LogIn_ID} />)

        return (
            <div className='select-photos-container'>
                {whichBucketToLoad}
            </div>
        )
    }

    const [activeComponent, setActiveComponent] = useState(<DisplayProducts LogIn_ID={props.LogIn_ID} />);

    // Add Product
    const [productTobeAdded, setProductToBeAdded] = useState({
        p_name: "",
        p_price: "",
        description: ""
    })

    const AddProduct = async () => {
        if (productTobeAdded.p_name.trim().length === 0 || productTobeAdded.p_price.trim().length === 0 || productTobeAdded.description.trim().length === 0) {
            alert("Please Fill Out All Required Fields");
            return;
        }
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "addProduct",
            "productToBeAdded": productTobeAdded
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response["status"] === true) {
                    setProductToBeAdded(prevState => ({
                        ...prevState,
                        p_name: "",
                        p_price: "",
                        description: ""
                    }))
                    setActiveComponent(<DisplayProducts />);
                }
            }
            )
            .catch(err => console.error(err));
    }

    return (
        <div className='manage-products'>
            <h3 className='secondary-text'>Product Management Center <i className='bx bxl-product-hunt'></i></h3>
            <br /><br />
            <div className='table-holder'>
                {activeComponent}
            </div>
            <br />
            <div className="grid-layout">
                <div className='manage-products-form'>
                    <form action="">
                        <h3 className='secondary-text'>Add New Product <i className='bx bxs-purchase-tag-alt'></i></h3>
                        <input type="text" name='p_name' placeholder='Product Name' value={productTobeAdded.p_name} onChange={(e) => { setProductToBeAdded(prevState => ({ ...prevState, [e.target.name]: e.target.value })) }} />
                        <input type="text" name='p_price' placeholder='Price (NZD)' value={productTobeAdded.p_price} onChange={(e) => { setProductToBeAdded(prevState => ({ ...prevState, [e.target.name]: e.target.value })) }} />
                        <textarea name="description" id="" rows="10" placeholder='Product Description' value={productTobeAdded.description} onChange={(e) => { setProductToBeAdded(prevState => ({ ...prevState, [e.target.name]: e.target.value })) }}></textarea>
                        {/* <div>
                            <h3 className='secondary-text'>Highlights</h3><br />
                            <div className='highlights'>
                                <input type="text" />
                                <input type="text" />
                                <input type="button" value="Add More" />
                            </div>
                        </div> */}
                        <input onClick={AddProduct} type="button" value="Add Product" />
                    </form>
                </div>
                <div>
                    <img src={ManageProductsDashboard} alt="" />
                </div>
            </div>
        </div>
    )
}

const ManageOrders = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [isOrdersSet, setIsOrdersSet] = useState(null);
    const fetchOrders = async () => {

        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "fetchOrders",
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setIsOrdersSet(true);
                    setOrders(response["data"]);
                } else {
                    setIsOrdersSet(false);
                }
            }
            )
            .catch(err => console.error(err));
    }
    useEffect(() => {
        fetchOrders();
    }, [])

    const ProcessOrder = (props) => {
        const [orderStatus, setOrderStatus] = useState("");
        const [orderDetails, setOrderDetails] = useState([]);
        const [orderedProducts, setOrderedProducts] = useState([]);

        useEffect(() => {
            const fetchOrderDetails = async () => {
                const data = {
                    "apiKey": "!IO23egz7!&!Gguow9x",
                    "function_called": "fetchOrderDetails",
                    "order_id": props.order_ID
                }
                const options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    header: {
                        "Content-Type": "application/json",
                    }
                };
                await fetch(process.env.REACT_APP_API, options)
                    .then(response => response.json())
                    .then(response => {
                        if (response["status"] === true) {
                            setOrderStatus(response["data"][0].order_status);
                            setOrderDetails(response["data"][0]);
                        }
                    }
                    )
                    .catch(err => console.error(err));
            }
            const fetchProductsOrdered = async () => {
                const data = {
                    "apiKey": "!IO23egz7!&!Gguow9x",
                    "function_called": "fetchProductsOrdered",
                    "order_id": props.order_ID
                }
                const options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    header: {
                        "Content-Type": "application/json",
                    }
                };
                await fetch(process.env.REACT_APP_API, options)
                    .then(response => response.json())
                    .then(response => {
                        if (response["status"] === true) {
                            setOrderedProducts(response["data"]);
                        }
                    }
                    )
                    .catch(err => console.error(err));
            }
            fetchOrderDetails();
            fetchProductsOrdered();
        }, [props.order_ID]);

        const updateOrderStatus = async (e) => {
            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "updateOrderStatus",
                "order_id": props.order_ID,
                "status": e.target.getAttribute("for")
            }
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] !== true) {
                        alert("Failed to update order status");
                    } else {
                        fetchOrders();
                    }
                }
                )
                .catch(err => console.error(err));
        }

        const stepClasses = {
            placed: 'step--placed',
            packed: 'step--packed',
            shipped: 'step--shipped',
            delivered: 'step--delivered',
        };
        return (
            <div>
                <div className="order-details-table">
                    <table style={{
                        color: "white",
                        backgroundColor: "#29292993",
                        borderRadius: "0.6em"
                    }}>
                        <tbody>
                            <tr>
                                <td>Customer Name:</td>
                                <td>{orderDetails.name}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{orderDetails.email}</td>
                            </tr>
                            <tr>
                                <td>Order Date:</td>
                                <td>{orderDetails.created_at}</td>
                            </tr>
                            <tr>
                                <td>Postal Code</td>
                                <td>{orderDetails.postal_code}</td>
                            </tr>
                            <tr>
                                <td>Shipping Address:</td>
                                <td>{orderDetails.shipping_address + ", " + orderDetails.city + ", " + orderDetails.country}</td>
                            </tr>
                            <tr>
                                <td>Items List:</td>
                                <td>
                                    {
                                        orderedProducts.map((item, index) => {
                                            return (
                                                <label style={{
                                                    cursor: "pointer",
                                                    color: "var(--secondary-color)"
                                                }} onClick={() => { navigate(generatePath("/shop/:id", { id: item.product_id })) }} key={index}>
                                                    <span>{item.p_name + " (Qty. " + item.quantity + ") "}</span><br /><br />
                                                </label>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <h4 className='secondary-text'>Track Order</h4> <br />
                <div className={`progress-bar progress-bar--${orderStatus}`}>
                    <div className={`step ${stepClasses.placed}`}></div>
                    <div className={`step ${stepClasses.packed}`}></div>
                    <div className={`step ${stepClasses.shipped}`}></div>
                    <div className={`step ${stepClasses.delivered}`}></div>
                </div>
                <div className="set-status-btn">
                    <input className='radio isHidden' type="radio" name="orderStatus" id="placed" onChange={(e) => { setOrderStatus(e.target.getAttribute('id')) }} checked={orderStatus === "placed"} />
                    <label className='label' htmlFor="placed" onClick={updateOrderStatus}>Order Placed</label>

                    <input className='radio isHidden' type="radio" name="orderStatus" id="packed" onChange={(e) => { setOrderStatus(e.target.getAttribute('id')) }} checked={orderStatus === "packed"} />
                    <label className='label' htmlFor="packed" onClick={updateOrderStatus}>Order Packed</label>

                    <input className='radio isHidden' type="radio" name="orderStatus" id="shipped" onChange={(e) => { setOrderStatus(e.target.getAttribute('id')) }} checked={orderStatus === "shipped"} />
                    <label className='label' htmlFor="shipped" onClick={updateOrderStatus}>Order Shipped</label>

                    <input className='radio isHidden' type="radio" name="orderStatus" id="delivered" onChange={(e) => { setOrderStatus(e.target.getAttribute('id')) }} checked={orderStatus === "delivered"} />
                    <label className='label' htmlFor="delivered" onClick={updateOrderStatus}>Order Delivered</label>
                </div>
            </div>
        )
    };
    const filterOrders = (e) => {
        showOrderStatusComponent();
        if (e.target.id === "all_orders") {
            const allRows = document.querySelectorAll('tr');
            allRows.forEach(row => {
                row.style.display = "table-row";
            });
            return;
        }
        // Get all rows with the "active_order" status
        const activeRows = document.querySelectorAll('tr[order-status="' + e.target.id + '"]');

        // Hide all rows by default
        const allRows = document.querySelectorAll('tr');
        allRows.forEach(row => {
            row.style.display = "none";
        });

        // Show only the active rows
        activeRows.forEach(row => {
            row.style.display = "table-row";
        });

    }

    const [orderStatusComponent, showOrderStatusComponent] = useState();
    return (
        <div className='manage-orders'>
            <h3 className='secondary-text'>Order Management Center <i className='bx bxl-shopify' ></i></h3>
            <br /><br />
            <ul style={{
                listStyleType: "none",
                display: 'flex',
                flexFlow: 'row no-wrap',
                gap: "1em"
            }}>
                <li style={{
                    color: "#FFFFFF",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.4em",
                    cursor: "pointer"
                }} id="all_orders" onClick={filterOrders}><i className='bx bx-border-all' ></i> All Orders</li>
                <li style={{
                    color: "#EA9F3D",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.4em",
                    cursor: "pointer"
                }} id="active_order" onClick={filterOrders}><i className='bx bx-radio-circle-marked bx-flashing' ></i> Active Order</li>
                <li style={{
                    color: "gray",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.4em",
                    cursor: "pointer"
                }} id="order_completed" onClick={filterOrders}><i className='bx bx-check-double'></i> Completed</li>
                <li style={{
                    color: "crimson",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.4em",
                    cursor: "pointer"
                }} id="payment_pending" onClick={filterOrders}><i className='bx bx-x' ></i> Payment Pending</li>
            </ul>
            <br />
            {(isOrdersSet === true) ?
                <div className='table-holder'>
                    {orderStatusComponent}
                    <br />
                    <table className='products-display-table '>
                        <thead>
                            <tr>
                                <th>S. No</th>
                                <th>Customer</th>
                                <th>Shipment Address</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.map((item, index) => {
                                    return (
                                        <tr order-status={((item.payment_status === "Paid" && item.order_status === "placed") || (item.payment_status === "Paid" && item.order_status === "packed") || (item.payment_status === "Paid" && item.order_status === "shipped")) ? "active_order"
                                            : (item.payment_status === "Paid" && item.order_status === "delivered") ? "order_completed"
                                                : "payment_pending"
                                        } style={{
                                            color: (item.payment_status === "Paid" && item.order_status === "placed") ? "#EA9F3D"
                                                : (item.payment_status === "Paid" && item.order_status === "packed") ? "#EA9F3D"
                                                    : (item.payment_status === "Paid" && item.order_status === "shipped") ? "#EA9F3D"
                                                        : (item.payment_status === "Paid" && item.order_status === "delivered") ? "gray"
                                                            : (item.payment_status !== "Paid") ? "crimson"
                                                                : ""
                                        }} key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.shipping_address}, {item.city}, {item.country}</td>
                                            <td>{item.total_price}</td>
                                            <td>
                                                <button onClick={() => {
                                                    if (item.payment_status === "Paid") {
                                                        showOrderStatusComponent(<ProcessOrder order_ID={item.order_id} />);
                                                        const element = document.getElementById("cPanel-dashboard");
                                                        const offset = 60; // Set the desired offset value
                                                        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                                                        const offsetPosition = elementPosition - offset;

                                                        window.scrollTo({
                                                            top: offsetPosition,
                                                            behavior: "smooth"
                                                        });
                                                    } else {
                                                        alert("Payment Pending")
                                                    }
                                                }}>{(item.payment_status === "Paid" ? "Process Order" : "Payment Pending")}</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                : (isOrdersSet === false) ?
                    <></>

                    : <div className='primary-bg'>
                        <div className="blank_area">
                            <div className="loader"></div>
                        </div>
                    </div>
            }

        </div>
    )
}

const ManageProfiles = () => {
    const [profiles, setProfiles] = useState([]);
    const fetchAllProfiles = async () => {

        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "fetchAllProfiles",
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setProfiles(response["data"]);
                } else {
                }
            }
            )
            .catch(err => console.error(err));
    }
    useEffect(() => {
        fetchAllProfiles();
    }, [])

    const verifyPhotos = async (e) => {
        if (window.confirm(e.target.getAttribute("data-isphotosverified") === "NO" ? "If you click Ok then user '" + e.target.getAttribute("data-loginid") + "' will get Photos Verified Badge on their Profile" : "Do You want to remove Photos Verified Badge from this user's profile?")) {
            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "verifyPhotos",
                "LogIn_ID": e.target.getAttribute("data-loginid"),
                "photosVerifiedStatus": e.target.getAttribute("data-isphotosverified") === "YES" ? "NO" : "YES"
            }
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        e.target.getAttribute("data-isphotosverified") === "YES" ? alert("Now " + e.target.getAttribute("data-loginid") + " Have Unverified Photos") : alert("Now " + e.target.getAttribute("data-loginid") + " Have Verified Photos");
                        fetchAllProfiles();
                    }
                }
                )
                .catch(err => console.error(err));
        }
    }

    const blockAccount = async (e) => {
        if (window.confirm(e.target.getAttribute("data-isaccountblocked") === "NO" ? "If you click Ok then user '" + e.target.getAttribute("data-loginid") + "' account will get blocked" : "Do you want to unblock this account?")) {
            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "blockAccount",
                "LogIn_ID": e.target.getAttribute("data-loginid"),
                "isaccountblocked": e.target.getAttribute("data-isaccountblocked") === "YES" ? "NO" : "YES"
            }
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        e.target.getAttribute("data-isaccountblocked") === "YES" ? alert("Now " + e.target.getAttribute("data-loginid") + " has been unblocked") : alert("Now " + e.target.getAttribute("data-loginid") + " has been blocked");
                        fetchAllProfiles();
                    }
                }
                )
                .catch(err => console.error(err));
        }
    }

    const activateSubscription = async (e) => {
        e.target.innerHTML = "Activating...";
        // Data for API CALL
        const data = {
            payment_type: 'subscription',
            Subs_Plan: e.target.getAttribute("data-subsplan"),
            LogIn_ID: e.target.getAttribute("data-loginid")
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch('https://www.girls4you.co.nz/backend/payments/payment_success.php', options)
            .then(response => response.json())
            .then(response => {
                // handle response from API
                if (response["status"] === true) {
                    alert(response["message"]);
                    fetchAllProfiles();
                } else {
                    alert(response["message"]);
                    e.target.innerHTML = "Try Again";
                }
            }
            )
            .catch(err => console.error(err));
    }

    const [filterValue, setFilterValue] = useState('');
    const [filteredProfiles, setFilteredProfiles] = useState(profiles);

    useEffect(() => {
        const filtered = profiles.filter((profile) =>
            Object.values(profile).some((value) =>
                value.toString().toLowerCase().includes(filterValue.toLowerCase())
            )
        );
        setFilteredProfiles(filtered);
    }, [filterValue, profiles]);


    return (
        <div className='magage-profiles-container'>
            <h3 className='secondary-text'>User Management Center <i className='bx bxs-user-circle'></i></h3><br /><br />
            <div className="display-profiles">

                <table className='user-profiles-table'>
                    <thead className='secondary-bg'>
                        <tr>
                            <th>S. No</th>
                            <th>Login ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Role</th>
                            <th><input style={{ padding: "0.6em 1em", outline: "none", border: "2px solid black", borderRadius: "0.4em", fontWeight: "500" }} type="text" placeholder='Search' value={filterValue} onChange={(e) => setFilterValue(e.target.value)} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProfiles.map((profile, index) => {
                            return (
                                <tr key={index} style={{ color: profile.Is_Blocked === 'YES' ? 'crimson' : profile.Payment_Status === "Done" ? "var(--secondary-color)" : 'gray' }}>
                                    <td>{index + 1}</td>
                                    <td>{profile.LogIn_ID}</td>
                                    <td>{profile.User_Name}</td>
                                    <td>{profile.Email_ID}</td>
                                    <td>{profile.Contact_No}</td>
                                    <td>{profile.Account_Type}</td>
                                    <td>
                                        <button onClick={blockAccount} data-loginid={profile.LogIn_ID} data-isaccountblocked={profile.Is_Blocked}>
                                            {profile.Is_Blocked === 'NO' ? 'Block' : (
                                                <>
                                                    Unblock <i className='bx bxs-badge-check' />
                                                </>
                                            )}
                                        </button>
                                        <button onClick={verifyPhotos} data-loginid={profile.LogIn_ID} data-isphotosverified={profile.Is_Photos_Verified}>
                                            {profile.Is_Photos_Verified === 'NO' ? 'Verify' : (
                                                <>
                                                    Verified <i className='bx bxs-badge-check' />
                                                </>
                                            )}</button>
                                        {
                                            profile.Payment_Status !== "Done" ?
                                                <button data-loginid={profile.LogIn_ID} data-subsplan={profile.Subs_Plan} onClick={activateSubscription}>Activate Subscription</button>
                                                :
                                                <></>
                                        }

                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

const ManageCoupons = (props) => {
    const [activeInput, setActiveInput] = useState(1);
    const [coupon, updateCoupon] = useState({
        Cpn_Offer: "",
        Cpn_Code: "",
        Cpn_Validity: "2023-04-30",
        Cpn_ValidAt: "",
        How_To_Claim_Message: "",
        Winner_Line: "",
        Max_Users: '',
        Cpn_Bg: "radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%)",
    });
    const [coupons, setCoupons] = useState([]);
    const fetchCoupons = async () => {
        // Data for API CALL
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "fetchCoupons",
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setCoupons(response["data"])
                } else {

                }
            }
            )
            .catch(err => console.error(err));
    }
    useEffect(() => {
        fetchCoupons();
    }, []);
    function isCouponValid(cpnValidity) {
        const today = new Date(); // get today's date
        const cpnValidityDate = new Date(cpnValidity); // create a Date object from the Cpn_Validity string

        // return a boolean indicating if the coupon is valid or expired
        return cpnValidityDate.getTime() >= today.getTime();
    }

    const onChangeHandler = (e) => {
        updateCoupon(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const onClickHandler = (e) => {
        e.preventDefault();
        const clicked_id = e.target.id;
        if (clicked_id === 'prev' && activeInput !== 1) {
            setActiveInput(activeInput - 1);
        } else if (clicked_id === 'next' && activeInput < 8) {
            switch (activeInput) {
                case 1:
                    if (coupon.Cpn_Offer.length === 0) {
                        alert("What You Are Offering ?");
                        return;
                    }
                    break;

                case 2:
                    if (coupon.Cpn_Code.length === 0) {
                        alert("Create a Coupon Code. Like 'FREEMASSAGE' ");
                        return;
                    }
                    break;


                case 3:
                    if (coupon.Cpn_Validity.length === 0) {
                        alert("Select Coupon Validity");
                        return;
                    }
                    break;


                case 4:
                    if (coupon.Cpn_ValidAt.length === 0) {
                        alert("Provide Names & Address Of Valid Stores");
                        return;
                    }
                    break;


                case 5:
                    if (coupon.How_To_Claim_Message.length === 0) {
                        alert("Tell Users how they can claim benefits of this coupon");
                        return;
                    }
                    break;


                case 6:
                    if (coupon.Winner_Line.length === 0) {
                        alert("Write a Winner Line Like: 'has got a FREE Massage you can get it too Hurry Up'. Keep This line in same format because it will be displayed as 'Johny (Winner Name): has got a FREE Massage you can get it too Hurry Up'");
                        return;
                    }
                    break;

                case 7:
                    if (coupon.Max_Users.length === 0) {
                        alert("How Many Users can Claim this coupon ?");
                        return;
                    }
                    break;

                case 8:
                    if (coupon.Cpn_Bg.length === 0) {
                        alert("Fill a Valid gradient for coupon background");
                        return;
                    }
                    break;

                default:
                    break;
            }
            setActiveInput(activeInput + 1);
        }
    }

    const createCoupon = async (e) => {
        e.preventDefault();
        // Data for API CALL
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "createCoupon",
            "coupon": coupon
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    fetchCoupons();
                    setActiveInput(1);
                    alert("A New Coupon Has Been Created.")
                } else {
                    console.log(response)
                }
            }
            )
            .catch(err => console.error(err));
    }

    const deleteCoupon = async (e) => {
        if (window.confirm("Are you sure to delete this coupon?")) {
            const Cpn_ID = e.target.getAttribute("data-cpnid")
            // Data for API CALL
            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "deleteCoupon",
                "couponToBeDeleted": Cpn_ID
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        fetchCoupons();
                        alert("Coupon Deleted Successfully");
                    } else {
                        alert("Failed! To Delete Coupon");
                    }
                }
                )
                .catch(err => console.error(err));
        }

    }

    return (
        <>
            <h3 className='secondary-text'>Coupons Management Center <i className='bx bxs-gift'></i></h3><br /><br />
            <div className="grid-layout">
                <div>
                    <form action="" className='create-reward-form'>
                        <h2 className='secondary-text'>Create Coupon</h2>
                        <input type="text" name="Cpn_Offer" id="" value={coupon.Cpn_Offer} onChange={onChangeHandler} placeholder='What You Are Offering ?' hidden={activeInput === 1 ? false : true} />
                        <input type="text" name="Cpn_Code" id="" value={coupon.Cpn_Code} onChange={onChangeHandler} placeholder='COUPONCODE' hidden={activeInput === 2 ? false : true} />
                        <input type="date" name="Cpn_Validity" id="" value={coupon.Cpn_Validity} onChange={onChangeHandler} placeholder='2023-04-30' hidden={activeInput === 3 ? false : true} />
                        <input type="text" name="Cpn_ValidAt" id="" value={coupon.Cpn_ValidAt} onChange={onChangeHandler} placeholder='Valid Stores ?' hidden={activeInput === 4 ? false : true} />
                        <input type="text" name="How_To_Claim_Message" id="" value={coupon.How_To_Claim_Message} onChange={onChangeHandler} placeholder='How Users Can Claim Benefits of this Coupon' hidden={activeInput === 5 ? false : true} />
                        <input type="text" name="Winner_Line" id="" value={coupon.Winner_Line} onChange={onChangeHandler} placeholder='Winner Line ?' hidden={activeInput === 6 ? false : true} />
                        <input type="text" name="Max_Users" id="" value={coupon.Max_Users} onChange={onChangeHandler} placeholder='Max number of users who can claim' hidden={activeInput === 7 ? false : true} />
                        <input type="text" name="Cpn_Bg" id="" value={coupon.Cpn_Bg} onChange={onChangeHandler} placeholder="Background Gradient" hidden={activeInput === 8 ? false : true} />
                        <a style={{ textDecoration: "none", color: "var(--secondary-color)" }} href="https://gradients.shecodes.io" target="_blank" rel="noopener noreferrer" hidden={activeInput === 8 ? false : true}>Check Gradient Background</a>
                        <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", alignItems: "center", justifyContent: "space-between" }}>
                            <button id='prev' onClick={onClickHandler}>Previous</button>
                            <button id='next' onClick={activeInput === 8 ? createCoupon : onClickHandler}>{activeInput === 8 ? "Activate Coupon" : "Next"}</button>
                        </div>
                    </form>
                </div>
                <div style={{ overflow: "hidden" }}>
                    <div className='coupon-card' style={{ background: coupon.Cpn_Bg }}>
                        <div className="coupon-content">
                            <div className='coupon-details'>
                                <h2 style={{ fontSize: "1.4em" }}>{coupon.Cpn_Offer.length > 0 ? coupon.Cpn_Offer : "What You Are Offering ?"}</h2>
                                <div className="call-to-action">
                                    <span className="coupon-code">{coupon.Cpn_Code.length > 0 ? coupon.Cpn_Code : "COUPONCODE"}</span>
                                    <span className="coupon-btn" >CLAIM</span>
                                </div>
                                <p>Valid Till: {new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(coupon.Cpn_Validity))}</p>
                                <span style={{ fontWeight: "500" }} onClick={() => { alert("Valid At: " + coupon.Cpn_ValidAt + " \n\nHow To Claim: " + coupon.How_To_Claim_Message + "") }}>How To Use?</span>
                                <span className={`expired ${isCouponValid(coupon.Cpn_Validity) ? "online" : "offline"}`}><i className='bx bx-radio-circle-marked' ></i> {isCouponValid(coupon.Cpn_Validity) ? "Valid" : "Expired"}</span>
                            </div>
                        </div>
                        <div className="circle1"></div>
                        <div className="circle2"></div>
                    </div>
                </div>
            </div> <br />
            <div className='earn-rewards-container'>
                {
                    coupons.map((coupon, index) => {
                        return (
                            <div key={index} className='coupon-card' style={{ background: coupon.Cpn_Bg }}>
                                <div className="coupon-content">

                                    <div className='coupon-details'>
                                        <h2>{coupon.Cpn_Offer}</h2>
                                        <div className="call-to-action">
                                            <span className="coupon-btn">CODE</span>
                                            <span className="coupon-code">{coupon.Cpn_Code}</span>
                                        </div>
                                        <p>Valid Till: {new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(coupon.Cpn_Validity))}</p>
                                        <span style={{ fontWeight: "500" }} onClick={() => { alert("Valid At: " + coupon.Cpn_ValidAt + " \n\nHow To Claim: " + coupon.How_To_Claim_Message + "") }}>How To Use?</span>
                                        <span className={`expired ${isCouponValid(coupon.Cpn_Validity) ? "online" : "offline"}`}><i className='bx bx-radio-circle-marked' ></i> {isCouponValid(coupon.Cpn_Validity) ? "Valid" : "Expired"}</span>
                                        <span data-cpnid={coupon.Cpn_ID} onClick={deleteCoupon} className='delete-coupon'><i data-cpnid={coupon.Cpn_ID} className='bx bxs-trash-alt'></i></span>
                                    </div>
                                </div>
                                <div className="circle1"></div>
                                <div className="circle2"></div>
                            </div>
                        )
                    })
                }

            </div>
        </>
    )
}

export default function Admin(props) {
    document.title = "Girls 4 You | Admin Dashboard";

    const navigate = useNavigate();
    // Getting data from props
    const username = props.obj.username;

    // Set Active Panel
    const [activePane, setActivePane] = useState('1');
    const handleActivePane = (e) => {
        if (e.target.id === '6') {
            if (window.confirm("We redirect you to Cloudways Hosting for further assistance. Thank you.")) {
                window.open("https://platform.cloudways.com/login?ref_id=web_navbar");
                return
            }
        }
        if (e.target.id === '7') {
            if (window.confirm("We redirect you to Shift4 Payment Gateway to manage payments.")) {
                window.open("https://dev.shift4.com/login");
                return
            }
        }
        if (e.target.id === '8') {
            if (window.confirm("We redirect you to Tidio Chatbot for immediate support. Thank you")) {
                window.open("https://www.tidio.com/panel/dashboard");
                return
            }
        }
        setActivePane(e.target.id);
        const element = document.getElementById("cPanel-dashboard");
        const offset = 60; // Set the desired offset value
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    // Logout
    const logout = async () => {
        if (window.confirm("Are You Sure To Logout")) {
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "logout",
                "id": username,
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        localStorage.removeItem("credentials");
                        navigate('/auth/login');
                    } else {
                        alert("Failed to logout");
                    }
                }
                )
                .catch(err => console.error(err));
        }
    }

    return (
        <>
            <Header />
            <div className='cPanel-wrapper primary-bg'>
                <div className='cPanel'>
                    <div className="sidebar secondary-bg">
                        <div className="sidebar-top">
                            <img className='profile-photo' src={adminProfile} alt="" />
                            <label htmlFor="profile-name">Welcome Admin</label>
                        </div>
                        <div className="sidebar-btm">
                            <ul className="dashboard-links">
                                <li id='1' className={activePane === '1' ? "active-pane-link" : "highlight-link"} onClick={handleActivePane}>Products <i id='1' className='bx bxl-product-hunt' ></i></li>
                                <li id='2' className={activePane === '2' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Orders <i id='2' className='bx bxs-dollar-circle' ></i></li>
                                <li id='3' className={activePane === '3' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>View Shop <i id='3' className='bx bxs-shopping-bags' ></i></li>
                                <li id='4' className={activePane === '4' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Users <i id='4' className='bx bxs-user-circle' ></i></li>
                                <li id='5' className={activePane === '5' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Coupons <i id='5' className='bx bxs-gift'></i></li>
                                <li id='6' className={activePane === '6' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Hosting <i id='6' className='bx bxs-data'></i></li>
                                <li id='7' className={activePane === '7' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Payments <i id='7' className='bx bxl-stripe'></i></li>
                                <li id='8' className={activePane === '8' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Chat <i id='8' className='bx bxs-help-circle'></i></li>

                                <li className="pane-link" onClick={logout}>Log Out <i className='bx bx-log-out' ></i></li>
                            </ul>
                        </div>
                    </div>
                    <div id='cPanel-dashboard' className="cPanel-dashboard">
                        {
                            (activePane === '1') ? <ManageProducts LogIn_ID={username} />
                                : (activePane === '2') ? <ManageOrders />
                                    : (activePane === '3') ? <ShopProducts />
                                        : (activePane === '4') ? <ManageProfiles />
                                            : (activePane === '5') ? <ManageCoupons />
                                                : <ManageProducts LogIn_ID={username} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
