import React, { useEffect, useState } from 'react'
import './css/layout.css'
import Card from './Card'

export default function Slider(props) {
  const [profiles, setprofiles] = useState(props.SliderData["profiles"]);
  const handleInfiniteScroll = async () => {
    try {
      const content_width = document.getElementById('flexbox').scrollWidth;
      const flexbox_width = document.getElementById('flexbox').clientWidth;
      const viewable_content_width = document.getElementById('flexbox').scrollLeft;
      if (flexbox_width + viewable_content_width + 200 >= content_width) {
        setprofiles((prev) => [...prev, ...props.SliderData["profiles"]])
        // document.getElementById('flexbox').scrollLeft = 0
      }
    } catch (error) {
      console.log(error)
    }
  }


  const handleScroll = (direction) => {
    const container = document.getElementById('flexbox');
    if (container) {
      if (direction === 'left') {
        container.scrollLeft -= 282;
      } else {
        container.scrollLeft += 282;
      }
    }
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      handleScroll("right");
    }, 2000);

    return () => clearInterval(intervalId);
  }, [])

  return (
    <div className={`Slider-Template`}>
      <div className="wrapper">
        <h4 className='secondary-text'>{props.SliderData.heading} <label htmlFor="heading-icon" className={props.SliderData.icon}></label></h4><br /><br />
        <div style={{ position: "relative", display: "flex", alignItems: "center", flexDirection: "row" }}>
          <button data-id="scroll-left" style={{ position: "absolute", left: "-10px", zIndex: "1", outline: "none", border: "none", borderRadius: "50%", width: "30px", height: "30px", cursor: "pointer", backgroundColor: "black", color: "white" }} onClick={() => { handleScroll("left") }}><i onClick={() => { handleScroll("left") }} data-id="scroll-left" style={{ fontSize: "30px" }} className='bx bxs-chevron-left-circle' ></i></button>
          <div id='flexbox' className="flexbox" onScroll={handleInfiniteScroll}>
            {
              profiles.map((item, index) => {
                return (
                  <div className="flex-item" key={index}>
                    <Card cardInfo={item} />
                  </div>
                )
              })
            }
          </div>
          <button data-id="scroll-right" style={{ position: "absolute", right: "-10px", zIndex: "1", outline: "none", border: "none", borderRadius: "50%", width: "30px", height: "30px", cursor: "pointer", backgroundColor: "black", color: "white" }} onClick={() => { handleScroll("right") }}><i onClick={() => { handleScroll("right") }} data-id="scroll-right" style={{ fontSize: "30px" }} className='bx bxs-chevron-left-circle bx-rotate-180' ></i></button>
        </div>

      </div>
    </div>
  )
}

