import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import "../components/css/clientCpanel.css"
import '../components/css/cPanel.css'
import clients from '../img/clients.png'
import Header from './Header'
import ShopProducts from '../components/ShopProducts'
import TrackOrderForm from '../components/TrackOrderForm'
import videoCallImg from '../img/Video-call-bro.png'
import resetpassword from '../img/reset-password.png'



const EarnRewards = (props) => {
  function isCouponValid(cpnValidity) {
    const today = new Date(); // get today's date
    const cpnValidityDate = new Date(cpnValidity); // create a Date object from the Cpn_Validity string

    // return a boolean indicating if the coupon is valid or expired
    return cpnValidityDate.getTime() >= today.getTime();
  }
  return (
    <>
      <h2 className='secondary-text'>Exciting Rewards <i className='bx bxs-gift' ></i></h2> <br /><br />
      <div className='earn-rewards-container'>
        {
          props.EarnRewardsProps.coupons.map((coupon, index) => {
            return (
              <div key={index} className='coupon-card' style={{ background: coupon.Cpn_Bg }}>
                <div className="coupon-content">
                  <div className='coupon-details'>
                    <h2>{coupon.Cpn_Offer}</h2>
                    <div className="call-to-action">
                      <span className="coupon-code">{coupon.Cpn_Code}</span>
                      <span data-cpnid={coupon.Cpn_ID} data-maxusers={coupon.Max_Users} data-usersclaimed={coupon.Users_Claimed} className="coupon-btn" onClick={props.serverResponse.Payment_Status !== "Done" ? () => { alert("Please Activate Your Account To Be Able To Enjoy These Rewards") } : props.EarnRewardsProps.handleClaimedCoupon}>CLAIM</span>
                    </div>
                    <p>Valid Till: {new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(coupon.Cpn_Validity))}</p>
                    <span className={`expired ${isCouponValid(coupon.Cpn_Validity) ? "online" : "offline"}`}><i className='bx bx-radio-circle-marked' ></i> {isCouponValid(coupon.Cpn_Validity) ? "Valid" : "Expired"}</span>
                    <span>{parseInt(coupon.Max_Users) - parseInt(coupon.Users_Claimed) === 0 ? "Max Limit Reached" : "Hurry Up Only " + (parseInt(coupon.Max_Users) - parseInt(coupon.Users_Claimed)) + " left"}</span>
                  </div>
                </div>
                <div className="circle1"></div>
                <div className="circle2"></div>
              </div>
            )
          })
        }

      </div>
    </>
  )
}

const MyCoupons = (props) => {
  function isCouponValid(cpnValidity) {
    const today = new Date(); // get today's date
    const cpnValidityDate = new Date(cpnValidity); // create a Date object from the Cpn_Validity string

    // return a boolean indicating if the coupon is valid or expired
    return cpnValidityDate.getTime() >= today.getTime();
  }

  return (
    <>
      <h2 className='secondary-text'>My Coupons <i className='bx bxs-gift'></i></h2> <br /><br />
      <div className='earn-rewards-container'>
        {
          props.ClaimedCoupons.map((coupon, index) => {
            return (
              <div key={index} className='coupon-card' style={{ background: coupon.Cpn_Bg }}>
                <div className="coupon-content">

                  <div className='coupon-details'>
                    <h2>{coupon.Cpn_Offer}</h2>
                    <div className="call-to-action">
                      <span className="coupon-btn">CODE</span>
                      <span className="coupon-code">{coupon.Cpn_Code}</span>
                    </div>
                    <p>Valid Till: {new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(coupon.Cpn_Validity))}</p>
                    <span style={{ fontWeight: "500" }} onClick={() => { alert("Valid At: " + coupon.Cpn_ValidAt + " \n\nHow To Claim: " + coupon.How_To_Claim_Message + "") }}>How To Use?</span>
                    <span className={`expired ${isCouponValid(coupon.Cpn_Validity) ? "online" : "offline"}`}><i className='bx bx-radio-circle-marked' ></i> {isCouponValid(coupon.Cpn_Validity) ? "Valid" : "Expired"}</span>
                  </div>
                </div>
                <div className="circle1"></div>
                <div className="circle2"></div>
              </div>
            )
          })
        }

      </div>
    </>

  )
}

const VideoChat = (props) => {
  return (
    <div className='video-chat-container'>
      <h2 className='secondary-text'>Video Chat <i className='bx bxs-video' ></i></h2><br /><br />
      <div className="video-chat-section" style={{ margin: "0 auto", display: "flex", flexFlow: "column nowrap", alignItems: "center", gap: "1em" }}>
        <img src={videoCallImg} alt="" style={{ width: "100%", height: "400px", objectFit: "contain" }} />
        <h2 className='secondary-text'>Coming Soon: Video Calls with Escorts</h2>
        <p className='gray-text' style={{ fontSize: "small", textAlign: "justify" }}>We're excited to announce that soon you'll be able to have virtual meetings with our gorgeous escorts! Our video call feature will allow you to connect face-to-face with your favorite companion from the comfort of your own home.</p>
        <p className='gray-text' style={{ fontSize: "small", textAlign: "justify" }}>Whether you're traveling, working from home, or simply unable to meet in person, our video calls will provide a safe and convenient way to stay in touch with your favorite escorts.</p>
        <p className='gray-text' style={{ fontSize: "small", textAlign: "justify" }}>Stay tuned for more updates on the release date of this exciting new feature. We can't wait to bring you closer to the companionship you crave.</p>
      </div>
    </div>
  )
}

const ResetPassword = (props) => {
  // Reset Password
  const resetPassFor = props.email;
  const [OTP, setOTP] = useState("");
  const [newPass, setNewPass] = useState("");
  const [OTPSentStatus, setOTPSentStatus] = useState(false);
  const [resetMessage, setResetMessage] = useState("");
  const [LoadingIcon, showLoadingIcon] = useState(false);
  const sendOTP = async (e) => {
    e.preventDefault();
    function isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    }


    if (!isValidEmail(resetPassFor)) {
      // show an error message to the user
      alert('Please enter a valid email address');
      return;
    }
    showLoadingIcon(true);
    const data = {
      "apiKey": "!IO23egz7!&!Gguow9x",
      "function_called": "sendOTP",
      "email": resetPassFor.trim(),
    }
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      header: {
        "Content-Type": "application/json",
      }
    };
    await fetch(process.env.REACT_APP_API, options)
      .then(response => response.json())
      .then(response => {
        if (response["status"] === true) {
          setOTPSentStatus(true);
          setResetMessage("OTP Sent Successfully");
          showLoadingIcon(false);
        } else {
          setResetMessage(response["data"]);
          showLoadingIcon(false);
        }
      }
      )
      .catch(err => console.error(err));

  }

  const changeUserPassword = async (e) => {
    e.preventDefault();
    function isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    }


    if (!isValidEmail(resetPassFor)) {
      // show an error message to the user
      alert('Please enter a valid email address');
      return;
    }

    showLoadingIcon(true);

    const data = {
      "apiKey": "!IO23egz7!&!Gguow9x",
      "function_called": "changeUserPassword",
      "email": resetPassFor.trim(),
      "otp": OTP,
      "newPassword": newPass
    }
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      header: {
        "Content-Type": "application/json",
      }
    };
    await fetch(process.env.REACT_APP_API, options)
      .then(response => response.json())
      .then(response => {
        if (response["status"] === true) {
          setResetMessage(response['data']);
          setOTP("");
          setNewPass("");
          setOTPSentStatus("");
          showLoadingIcon(false);
        } else {
          setResetMessage(response['data']);
          showLoadingIcon(false);
        }
      }
      )
      .catch(err => console.error(err));


  }
  return (
    <>
      <div className="grid-layout" style={{ alignItems: "center" }}>
        <div>
          <img src={resetpassword} alt="" />
        </div>
        <div className='reser_Password_form_container'>
          <form action="" style={{ width: "90%", margin: "0 auto" }}>
            <h4>Reset Password <i className='bx bxs-lock-alt bx-tada' ></i></h4>
            <input className='input' type="text" id='reset_login_id' placeholder='Email' value={resetPassFor} onChange={(e) => { }} required />
            <input className='input' type="text" placeholder='Enter OTP' onChange={(e) => { setOTP(e.target.value) }} hidden={OTPSentStatus === true ? false : true} />
            <input className='input' type="text" placeholder='New Password' onChange={(e) => { setNewPass(e.target.value) }} hidden={OTPSentStatus === true ? false : true} />
            <button onClick={OTPSentStatus === true ? changeUserPassword : sendOTP} className='submit_btn'>{OTPSentStatus === true ? "Change Password" : "Send OTP"} {LoadingIcon === true ? <i className='bx bx-loader-circle bx-spin' ></i> : <></>}</button>
            <span style={{ fontSize: "small", textAlign: "center" }}>{resetMessage}</span>
          </form>
        </div>
      </div>
      <br /><br />
      <div style={{ borderRadius: "0.6em", backgroundColor: "var(--secondary-color)", padding: "8px", textAlign: "justify", lineHeight: "2em", fontSize: "small" }}>
        <p>
          Dear User,
          To Reset Your Password, Please follow the steps below:
        </p>
        <ul>
          <li>Click on the "Send OTP" button.</li>
          <li> Shortly, an OTP will be dispatched to your registered email address.</li>
          <li>Retrieve the OTP from your inbox and enter it in the designated field.</li>
          <li> Upon successful authentication, you will be prompted to set a new password for your account.</li>
          <li> Enter your new password and click on the "Change Password" button.</li>
          <li>Upon successfully resetting your password, a confirmation message will be displayed on your screen, indicating that the password has been changed. Additionally, you may also receive a confirmation email notifying you of the password change. Please ensure that you keep your new password safe and secure.</li>
        </ul>
        <p>
          If you have any further questions or concerns, please feel free to contact our customer support team.
          Thank you for choosing our service.
          <br />
          Best regards, <br />
          Girls4You Team <br />
        </p>
      </div>
    </>
  )
}


export default function ClientsCpanel(props) {
  const navigate = useNavigate();
  // Getting data from props
  const username = props.obj.username;
  const accountType = props.obj.accountType;
  const [serverResponse, updateServerResponse] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [ClaimedCoupons, setClaimedCoupons] = useState([]);

  useEffect(() => {
    const fetchProfileData = async () => {
      // Data for API CALL
      const dataForAPICall = {
        "apiKey": "!IO23egz7!&!Gguow9x",
        "function_called": "fetchProfileData",
        "username": username,
        "accountType": accountType
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(dataForAPICall),
        header: {
          "Content-Type": "application/json",
        }
      };
      await fetch(process.env.REACT_APP_API, options)
        .then(response => response.json())
        .then(response => {
          if (response["status"] === true) {
            updateServerResponse(response["data"][0]);
          } else {
            console.log(response)
          }
        }
        )
        .catch(err => console.error(err));
    }
    const fetchCoupons = async () => {
      // Data for API CALL
      const data = {
        "apiKey": "!IO23egz7!&!Gguow9x",
        "function_called": "fetchCoupons",
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(data),
        header: {
          "Content-Type": "application/json",
        }
      };
      await fetch(process.env.REACT_APP_API, options)
        .then(response => response.json())
        .then(response => {
          if (response["status"] === true) {
            setCoupons(response["data"])
          } else {

          }
        }
        )
        .catch(err => console.error(err));
    }
    const fetchClaimedCoupons = async () => {
      // Data for API CALL
      const data = {
        "apiKey": "!IO23egz7!&!Gguow9x",
        "function_called": "fetchClaimedCoupons",
        "User_ID": username
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(data),
        header: {
          "Content-Type": "application/json",
        }
      };
      await fetch(process.env.REACT_APP_API, options)
        .then(response => response.json())
        .then(response => {
          if (response["status"] === true) {
            setClaimedCoupons(response["data"])
          } else {
            console.log(response)
          }
        }
        )
        .catch(err => console.error(err));
    }
    fetchProfileData();
    fetchCoupons();
    fetchClaimedCoupons();
  }, [username, accountType]);

  // Set Active Panel
  const [activePane, setActivePane] = useState('1');
  const handleActivePane = (e) => {
    setActivePane(e.target.id);
    const element = document.getElementById("cPanel-dashboard");
    const offset = 60; // Set the desired offset value
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  const EarnRewardsProps = {
    coupons: coupons,
    handleClaimedCoupon: function handleClaimedCoupon(e) {
      const Cpn_ID = e.target.getAttribute("data-cpnid");
      const Max_Users = e.target.getAttribute("data-maxusers");
      const Users_Claimed = e.target.getAttribute("data-usersclaimed");
      const claimedCoupon = coupons.find(coupon => coupon.Cpn_ID === Cpn_ID);

      // Check if the coupon already exists in the claimed coupons array
      if (ClaimedCoupons.find(coupon => coupon.Cpn_ID === claimedCoupon.Cpn_ID)) {
        alert("Coupon already claimed!");
        return;
      }

      if (parseInt(Users_Claimed) === parseInt(Max_Users)) {
        alert("Maximum limit for this Coupon has been Reached");
        return;
      }

      const claimCoupon = async () => {
        // Data for API CALL
        const data = {
          "apiKey": "!IO23egz7!&!Gguow9x",
          "function_called": "claimCoupon",
          "User_ID": serverResponse.LogIn_ID,
          "Cpn_ID": Cpn_ID
        }

        const options = {
          method: 'POST',
          body: JSON.stringify(data),
          header: {
            "Content-Type": "application/json",
          }
        };
        await fetch(process.env.REACT_APP_API, options)
          .then(response => response.json())
          .then(response => {
            if (response["status"] === true) {
              setClaimedCoupons(prevCoupons => [...prevCoupons, claimedCoupon]);
              e.target.innerHTML = "CLAIMED";
            } else {

            }
          }
          )
          .catch(err => console.error(err));
      }
      claimCoupon();

    }
  }

  // Activate Account / Payment
  function ActivateAccountButton() {
    const buttonRef = useRef(null);

    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://dev.shift4.com/checkout.js';
      script.async = true;
      script.setAttribute('data-env', 'sandbox');

      script.onload = () => {
        const buttonElement = buttonRef.current;

        const handleAccountActivation = async () => {
          try {
            const response = await fetch('https://www.girls4you.co.nz/backend/payments/create_checkout.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                payment_type: 'subscription',
                Subs_Plan: serverResponse.Subs_Plan
              })
            });
            const data = await response.json();
            window.Shift4Checkout.key = 'pk_test_7FmEbOnpzwTvklX9Tn2MRioc';
            window.Shift4Checkout.success = function (result) {
              // handle successful payment by hitting the API
              fetch('https://www.girls4you.co.nz/backend/payments/payment_success.php', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  payment_type: 'subscription',
                  Subs_Plan: serverResponse.Subs_Plan,
                  LogIn_ID: username
                })
              })
                .then(response => response.json())
                .then(data => {
                  // handle response from API
                  if (data["status"] === true) {
                    window.location.reload();
                  }
                })
                .catch(error => {
                  console.error(error);
                });
            };
            window.Shift4Checkout.error = function (errorMessage) {
              // handle integration errors (e.g. send error notification to your server)
            };

            window.Shift4Checkout.open({
              checkoutRequest: data.signedCheckoutRequest,
              name: 'Buy Credits',
              description: `Activate Account`,
            });

          } catch (error) {
            console.error(error);
          }
        };

        if (buttonElement) {
          buttonElement.addEventListener('click', handleAccountActivation);
        }

        return () => {
          buttonElement.removeEventListener('click', handleAccountActivation);
        };
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, []);

    return (
      <button className='highlight-link' ref={buttonRef}>Activate Profile <i className='bx bx-refresh' ></i></button>
    );
  }

  // Logout
  const logout = async () => {
    if (window.confirm("Are You Sure To Logout")) {
      const dataForAPICall = {
        "apiKey": "!IO23egz7!&!Gguow9x",
        "function_called": "logout",
        "id": username,
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(dataForAPICall),
        header: {
          "Content-Type": "application/json",
        }
      };
      await fetch(process.env.REACT_APP_API, options)
        .then(response => response.json())
        .then(response => {
          if (response["status"] === true) {
            localStorage.removeItem("credentials");
            navigate('/auth/login');
          } else {
            alert("Failed to logout");
          }
        }
        )
        .catch(err => console.error(err));
    }
  }

  return (
    <>
      <Header handleActivePane={handleActivePane} link={"Mystery Box"} />
      <div className='cPanel-wrapper primary-bg'>
        <div className='cPanel'>
          <div className="sidebar secondary-bg">
            <div className="sidebar-top">
              <img onClick={() => {
                setActivePane('1');
              }} className='profile-photo' src={clients} alt="" />
              <label htmlFor="profile-name">{serverResponse.User_Name}</label>
              <label htmlFor="" style={{ fontSize: 'small' }}>{serverResponse.Email_ID}</label>
            </div>
            <div className="sidebar-btm">
              <ul className="dashboard-links">
                {
                  serverResponse.Payment_Status === "Pending" ? <ActivateAccountButton /> : <></>
                }
                <li id='1' className={activePane === '1' ? "active-pane-link" : "highlight-link"} onClick={handleActivePane}>Earn Rewards <i id='1' className='bx bxs-gift' ></i></li>
                <li id='2' className={activePane === '2' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>My Coupons &nbsp; <label id='2' style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", color: "black", width: "20px", height: "20px", borderRadius: "50%" }}>{ClaimedCoupons.length} </label></li>
                <li id='3' className={activePane === '3' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Video Chat <i id='3' className='bx bxs-video'></i></li>
                <li id='4' className={activePane === '4' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Shop <i id='4' className='bx bxs-shopping-bags' ></i></li>
                <li id='5' className={activePane === '5' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Track Order <i id='5' className='bx bxs-truck'></i></li>
                <li id='6' className={activePane === '6' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Reset Password <i id='6' className='bx bxs-lock-alt'></i></li>
                <li className="pane-link" onClick={logout}>Log Out <i className='bx bx-log-out' ></i></li>
              </ul>
            </div>
          </div>
          <div id='cPanel-dashboard' className="cPanel-dashboard">
            {
              (activePane === '1') ? <EarnRewards EarnRewardsProps={EarnRewardsProps} serverResponse={serverResponse} />
                : (activePane === '2') ? <MyCoupons ClaimedCoupons={ClaimedCoupons} />
                  : (activePane === '3') ? <VideoChat />
                    : (activePane === '4') ? <ShopProducts />
                      : (activePane === '5') ? <TrackOrderForm />
                        : (activePane === '6') ? <ResetPassword email={serverResponse.Email_ID} />
                          : <EarnRewards EarnRewardsProps={EarnRewardsProps} />
            }
          </div>
        </div>
      </div>
    </>
  )
}
