import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Banner from '../components/Banner'
import Grid from '../components/Grid'
import Filters from '../components/Filters';
import Slider from '../components/Slider';
import AdultContentWarning from '../components/AdultContentWarning';

export default function Home() {
  document.title = "Girls 4 You | Home";

  // Fetching All Profiles From Server
  const [serverResponse, setServerResponse] = useState([]);
  const [isAllProfilesLoaded, setIsAllProfilesLoaded] = useState();

  useEffect(() => {
    setIsAllProfilesLoaded(false);
    async function fetchProfiles() {
      const data = {
        apiKey: '!IO23egz7!&!Gguow9x',
        function_called: 'fetchProfiles',
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await fetch(process.env.REACT_APP_API, options);
        const json = await response.json();
        if (json.status === true) {
          setServerResponse(json.data);
          setIsAllProfilesLoaded(true);
        } else {
          setServerResponse([]);
          setIsAllProfilesLoaded(true);

        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchProfiles();
  }, []);
  const cities = [...new Set(serverResponse.map(profile => profile.City))];

  // Props For Banner
  const BannerProps = {
    BannerFor: "Home",
    h1: "The Sexiest Adult Service Platform.",
    h2: "100% kiwi owned and operated",
    buttonText: "Create Account"
  }

  // Filters
  const [filter, setFilter] = useState({
    City: "",
    Gender: "",
    Geography: "",
    Is_Photos_Verified: ""
  })
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  const basicProfiles = serverResponse.filter(profile =>
    (filter.City === "" || profile.City === filter.City) &&
    (filter.Gender === "" || profile.Gender === filter.Gender) &&
    (filter.Geography === "" || profile.Geography === filter.Geography) &&
    (filter.Is_Photos_Verified === "" || profile.Is_Photos_Verified === filter.Is_Photos_Verified) &&
    profile.Subs_Category === 'BASIC').sort((a, b) => new Date(b.Profile_Boosted_At) - new Date(a.Profile_Boosted_At));

  const diamondProfiles = serverResponse.filter(profile =>
    (filter.City === "" || profile.City === filter.City) &&
    (filter.Gender === "" || profile.Gender === filter.Gender) &&
    (filter.Geography === "" || profile.Geography === filter.Geography) &&
    (filter.Is_Photos_Verified === "" || profile.Is_Photos_Verified === filter.Is_Photos_Verified) &&
    profile.Subs_Category === 'DIAMOND').sort((a, b) => new Date(b.Profile_Boosted_At) - new Date(a.Profile_Boosted_At));


  const supremeProfiles = serverResponse.filter(profile =>
    (filter.City === "" || profile.City === filter.City) &&
    (filter.Gender === "" || profile.Gender === filter.Gender) &&
    (filter.Geography === "" || profile.Geography === filter.Geography) &&
    (filter.Is_Photos_Verified === "" || profile.Is_Photos_Verified === filter.Is_Photos_Verified) &&
    profile.Subs_Category === 'SUPREME').sort((a, b) => new Date(b.Profile_Boosted_At) - new Date(a.Profile_Boosted_At));

  const girlsOfTheDay = serverResponse.filter(profile =>
    (profile.Profile_Boosted_At != null) && // Exclude profiles with NULL values for Profile_Boosted_At
    (filter.City === "" || profile.City === filter.City) &&
    (filter.Gender === "" || profile.Gender === filter.Gender) &&
    (filter.Geography === "" || profile.Geography === filter.Geography) &&
    (filter.Is_Photos_Verified === "" || profile.Is_Photos_Verified === filter.Is_Photos_Verified)
  ).sort((a, b) => new Date(b.Profile_Boosted_At) - new Date(a.Profile_Boosted_At)).slice(0, 6);


  const BasicProfiles = {
    heading: "BASIC ESCORTS",
    icon: "basic-icon",
    profiles: basicProfiles
  }

  const DiamondProfiles = {
    heading: "DIAMOND ESCORTS",
    icon: "diamond-icon",
    profiles: diamondProfiles
  }

  const SupremeProfiles = {
    heading: "SUPREME ESCORTS",
    icon: "supreme-icon",
    profiles: supremeProfiles
  }

  const GirlsOfTheDay = {
    heading: "Girls Of The Day",
    icon: "supreme-icon",
    profiles: girlsOfTheDay
  }

  return (
    <div className='primary-bg'>
      <AdultContentWarning/>
      <Header />
      <Banner banner={BannerProps} />
      <div style={{ padding: "1em" }}>
        <Filters filter={filter} onChange={handleFilterChange} cities={cities} /><br />
        {
          girlsOfTheDay.length > 0 ? <Slider SliderData={GirlsOfTheDay} /> : <></>
        }

        {
          (isAllProfilesLoaded === false) ?
            <div className=' primary-bg'>
              <div className="blank_area">
                <div className="loader"></div>
              </div>
            </div> :
            <div>
              {
                supremeProfiles.length > 0 ? <Grid GridData={SupremeProfiles} /> : <></>
              }
              {
                diamondProfiles.length > 0 ? <Grid GridData={DiamondProfiles} /> : <></>
              }
              {
                basicProfiles.length > 0 ? <Grid GridData={BasicProfiles} /> : <></>
              }
            </div>
        }
      </div>
    </div>
  )
}
