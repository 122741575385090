import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Header from '../components/Header';

export default function OrderConfirmation() {
    document.title = "Girls 4 You | Order Confirmation";

    const { order_id } = useParams();
    const { status } = useParams();
    const handleCopy = (event) => {
        const text = event.target.textContent;
        navigator.clipboard.writeText(text);
        alert(`Copied "${text}" to clipboard`);
    }
    useEffect(() => {
        if (status === "success") {
            localStorage.removeItem("cart_items");
        }
    }, [status]);
    return (
        <>
            <Header />
            {status === "success" ?
                <div className='primary-bg' style={{
                    minHeight: "60vh",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div className='order-confirmation' style={{
                        maxWidth: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2em",
                        padding: "2em",
                        backgroundColor: "rgba(73, 73, 73, 0.266)",
                        borderRadius: "0.4em",
                    }} >
                        <h1 style={{ textAlign: "center", textTransform: "capitalize" }} className='secondary-text'>Thank you for placing your order!</h1>
                        <p style={{ textAlign: "center", textTransform: "capitalize" }} className='gray-text'>Your order has been placed successfully.</p>
                        <label style={{ color: "var(--accent)", cursor: "pointer" }} htmlFor="orderId" onClick={handleCopy}>Order ID: {order_id}</label>
                        <label style={{ textAlign: "center", textTransform: "capitalize", fontSize: "small" }} htmlFor="text" className='secondary-text'>Copy & Save This Order ID To Track Order</label>
                    </div>
                </div>
                :
                <div className='primary-bg' style={{
                    minHeight: "60vh",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div className='order-error' style={{
                        maxWidth: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2em",
                        padding: "2em",
                        backgroundColor: "rgba(73, 73, 73, 0.266)",
                        borderRadius: "0.4em",
                    }}>
                        <h1 style={{ textAlign: "center", textTransform: "capitalize" }} className='accent-text'>We're sorry, but there was a problem placing your order.</h1>
                        <p style={{ textAlign: "center", textTransform: "capitalize" }} className='gray-text'>Please try again later.</p>
                    </div>

                </div>
            }
        </>
    )
}
