import React from 'react'
import '../components/css/services.css'
import Header from '../components/Header';

export default function Services() {
  document.title = "Girls 4 You | Services";
  return (
    <>
      <Header />
      <div className='primary-bg'>
        <div className="services">
          <div className="service-wrapper">
            <div className="photoshoot-service">
              <div className="photoshoot-grid">
                {/* Item 1 */}
                <div className='photoshoot-grid-item'>
                  <h1 className='secondary-text'>Professional Photoshoot Service</h1>
                  <p>We work with you to create a personalized photoshoot that showcases your unique personality and style. The end result is a collection of high-quality photos that you can use to attract more clients and build your business.</p>
                  <button className='primary-btn secondary-bg'>Let's Collab</button>
                </div>
                {/* Item 2 */}
                <div className='photoshoot-grid-item'>
                  <img src="https://images.pexels.com/photos/13786273/pexels-photo-13786273.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                </div>
                {/* Item 3 */}
                <div className='photoshoot-grid-item'>
                  <img src="https://images.pexels.com/photos/8282136/pexels-photo-8282136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                </div>
                {/* Item 4 */}
                <div className='photoshoot-grid-item'>
                  <h2 className='secondary-text'>Why You Need Professional Photos</h2>
                  <p>As an escort, your photos are one of the most important aspects of your profile. They are often the first thing potential clients see when they come across your profile, and they play a crucial role in whether or not someone decides to book you.</p>
                  <p>Professional photos can make a huge difference in the number of clients you attract and the quality of those clients. They show that you take your work seriously and that you are invested in presenting yourself in the best possible way.</p>
                  <button className='primary-btn secondary-bg'>Yeah, You're Right</button>
                </div>
                {/* Item 5 */}
                <div className='photoshoot-grid-item'>
                  <h2 className='secondary-text'>What We Offer</h2>
                  <p>Our professional photoshoot services are designed to help you create the perfect profile. We work with experienced photographers who know how to capture your best angles and present you in the most flattering way possible.</p>
                  <p>Our services include:</p>
                  <ul>
                    <li>Indoor and outdoor photoshoots</li>
                    <li>Multiple outfit changes</li>
                    <li>Professional hair and makeup services</li>
                    <li>Retouching and editing</li>
                  </ul>
                  <button className='primary-btn secondary-bg'>Wow! Interesting</button>
                </div>
                {/* Item 6 */}
                <div className='photoshoot-grid-item'>
                  <img src="https://images.pexels.com/photos/6003752/pexels-photo-6003752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                </div>

                {/* Item 7 */}
                <div className='photoshoot-grid-item'>
                  <img src="https://images.pexels.com/photos/13644117/pexels-photo-13644117.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                </div>
                {/* Item 8 */}
                <div className='photoshoot-grid-item'>
                  <h2 className='secondary-text'>Contact Us</h2>
                  <p>If you're interested in booking a professional photoshoot with us, please get in touch. We'd be happy to answer any questions you have and help you schedule a session.</p>
                  <p>Contact us at:</p>
                  <ul>
                    <li>Email: gcgtechconz@gmail.com</li>
                    <li>Address: 540 MT Albert Rd, Three Kings Plaza, Auckland.</li>
                  </ul>
                  <button className='primary-btn secondary-bg'>Contact Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
