import React from 'react'
import './css/filters.css'


export default function Filters(props) {

    return (
        <div className='apply-filters'>
            <div className='filters'>
                <label htmlFor="applyFilter" className='gray-text'>Filters </label>
                <select name="City" id="City" onChange={props.onChange}>
                    <option value="">All Cities</option>
                    {
                        props.cities.map((city) => {
                            return (
                                <option key={city} value={city}>{city}</option>
                            )
                        })
                    }
                </select>

                <select name="Gender" id="Gender" onChange={props.onChange}>
                    <option value="">All Gender</option>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Trans">Trans</option>
                </select>

                <select name="Geography" id="Geography" onChange={props.onChange}>
                    <option value="">All Geographies</option>
                    <option value="Asian">Asian</option>
                    <option value="Non Asian">Non Asian</option>
                </select>

                <select name="Is_Photos_Verified" id="Is_Photos_Verified" onChange={props.onChange}>
                    <option value="">Verified / Not Verified Photos</option>
                    <option value="YES">Verified Photos Only</option>
                </select>
            </div>


        </div>
    )
}
