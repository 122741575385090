import React from 'react'
import '../components/css/shop.css'
import { useState, useEffect } from 'react'
import Banner from '../components/Banner'
import noImage from '../img/no_image.jpg'
import { generatePath, useNavigate } from 'react-router-dom'

export default function ShopProducts() {
    document.title = "Girls 4 You | Shop";
    const [isPageLoaded, updateIsPageLoaded] = useState();
    const navigate = useNavigate();

    // Getting Products from database
    const [products, setProducts] = useState([]);
    useEffect(() => {
        const fetchProducts = async () => {

            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "fetchAllActiveProducts",
            }
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        updateIsPageLoaded(true);
                        setProducts(response["data"])
                    } else {
                        updateIsPageLoaded(false);
                    }
                }
                )
                .catch(err => console.error(err));
        }
        fetchProducts()
    }, []);

    // Showing Product Details
    const showProduct = (e) => {
        navigate(generatePath("/shop/:id", { id: e.target.getAttribute('data-id') }))
    }

    const BannerProps = {
        BannerFor: "Shop",
        h1: "Indulge Your Desires & Unlock Your Pleasure",
        h2: "Experience ultimate pleasure with our curated collection of sex toys.",
        buttonText: "Explore Toys"
    }
    return (
        <div className="shop primary-bg">
            {
                (isPageLoaded === true || isPageLoaded === false) ?
                    <div className="shop-wrapper">
                        <Banner banner={BannerProps} />
                        <div id='products-grid' className="products-grid">
                            {
                                products.map((product, index) => {
                                    return (
                                        <div onClick={showProduct} className="product-card" data-id={product.p_ID} key={product.p_ID}>
                                            <img data-id={product.p_ID} src={product.thumbnail === null ? noImage : product.thumbnail} alt="" />
                                            <div data-id={product.p_ID} className='product-info'>
                                                <label data-id={product.p_ID} htmlFor="pname">{product.p_name}</label>
                                                <label data-id={product.p_ID} className='secondary-text' htmlFor="p_price">$ {product.p_price}</label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    <div className='blank_area primary-bg' style={{ minHeight: "100vh" }}>
                        <div className="loader"></div>
                    </div>
            }



        </div>
    )
}
