import React, { useEffect, useState } from 'react'
import Grid from '../components/Grid'
import Header from '../components/Header';

export default function Agency() {
  document.title = "Girls 4 You | Agencies";
  let API = process.env.REACT_APP_API;
  // Agency Accounts


  const [agencyAccounts, setAgencyAccounts] = useState([]);
  const [isPageLoaded, updateIsPageLoaded] = useState();


  const passDataForAgencyProfiles = {
    "apiKey": "!IO23egz7!&!Gguow9x",
    "function_called": "getAgencyAccounts"
  }

  const optionsForAgencyProfilesCall = {
    method: 'POST',
    body: JSON.stringify(passDataForAgencyProfiles),
    header: {
      "Content-Type": "application/json",
    }
  };

  const fetchAgencyProfiles = async (url) => {
    fetch(url, optionsForAgencyProfilesCall)
      .then(response => response.json())
      .then(response => {
        if (response["status"] === true) {
          setAgencyAccounts(response["data"]);
          updateIsPageLoaded(true);
        } else {
          setAgencyAccounts([]);
          updateIsPageLoaded(false);
        }
      }
      )
      .catch(err => console.error(err));
  }

  useEffect(() => {
    fetchAgencyProfiles(API);
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const dataForGrid = {
    heading: "AGENCIES",
    icon: "agency-icon",
    profiles: agencyAccounts
  }
  return (
    <>
      <Header />
      <div className='agency-container primary-bg' style={{ padding: "1em" }}>
        {
          (isPageLoaded === true || isPageLoaded === false) ?
            <Grid GridData={dataForGrid} />
            :
            <div className="blank_area primary-bg" style={{ minHeight: "100vh" }}>
              <div className="loader"></div>
            </div>
        }
      </div>
    </>
  )
}
