import React from 'react'
import './css/layout.css'
import Card from './Card'

export default function Grid(props) {
    return (
        <div className='Grid-Template primary-bg'>
            <div className="wrapper">
                <h4 className='secondary-text'>{props.GridData.heading} <label htmlFor="heading-icon" className={props.GridData.icon}></label></h4><br /><br />
                <div className="grid">
                    {
                        props.GridData["profiles"].map(item => {
                            return (
                                <div className="grid-item" key={item.LogIn_ID}>
                                    <Card cardInfo={item} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
