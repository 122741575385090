import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
// import servicesDashboard from '../img/services-dashboard.svg'
import subscriptionDashboard from '../img/subscription-dashboard.svg'
import "../components/css/mystery.css";
import betterLuck from '../img/better-luck-next-time.png'
import money from '../img/money.png'
import resetpassword from '../img/reset-password.png'
import '../components/css/cPanel.css'
import axios from "axios";
import Header from './Header';

const PersonalInfo = (props) => {
    const updateProile = async () => {
        function isValidEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        }

        function isValidPhoneNumber(phoneNumber) {
            const phoneRegex = /^\d+$/; // Matches any numeric string
            return phoneRegex.test(phoneNumber);
        }

        if (!isValidEmail(props.RequiredData.profile["Email_ID"])) {
            // show an error message to the user
            alert('Please enter a valid email address');
            return;
        }

        if (!isValidPhoneNumber(props.RequiredData.profile["Contact_No"])) {
            // show an error message to the user
            alert('Mobile Number must be numeric');
            return;
        }

        if (props.RequiredData.profile["User_Name"].trim().length === 0 || props.RequiredData.profile["Charges"].trim().length === 0 || props.RequiredData.profile["Charges30min"].trim().length === 0 || props.RequiredData.profile["Region"].trim().length === 0 || props.RequiredData.profile["Age"].trim().length === 0 || props.RequiredData.profile["Weight"].trim().length === 0 || props.RequiredData.profile["Height"].trim().length === 0) {
            alert("Please Fill Out All Required Fields");
            return;
        }

        if (isNaN(props.RequiredData.profile["Charges"].trim()) || isNaN(props.RequiredData.profile["Charges30min"].trim()) || isNaN(props.RequiredData.profile["Age"].trim()) || isNaN(props.RequiredData.profile["Weight"].trim()) || isNaN(props.RequiredData.profile["Height"].trim())) {
            alert("Check All Inputs Are Valid");
            return;
        }

        if ((props.RequiredData.profile["Nationality"] === null || props.RequiredData.profile['Nationality'].trim().length < 1)) {
            alert("Please Fill Your Nationality");
            return
        }

        // Data 
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "updateProfile",
            "Account_Type": props.RequiredData.profile["Account_Type"],
            "LogIn_ID": props.RequiredData.profile["LogIn_ID"],
            "profile": props.RequiredData.profile
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    alert("Profile Updated Successfully")
                } else {
                    console.log(response);
                }
            }
            )
            .catch(err => console.error(err));
    }
    return (
        <>
            <h2 className='panel-heading'>Welcome, {props.RequiredData.profile["User_Name"]} <button onClick={updateProile}>Save <i className='bx bxs-cog' ></i></button></h2>
            <div className="profile-basic-info grid-layout">
                <div className="grid-div ">
                    <table>
                        <thead>
                            <tr>
                                <th>Personal Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td><input type="text" name='User_Name' id='User_Name' onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["User_Name"]} /></td>
                            </tr>
                            <tr>
                                <td>Charges<sub> / 30min (In. $)</sub></td>
                                <td><input type="text" name='Charges30min' id='Charges30min' onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Charges30min"]} /></td>
                            </tr>
                            <tr>
                                <td>Charges<sub> / Hour (In. $)</sub></td>
                                <td><input type="text" name='Charges' id='Charges' onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Charges"]} /></td>
                            </tr>
                            <tr>
                                <td>Contact</td>
                                <td><input type="text" name='Contact_No' id='Contact_No' onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Contact_No"]} /></td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td><input style={{ textTransform: "lowercase" }} type="text" name='Email_ID' id='Email_ID' onChange={() => { alert("You cannot change email address") }} value={props.RequiredData.profile["Email_ID"]} /></td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td>
                                    <select name="City" id="City" value={props.RequiredData.profile["City"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>Choose City</option>
                                        <option value="Auckland">Auckland</option>
                                        <option value="Wellington">Wellington</option>
                                        <option value="Cristchurch">Cristchurch</option>
                                        <option value="Hamilton">Hamilton</option>
                                        <option value="Central Nth Island">Central Nth Island</option>
                                        <option value="South Island">South Island</option>
                                        <option value="Tauranga">Tauranga</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Location</td>
                                <td><input type="text" name='Region' id='Region' onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Region"]} /></td>
                            </tr>
                            <tr>
                                <td>Geography</td>
                                <td>
                                    <select name="Geography" id="Geography" value={props.RequiredData.profile["Geography"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>I'm </option>
                                        <option value="Asian">Asian</option>
                                        <option value="Non Asian">Non Asian</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Nationality
                                </td>
                                <td><input type="text" name='Nationality' id='Nationality' onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Nationality"] === null ? '' : props.RequiredData.profile["Nationality"]} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="grid-div ">
                    <table>
                        <thead>
                            <tr>
                                <th>Meeting Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Incall</td>
                                <td>
                                    <select name="InCall" id="InCall" value={props.RequiredData.profile["InCall"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Outcall</td>
                                <td>
                                    <select name="OutCall" id="OutCall" value={props.RequiredData.profile["OutCall"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Contact Method</td>
                                <td>
                                    <select name="Preferred_Contact_Method" id="Preferred_Contact_Method" value={props.RequiredData.profile["Preferred_Contact_Method"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>Choose Contact Method</option>
                                        <option value="Call For Booking">Call For Booking</option>
                                        <option value="SMS-Only">SMS-Only</option>
                                        <option value="WhatsApp">WhatsApp</option>
                                        <option value="Email">Email</option>
                                        <option value="Viber">Viber</option>
                                        <option value="SMS Phone/WhatsApp">SMS Phone/WhatsApp</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Meeting With</td>
                                <td>
                                    <select name="Meeting_With" id="Meeting_With" value={props.RequiredData.profile["Meeting_With"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>Interested In</option>
                                        <option value="Men">Men</option>
                                        <option value="Women">Women</option>
                                        <option value="Group Sex">Group Sex</option>
                                        <option value="Men And Women">Men And Women</option>
                                        <option value="Pairs">Pairs</option>
                                        <option value="Anyone">Anyone</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th>Write A Tagline</th>
                                <th>{props.RequiredData.profile["Tagline"].length} / 75</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <textarea style={{ border: "2px solid var(--secondary-color)" }} rows="2" name="Tagline" id="Tagline" value={props.RequiredData.profile["Tagline"].replace(/<[^>]+>/g, '')} onChange={props.RequiredData.function.setValue} placeholder="Write About Yourself"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="grid-div ">
                    <table>
                        <thead>
                            <tr>
                                <th>Body Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Age <sub>(In Years)</sub></td>
                                <td><input id='Age' name='Age' type="text" onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Age"]} placeholder="Years" /></td>
                            </tr>
                            <tr>
                                <td>Weight <sub>(Kg)</sub></td>
                                <td><input id='Weight' name='Weight' type="text" onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Weight"]} placeholder="Kg" /></td>
                            </tr>
                            <tr>
                                <td>Height <sub>(cms)</sub></td>
                                <td><input id='Height' name='Height' type="text" onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile["Height"]} placeholder="cms" /></td>
                            </tr>

                            <tr>
                                <td>Gender</td>
                                <td>
                                    <select name="Gender" id="Gender" value={props.RequiredData.profile["Gender"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>Choose Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Trans">Trans</option>
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "Breast Size" : "Penis Size"}</td>
                                <td>
                                    <select id={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "Breast_Size" : "Penis_Size"} name={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "Breast_Size" : "Penis_Size"} onChange={props.RequiredData.function.setValue} value={props.RequiredData.profile[(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "Breast_Size" : "Penis_Size"]}>
                                        <option value="" disabled={true}>Choose {(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "Breast Size" : "Penis Size"}</option>
                                        <option value={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "A" : "4.5"}>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "A" : "4.5"}</option>
                                        <option value={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "B" : "5.0"}>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "B" : "5.0"}</option>
                                        <option value={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "C" : "5.5"}>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "C" : "5.5"}</option>
                                        <option value={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "D" : "6.0"}>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "D" : "6.0"}</option>
                                        <option value={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "DD" : "6.5"}>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "DD" : "6.5"}</option>
                                        <option value={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "E" : "7.0"}>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "E" : "7.0"}</option>
                                        <option value={(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "F" : "7.5"}>{(props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ? "F" : "7.5"}</option>
                                    </select>
                                </td>
                            </tr>
                            {
                                (props.RequiredData.profile["Gender"] === "Female" || props.RequiredData.profile["Gender"] === "Trans") ?
                                    <tr>
                                        <td>Breast Type</td>
                                        <td>
                                            <select name="Breast_Type" id="Breast_Type" value={props.RequiredData.profile["Breast_Type"]} onChange={props.RequiredData.function.setValue}>
                                                <option value="" disabled={true}>Choose Breast Type</option>
                                                <option value="Natural">Natural</option>
                                                <option value="Silicone">Silicone</option>
                                            </select>
                                        </td>
                                    </tr>
                                    :
                                    ""
                            }

                            <tr>
                                <td>Body Type</td>
                                <td>
                                    <select name="Body_Type" id="Body_Type" value={props.RequiredData.profile["Body_Type"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>Choose Body Type</option>
                                        <option disabled={true}>Women Type</option>
                                        <option value="Slim">Slim</option>
                                        <option value="Athletic">Athletic</option>
                                        <option value="Curvy">Curvy</option>
                                        <option value="Busty">Busty</option>
                                        <option value="BBW">BBW</option>
                                        <option value="Voluptuous">Voluptuous</option>
                                        <option disabled={true}>Men Type</option>
                                        <option value="Stocky">Stocky</option>
                                        <option value="Slim-Athletic">Slim-Athletic</option>
                                        <option value="Heavyset">Heavyset</option>
                                        <option value="Tall and Lean">Tall and Lean</option>
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>Hair Color</td>
                                <td>
                                    <select name="Hair_Color" id="Hair_Color" value={props.RequiredData.profile["Hair_Color"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>Choose Hair Color</option>
                                        <option disabled={true}>Women Type</option>
                                        <option value="Brunette">Brunette</option>
                                        <option value="Blonde">Blonde</option>
                                        <option value="Red">Red</option>
                                        <option value="Black">Black</option>
                                        <option value="Other">Other</option>
                                        <option disabled={true}>Men Type</option>
                                        <option value="Straight Hair">Straight Hair</option>
                                        <option value="Wavy Hair">Wavy Hair</option>
                                        <option value="Curly Hair">Curly Hair</option>
                                        <option value="Kinky Hair">Kinky Hair</option>
                                        <option value="Coarse Hair">Coarse Hair</option>
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>Eye Color</td>
                                <td>
                                    <select name="Eye_Color" id="Eye_Color" value={props.RequiredData.profile["Eye_Color"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>Choose Eye Color</option>
                                        <option value="Brown">Brown</option>
                                        <option value="Green">Green</option>
                                        <option value="Blue">Blue</option>
                                        <option value="Hazel">Hazel</option>
                                        <option value="Blue / Green">Blue / Green</option>
                                        <option value="Gray">Gray</option>
                                        <option value="Black">Black</option>
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>Pubic Hair</td>
                                <td>
                                    <select name="Pubic_Hair" id="Pubic_Hair" value={props.RequiredData.profile["Pubic_Hair"]} onChange={props.RequiredData.function.setValue}>
                                        <option value="" disabled={true}>I Have</option>
                                        <option value="Fully Shaved">Fully Shaved</option>
                                        <option value="Partially Shaved">Partially Shaved</option>
                                        <option value="Natural Growth">Natural Growth</option>
                                    </select>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="grid-div">
                    <table>
                        <thead>
                            <tr>
                                <th>Word Limit</th>
                                <th>{props.RequiredData.profile["Account_Description"].length} / 1500</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <textarea rows="18" name="Account_Description" id="Account_Description" value={props.RequiredData.profile["Account_Description"].replace(/<[^>]+>/g, '')} onChange={props.RequiredData.function.setValue} placeholder="Write About Yourself"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const Services = (props) => {
    // Add Service
    const addService = async () => {
        if (props.RequiredData.serviceToBeInserted["Service_Name"].trim().length !== 0 && props.RequiredData.serviceToBeInserted["Service_Charge"].trim().length !== 0) {
            props.RequiredData.functions.setServiceStatus(false);
            // Data for Fetching user services
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "addService",
                "LogIn_ID": props.RequiredData.LogIn_ID,
                "service": props.RequiredData.serviceToBeInserted["Service_Name"].trim(),
                "charge": props.RequiredData.serviceToBeInserted["Service_Charge"].trim()
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        props.RequiredData.functions.clearInputs();
                        props.RequiredData.functions.setServiceStatus(true);
                    } else {
                        console.log(response);
                    }
                }
                )
                .catch(err => console.error(err));
        } else {
            alert("Please Enter Inputs");
        }
    }

    // Delete Service
    const deleteService = async (e) => {
        if (window.confirm("Are you sure to remove this service")) {
            props.RequiredData.functions.setServiceStatus(false);
            const SR_ID = e.target.getAttribute('data-id');
            // Data 
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "deleteService",
                "serviceToBeDeleted": SR_ID
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        // remove the deleted service from the services state
                        // setServices(prevServices => prevServices.filter(service => service.SR_ID !== SR_ID));
                        props.RequiredData.functions.setServiceStatus(true);
                    } else {
                        alert("Unable to remove this service");
                    }
                }
                )
                .catch(err => console.error(err));
        }
    }

    // Set Working Days/Hours
    const [workingHours, setWorkingHours] = useState([
        { day: "Monday", startTime: "10:00", endTime: "22:00", isHoliday: false },
        { day: "Tuesday", startTime: "10:00", endTime: "22:00", isHoliday: false },
        { day: "Wednesday", startTime: "10:00", endTime: "22:00", isHoliday: false },
        { day: "Thursday", startTime: "10:00", endTime: "22:00", isHoliday: false },
        { day: "Friday", startTime: "10:00", endTime: "22:00", isHoliday: false },
        { day: "Saturday", startTime: "10:00", endTime: "22:00", isHoliday: false },
        { day: "Sunday", startTime: "10:00", endTime: "22:00", isHoliday: false },
    ]);

    // Fetching User Working Hours
    useEffect(() => {
        const fetchUserWorkingHours = async () => {
            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "fetchUserWorkingHours",
                "LogIn_ID": props.RequiredData.LogIn_ID,
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        if (JSON.parse(response["data"][0]["Working_Hours"] !== null) && JSON.parse(response["data"][0]["Working_Hours"].length) > 0) {
                            setWorkingHours(JSON.parse(response["data"][0]["Working_Hours"]));
                        }
                    }
                }
                )
                .catch(err => console.error(err));
        }
        fetchUserWorkingHours();
    }, [props.RequiredData.LogIn_ID])

    const handleStartTimeChange = (event, day) => {
        const startTime = event.target.value;
        setWorkingHours((hours) =>
            hours.map((h) => (h.day === day ? { ...h, startTime } : h))
        );
    };

    const handleEndTimeChange = (event, day) => {
        const endTime = event.target.value;
        setWorkingHours((hours) =>
            hours.map((h) => (h.day === day ? { ...h, endTime } : h))
        );
    };

    const handleHolidayChange = (event, day) => {
        const isHoliday = event.target.checked;
        setWorkingHours((hours) =>
            hours.map((h) => (h.day === day ? { ...h, isHoliday } : h))
        );
    };

    const saveWorkingHours = async (event) => {
        event.preventDefault();

        // Filter out holiday days
        // const Working_Hours = workingHours.filter((h) => !h.isHoliday);

        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "saveWorkingHours",
            "LogIn_ID": props.RequiredData.LogIn_ID,
            "Working_Hours": workingHours
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    alert("Your Availability Schedule Has Been Saved Successfully")
                } else {
                    alert("Failed To Save Your Availability Schedule");
                }
            }
            )
            .catch(err => console.error(err));
    };

    return (
        <>
            <h2 className='secondary-text'>Services <i className='bx bx-list-ol' ></i></h2>
            <div className="services-offered grid-layout">
                <div className="grid-div" style={{ width: "100%", overflowX: "auto" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th style={{ textAlign: "start" }}>Start Time</th>
                                <th>End Time</th>
                                <th style={{ textAlign: "start" }}>On Holiday</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workingHours.map(({ day, startTime, endTime, isHoliday }) => (
                                <tr key={day}>
                                    <td>{day}</td>
                                    <td>
                                        <input
                                            type="time"
                                            value={startTime}
                                            onChange={(event) => handleStartTimeChange(event, day)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="time"
                                            value={endTime}
                                            onChange={(event) => handleEndTimeChange(event, day)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={isHoliday}
                                            onChange={(event) => handleHolidayChange(event, day)}
                                        />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><button style={{ backgroundColor: "var(--secondary-color)" }} onClick={saveWorkingHours}>Set Availability</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="grid-div" style={{ width: "100%", overflowX: "auto" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Services Offered</th>
                                <th>$ Charge</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.RequiredData.services.map((service, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{service.Service_Name}</td>
                                            <td>{service.Service_Charge} <i onClick={deleteService} data-id={service.SR_ID} onMouseOver={(e) => { e.target.className = 'bx bxs-trash-alt bx-tada' }} onMouseOut={(e) => { e.target.className = 'bx bxs-trash-alt' }} className='bx bxs-trash-alt'></i></td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td className='secondary-text'><strong>Add New</strong></td>
                            </tr>
                            <tr>
                                <td style={{ border: "2px solid var(--secondary-color)", borderRadius: "0.4em", padding: "0 4px" }}><input id='Service_Name' name='Service_Name' type="text" placeholder="Service Name" value={props.RequiredData.serviceToBeInserted["Service_Name"]} onChange={props.RequiredData.functions.onServiceInput} /></td>
                                <td style={{ border: "2px solid var(--secondary-color)", borderRadius: "0.4em", padding: "0 4px" }}><input id='Service_Charge' name='Service_Charge' type="text" placeholder='Charges' value={props.RequiredData.serviceToBeInserted["Service_Charge"]} onChange={props.RequiredData.functions.onServiceInput} /></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><button style={{ backgroundColor: "var(--secondary-color)" }} onClick={addService}>Add Service <i className={(props.RequiredData.isServiceAdded === false) ? 'bx bx-loader-circle bx-spin' : (props.RequiredData.isServiceAdded === true) ? 'bx bxs-save' : 'bx bxs-save'}></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br /><br />
            <div style={{ borderRadius: "0.6em", backgroundColor: "var(--secondary-color)" }}>
                <p style={{ padding: "8px", textAlign: "justify", lineHeight: "2em", fontSize: "small" }}>
                    Dear Escorts,

                    We're excited to offer you a platform to showcase your services and connect with potential clients. Our website attracts a diverse and engaged audience, so this is a great opportunity to reach new customers and grow your business.

                    To get started, simply create a profile and list your services. Be sure to include all the details that clients might be interested in, such as your availability, pricing, and any special skills or services you offer. The more information you provide, the more likely you are to attract clients who are a good match for your services.

                    We value your privacy and safety, so we have put measures in place to ensure that your information is protected and your experience on our website is a positive one. If you have any questions or concerns, our support team is always available to assist you.

                    Thank you for choosing our website to promote your services. We look forward to helping you succeed!

                    Best regards,

                    [girls4you.co.nz]
                </p>
            </div>
        </>
    )
}

const Gallery = (props) => {
    const navigate = useNavigate();
    const [selectedImages, updateSelectedImages] = useState([]);

    const ChoosePhotos = () => {
        var selectPhotos = document.getElementById("SelectPhotos");
        selectPhotos.click();
    }

    // Upload Images
    const handleChange = (event) => {
        const selectedFilesCount = event.target.files.length;
        const uploadedFilesCount = props.RequiredData.ProfilePhotos.length;
        const totalFilesCount = selectedFilesCount + uploadedFilesCount;
        if (props.RequiredData.Subs_Plan === "BASIC365") {
            var PhotosLimit = 2;
        } else if (props.RequiredData.Subs_Plan === "DIAMOND-30") {
            PhotosLimit = 5;
        } else if (props.RequiredData.Subs_Plan === "SUPREME-30") {
            PhotosLimit = 10;
        } else if (props.RequiredData.Subs_Plan === "ADIAMOND-30") {
            PhotosLimit = 5;
        } else if (props.RequiredData.Subs_Plan === "ASUPREME-30") {
            PhotosLimit = 10;
        }

        if (totalFilesCount > PhotosLimit) {
            alert(`Limit Reached: You Can Only Upload Up To ${PhotosLimit} Photos.`);
            updateSelectedImages([]); // Clear the selected files
            return;
        }
        updateSelectedImages(event.target.files);
    };
    const Upload = async () => {
        if (selectedImages.length === 0) {
            alert("Select Images To Upload");
            return;
        }
        props.RequiredData.functions.progressStatus('Running');
        const data = new FormData();
        data.append("function_called", JSON.stringify("UploadImages"));
        data.append("apiKey", JSON.stringify("!IO23egz7!&!Gguow9x"));
        data.append("id", JSON.stringify(props.RequiredData.LogIn_ID));

        for (let i = 0; i < selectedImages.length; i++) {
            var filesize = Math.round(selectedImages[i].size / 1024);
            if (filesize > 4096) {
                alert("File too Big, please select a file less than 4mb");
                return;
            }
            data.append("file[]", selectedImages[i]);
        }


        let url = "https://www.girls4you.co.nz/backend/Photos_Upload.php";
        axios
            .post(url, data, {
                // receive two parameter endpoint url ,form data
            })
            .then((res) => {
                // then print response status
                if (res.data.status === true) {
                    updateSelectedImages([]);
                    props.RequiredData.functions.progressStatus('Done');
                } else {
                    console.log(res.data);
                }
            }, error => {
                alert(error);
            });
    }

    // Delete Photos
    const deletePhoto = async (e) => {
        if (window.confirm("Are You Sure To Delete?")) {
            if (props.RequiredData.defaultThumbnail === e.target.getAttribute('photo-url')) {
                alert("Default Photo Can't Be Deleted");
                return;
            }
            props.RequiredData.functions.progressStatus('Running');
            // Data 
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "deleteFile",
                "id": props.RequiredData.LogIn_ID,
                "fileToBeDeleted": e.target.getAttribute('photo-url')
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                headers: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        props.RequiredData.functions.progressStatus('Done');
                    } else {
                        alert("Unable To Delete Photo");
                    }
                }
                )
                .catch(err => console.error(err));
        }
    }

    // Video
    // Upload Video  
    const [videoUploadingProgress, setvideoUploadingProgress] = useState(null);

    const selectVideo = () => {
        var selectVideo = document.getElementById("video");
        selectVideo.click();
    }

    const VideoHandler = (event) => {
        if (event.target.files[0].size / 1024 > 10240) {
            alert("File too Big, please select a video less than 10 MB");
            return;
        }

        setvideoUploadingProgress("Running");
        const Reader = new FileReader();
        Reader.onload = () => {
            props.RequiredData.functions.setVideoProfile(Reader.result); // String Base64  
        };
        Reader.readAsDataURL(event.target.files[0]);

        const data = new FormData();
        data.append("function_called", JSON.stringify("UploadVideo"));
        data.append("apiKey", JSON.stringify("!IO23egz7!&!Gguow9x"));
        data.append("id", JSON.stringify(props.RequiredData.LogIn_ID));
        data.append("fileToBeDeleted", JSON.stringify(document.getElementById("videoPreview").getAttribute('video-url')));
        data.append("file[]", event.target.files[0]);

        let url = "https://www.girls4you.co.nz/backend/Video_Upload.php";
        axios
            .post(url, data, {
                // receive two parameter endpoint url ,form data
            })
            .then((res) => {
                // then print response status
                if (res.data.status === true) {
                    alert(res.data.message);
                    setvideoUploadingProgress("Done");
                } else {
                    console.log(res.data);
                }
            }, error => {
                alert(error);
            });
    }

    return (
        <div className='gallery'>
            <h2 className='secondary-text'>Gallery <i className='bx bxs-photo-album'></i></h2>
            {/* Video Profile */}
            <div className="grid-layout">
                <div className='colm-flex' style={{ alignItems: "flex-start" }}>
                    <h2 className='secondary-text'>
                        Attract More Clients With Video Profile
                    </h2>
                    <p style={{ lineHeight: "1.6em" }} className='gray-text'>Video profiles attract clients. Upload one to increase your chances.</p>
                    <button style={{ fontSize: "small" }} onClick={props.RequiredData.Subs_Plan === "BASIC365" ? () => { alert("Free Accounts Are Not Eligible To Upload Video Profile") } : selectVideo}>Select Video <i className={(videoUploadingProgress === 'Running') ? 'bx bx-loader-circle bx-spin' : (videoUploadingProgress === "Done") ? 'bx bxs-cloud-upload' : "bx bxs-cloud-upload"} ></i></button>
                </div>
                <div className='colm-flex'>
                    <input type="file" id='video' accept='.mp4, .mkv' onChange={VideoHandler} hidden />
                    <video id='videoPreview' video-url={props.RequiredData.Video_Url} src={props.RequiredData.Video_Url} controls={props.RequiredData.Video_Url === null ? false : true} muted poster={props.RequiredData.Video_Url === null ? "https://images.pexels.com/photos/3379934/pexels-photo-3379934.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" : ""}></video>
                </div>
            </div>
            <br /><br />
            {/* Blur Faces */}
            <div className="grid-layout">
                <div className='colm-flex'>
                    <video src="https://blur.me/blurme_anim_hero_en.mp4" autoPlay loop></video>
                </div>
                <div className='colm-flex'>
                    <h2 className='secondary-text' style={{ textAlign: "center" }}>
                        Blur your face in 3 seconds,
                        Instantly!
                    </h2>
                    <p style={{ textAlign: "center", lineHeight: "1.6em" }} className='gray-text'>Do not reveal your face. Upload your picture and blur in as fast as 3 seconds</p>
                    <button style={{ fontSize: "small" }} onClick={() => { window.open("https://blur.me/en/") }}>Try Out</button>
                </div>
            </div>
            <br /> <br />
            {/* Your Photos */}
            <h2 className='panel-heading'> Your Photos
                <input type="file" id='SelectPhotos' onChange={handleChange} accept='image/jpeg, image/jpg, image/png, .jpeg, .jpg, .png' multiple hidden />
                <button style={{ fontSize: "small" }} onClick={selectedImages.length > 0 ? Upload : ChoosePhotos} id="upload">{selectedImages.length > 0 ? "Upload" : "Choose Photos"} <i className={props.RequiredData.isUploading === 'Running' ? 'bx bx-loader-circle bx-spin' : 'bx bxs-cloud-upload'} ></i></button>
            </h2>
            <br />
            <div className='preview_photos'>
                {
                    props.RequiredData.ProfilePhotos.map(photo => {
                        return (
                            <div className='photo_holder' key={photo.Photo_ID}>
                                <img src={photo.url} alt="" />
                                <div className="photo_actions">
                                    <span photo-url={photo.url} onClick={props.RequiredData.functions.setAsProfilePhoto} className='primary_photo'>Set As Default Photo</span>
                                    <span photo-url={photo.url} onClick={deletePhoto} className='delete_icon'>Delete</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <br /><br />
            {/* Display Message */}
            <div style={{ borderRadius: "0.6em", backgroundColor: "var(--secondary-color)" }}>
                <p style={{ padding: "8px", textAlign: "justify", lineHeight: "2em", fontSize: "small" }}>
                    Dear escorts,

                    We understand that presenting yourself in the best possible light is essential when it comes to attracting potential clients. That's why we want to let you know about our professional photography services. By using our services, you can showcase your best qualities and increase your chances of standing out in a crowded online marketplace.

                    Our team of experienced photographers has the skills and equipment needed to capture stunning photos and videos that showcase your unique personality and charm. We offer flexible scheduling options, competitive pricing, and exceptional customer service, so you can feel confident that you're getting the best possible service.

                    We encourage you to consider using our professional photography services to enhance your profile and attract more clients. By investing in high-quality photos and videos, you can set yourself apart from the competition and increase your visibility in the online escort community.

                    Thank you for considering our services, and we look forward to working with you soon.

                    Best regards,
                    [Girls 4 You Team]
                </p>
            </div>
            <br /><br />
            <button style={{ fontSize: "medium" }} onClick={() => { navigate('/services') }} id="upload">Get Professional Photos</button>
        </div>
    )
}

const BoostProfile = (props) => {
    const [rechargeCredits, setRechargeCredits] = useState(80);
    const [saveDataFromProps, updateSavedDataFromProps] = useState({
        Credits: props.Analytics.Credits,
        Credits_Used: props.Analytics.Credits_Used,
        Profile_Position: props.Analytics.Profile_Position,
        Profile_Boosted_At: props.Analytics.Profile_Boosted_At,
    })
    // Mystery Box Component
    const Mystery = (props) => {
        const [creditsEarned, updateCreditsEarned] = useState(0);
        const [isOpen, setIsOpen] = useState(false);
        const [awardCount, setAwardCount] = useState(0);


        const openCube = () => {
            const cube = document.querySelector("#cube");
            const cback = document.querySelector(".back");
            const ctop = document.querySelector(".top");
            const cleft = document.querySelector(".left");
            const cright = document.querySelector(".right");
            const glow = document.querySelector(".hexagon");
            const powerup = document.querySelector(".powerup");
            const message = document.querySelector("#message");
            const transitionTime = "750ms";

            ctop.style.transition = `all ${transitionTime}`;
            cleft.style.transition = `all ${transitionTime}`;
            cright.style.transition = `all ${transitionTime}`;
            cube.style.transition = `all ${transitionTime}`;
            powerup.style.transition = `all ${transitionTime}`;
            glow.style.transition = `all ${transitionTime}`;
            cback.style.transition = `all ${transitionTime}`;

            if (!isOpen) {
                award();
                ctop.style.transform = "translateY(-3rem)";
                cleft.style.transform = "translateX(-3rem)";
                cright.style.transform = "translateX(3rem)";
                ctop.style.opacity = 0.1;
                cleft.style.opacity = 0.1;
                cright.style.opacity = 0.1;
                cback.style.opacity = 0.1;
                glow.style.opacity = 0.5;
                powerup.style.opacity = 1;
                setIsOpen(true);
                cube.style.animationPlayState = "paused";
                powerup.style.zIndex = 10;
                powerup.style.height = "80px";
                powerup.style.width = "80px";
                message.style.display = 'block';
            } else {
                ctop.style.transform = "translateY(0)";
                cleft.style.transform = "translateX(0)";
                cright.style.transform = "translateX(0)";
                cube.style.opacity = 1;
                setIsOpen(false);
                ctop.style.opacity = 1;
                cleft.style.opacity = 1;
                cright.style.opacity = 1;
                cback.style.opacity = 1;
                glow.style.opacity = 1;
                powerup.style.opacity = 0;
                powerup.style.zIndex = 0;
                cube.style.animationPlayState = "running";
                powerup.style.height = "48px";
                powerup.style.width = "48px";
                changeVar("rgba(255,195,26,0.4)");
                message.style.display = 'none';
            }
        }

        const awards = [
            {
                message: "Better luck next time",
                credits: 0,
                imageUrl: betterLuck,
                color: "orange"
            },
            {
                message: "Wow, You got 1 free credit",
                credits: 1,
                imageUrl: money,
                color: "rgba(69,185,251,0.33)"
            },
            {
                message: "Congrats, You got 2 free credit",
                credits: 2,
                imageUrl: money,
                color: "rgba(69,185,251,0.33)"
            },
            {
                message: "Hurrah! You got 2 free credit",
                credits: 3,
                imageUrl: money,
                color: "rgba(69,185,251,0.33)"
            }
        ];

        // Function to check if mystery box was opened within the last 24 hours
        function isOpenedWithinLast24Hours() {
            const credentials = JSON.parse(localStorage.getItem("credentials"));
            const mysteryBox = JSON.parse(localStorage.getItem("mysteryBox"));

            if (credentials && mysteryBox) {
                const loggedInID = credentials[0]?.username;
                const lastOpenedTime = mysteryBox?.lastOpenedTime;
                const openedBy = mysteryBox?.openedBy;

                if (loggedInID && lastOpenedTime && loggedInID === openedBy) {
                    const currentTime = Date.now();
                    const timeDifference = currentTime - lastOpenedTime;
                    const hoursDifference = timeDifference / (1000 * 60 * 60);
                    return hoursDifference < 24;
                }
            }

            return false;
        }

        const award = () => {
            const cube = document.querySelector("#cube");
            const cback = document.querySelector(".back");
            const ctop = document.querySelector(".top");
            const cleft = document.querySelector(".left");
            const cright = document.querySelector(".right");
            const glow = document.querySelector(".hexagon");
            const powerup = document.querySelector(".powerup");
            const message = document.querySelector("#message");

            const transitionTime = "750ms";
            ctop.style.transition = `all ${transitionTime}`;
            cleft.style.transition = `all ${transitionTime}`;
            cright.style.transition = `all ${transitionTime}`;
            cube.style.transition = `all ${transitionTime}`;
            powerup.style.transition = `all ${transitionTime}`;
            glow.style.transition = `all ${transitionTime}`;
            cback.style.transition = `all ${transitionTime}`;

            if (!isOpenedWithinLast24Hours()) {
                const mysteryBox = {
                    lastOpenedTime: Date.now(),
                    openedBy: props.LogIn_ID
                };
                localStorage.setItem("mysteryBox", JSON.stringify(mysteryBox));

                // Randomly select an award based on the specified chances
                let randomAward;
                const random = Math.random() * 100;
                if (random < 60) {
                    randomAward = awards[1]; // 1 credit award (60% chance)
                } else if (random < 90) {
                    randomAward = awards[2]; // 2 credits award (30% chance)
                } else {
                    randomAward = awards[3]; // 3 credits award (10% chance)
                }

                powerup.style.backgroundImage = `url('${randomAward.imageUrl}')`;
                changeVar(randomAward.color);
                updateCreditsEarned(randomAward.credits);
                message.innerHTML = randomAward.message;
                document.querySelector("#claim").style.display =
                    randomAward.credits === 0 ? "none" : "block";
            } else {
                const randomAward = awards[0];
                powerup.style.backgroundImage = `url('${randomAward.imageUrl}')`;
                changeVar(randomAward.color);
                message.innerHTML = "Come Back, Tomorrow.";
                document.querySelector("#claim").style.display = "none";
            }

            ctop.style.transform = "translateY(-3rem)";
            cleft.style.transform = "translateX(-3rem)";
            cright.style.transform = "translateX(3rem)";
            ctop.style.opacity = 0.1;
            cleft.style.opacity = 0.1;
            cright.style.opacity = 0.1;
            cback.style.opacity = 0.1;
            glow.style.opacity = 0.5;
            powerup.style.opacity = 1;
            cube.style.animationPlayState = "paused";
            powerup.style.zIndex = 10;
            powerup.style.height = "80px";
            powerup.style.width = "80px";
            setAwardCount(awardCount + 1);
        };

        const changeVar = (glow) => {
            document.documentElement.style.setProperty("--glow", glow);
        };

        const updateUserCredits = async (e) => {
            const data = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "updateUserCredits",
                "id": props.LogIn_ID,
                "creditsEarned": creditsEarned
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        alert("Credits Added to Your Account")
                        updateCreditsEarned(0);
                        openCube();
                        document.querySelector("#claim").style.display = "none";
                        updateSavedDataFromProps({
                            ...saveDataFromProps,
                            "Credits": parseInt(saveDataFromProps.Credits) + creditsEarned
                        })
                    } else {
                        alert(response["data"]);
                    }
                }
                )
                .catch(err => console.error(err));
        }

        return (
            <section>
                <div id="cube" onClick={props.Subs_Plan === "BASIC365" ? () => { alert("You're Not Eligible to Open Mystery Box") } : openCube}>
                    <div className="hexagon"></div>
                    <div className="cube back"></div>
                    <div className="cube top"></div>
                    <div className="cube left"></div>
                    <div className="cube right"></div>
                    <div className="powerup"></div>
                </div>
                <p id="message" className="secondary-text"></p><br />
                <input data-credits-earned={creditsEarned} type="button" id="claim" className="primary-btn secondary-bg" value="Claim" onClick={updateUserCredits}></input>
            </section>
        );
    }
    // Boost Profile function 
    const BoostProfile = async () => {
        if (saveDataFromProps.Credits_Used === props.Analytics.Credits) {
            alert('You have reached your credit limit for this period');
            return;
        }
        if (parseInt(props.Analytics.Credits) === 0) {
            alert('Sufficient credits quota not available');
            return;
        }
        if (props.Analytics.Payment_Status === 'Pending') {
            alert('Activate Account To Boost Profile');
            return;
        }
        if (window.confirm("Boost your profile to 1st position under " + props.Analytics.Subs_Category + " profiles")) {
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "BoostProfile",
                "id": props.Analytics.LogIn_ID,
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        var Profile_Boosted_At = new Date();
                        alert("Your profile has been boosted. Congrats!!");
                        updateSavedDataFromProps({
                            ...saveDataFromProps,
                            "Credits_Used": parseInt(saveDataFromProps.Credits_Used) + 1,
                            "Profile_Position": 1,
                            "Profile_Boosted_At": Profile_Boosted_At.toLocaleString('en', { timeZone: 'Pacific/Auckland' })
                        })
                    } else {
                        alert("Failed To Launch Rocket");
                    }
                }
                )
                .catch(err => console.error(err));
        }
    }

    function CreditPurchaseButton() {
        const buttonRef = useRef(null);

        useEffect(() => {
            const script = document.createElement('script');
            script.src = 'https://dev.shift4.com/checkout.js';
            script.async = true;
            script.setAttribute('data-env', 'sandbox');

            script.onload = () => {
                const buttonElement = buttonRef.current;

                const handleCreditPurchase = async () => {
                    try {
                        let numCredits = rechargeCredits;
                        if (!numCredits) {
                            return;
                        }

                        // Validate input to ensure numCredits is a positive integer less than or equal to 50
                        numCredits = parseInt(numCredits);
                        if (isNaN(numCredits) || numCredits < 1) {
                            alert("Invalid Number Of Credits");
                            return;
                        }

                        const response = await fetch('https://www.girls4you.co.nz/backend/payments/create_checkout.php', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                payment_type: 'credits',
                                num_credits: numCredits
                            })
                        });

                        const data = await response.json();
                        window.Shift4Checkout.key = 'pk_test_7FmEbOnpzwTvklX9Tn2MRioc';
                        window.Shift4Checkout.success = function (result) {
                            // handle successful payment by hitting the API
                            fetch('https://www.girls4you.co.nz/backend/payments/payment_success.php', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    payment_type: 'credits',
                                    num_credits: numCredits,
                                    LogIn_ID: props.Analytics.LogIn_ID
                                })
                            })
                                .then(response => response.json())
                                .then(data => {
                                    // handle response from API
                                    if (data["status"] === true) {
                                        window.location.reload();
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        };
                        window.Shift4Checkout.error = function (errorMessage) {
                            // handle integration errors (e.g. send error notification to your server)
                        };

                        window.Shift4Checkout.open({
                            checkoutRequest: data.signedCheckoutRequest,
                            name: 'Buy Credits',
                            description: `Purchase ${numCredits} Credits`,
                        });
                    } catch (error) {
                        console.error(error);
                    }
                };

                if (buttonElement) {
                    buttonElement.addEventListener('click', handleCreditPurchase);
                }


                return () => {
                    buttonElement.removeEventListener('click', handleCreditPurchase);
                };
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }, []);

        return (
            <button style={{ backgroundColor: "var(--accent)", color: "white" }} ref={buttonRef}>Buy More Credits <i className='bx bxs-rocket bx-tada' ></i></button>
        );
    }

    return (
        <>
            <h2 className='secondary-text'>Boost Profile <i className='bx bxs-rocket' ></i></h2>
            <div className="profile-analytics grid-layout">

                <div className="grid-div">
                    <table>
                        <thead>
                            <tr>
                                <th>Analytics</th>
                                <th><i className='bx bxs-analyse bx-spin' ></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Credits Used <i className='bx bxs-hot' ></i></td>
                                <td>{saveDataFromProps.Credits_Used}/{saveDataFromProps.Credits}</td>
                            </tr>
                            <tr>
                                <td>Profile Views <i className='bx bxs-show' ></i></td>
                                <td>{props.Analytics.Profile_Views}</td>
                            </tr>
                            <tr>
                                <td>Profile Rank: <i className='bx bx-run'></i></td>
                                <td>
                                    {
                                        saveDataFromProps.Profile_Boosted_At === null ? "" : props.Analytics.Profile_Position === '0' ? "1" : (parseInt(props.Analytics.Profile_Position) + 1).toString()
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Last Boosted <i className='bx bxs-rocket'></i></td>
                                <td>{saveDataFromProps.Profile_Boosted_At === null ? "Not Boosted Yet" : saveDataFromProps.Profile_Boosted_At}</td>
                            </tr>
                            <tr style={{ color: "var(--secondary-color)", fontWeight: "600" }}>
                                <td>Recharge Credits</td>
                                <td><i className='bx bxs-badge-dollar'></i></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <input className='radio isHidden' type="radio" name='rechargeCredits' id='7' value={7} onChange={(e) => { setRechargeCredits(e.target.value) }} checked={rechargeCredits === 7} />
                                    <label htmlFor="7" className='label'>7 Credits For $5</label>
                                    <br />

                                    <input className='radio isHidden' type="radio" name='rechargeCredits' id='30' value={30} onChange={(e) => { setRechargeCredits(e.target.value) }} checked={rechargeCredits === 30} />
                                    <label htmlFor="30" className='label'>30 Credits For $20</label>
                                    <br />

                                    <input className='radio isHidden' type="radio" name='rechargeCredits' id='80' value={80} onChange={(e) => { setRechargeCredits(e.target.value) }} checked={rechargeCredits === 80} />
                                    <label htmlFor="80" className='label'>80 Credits For $50</label>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "var(--secondary-color)" }} onClick={BoostProfile}>Boost Profile <i className='bx bxs-rocket bx-tada' ></i></button>
                                </td>
                                <td>
                                    <CreditPurchaseButton />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <Mystery LogIn_ID={props.Analytics.LogIn_ID} Subs_Plan={props.Analytics.Subs_Plan} />
                </div>
            </div>
            <br /><br />
            <div style={{ borderRadius: "0.6em", backgroundColor: "var(--secondary-color)" }}>
                <p style={{ padding: "8px", textAlign: "justify", lineHeight: "2em", fontSize: "small" }}>
                    Dear User,

                    We hope you're enjoying your experience on our website! As you know, we offer credits that can be used to boost your profile to 1st place, and we wanted to remind you of the benefits this can bring.

                    By using your credits to boost your profile, you'll be able to get more visibility and attention from other users, potentially leading to more connections, interactions, and even opportunities. It's a great way to stand out and make a lasting impression on the community.

                    So, if you have some credits to spare, we encourage you to use them to boost your profile and take advantage of this exciting feature. Thanks for being a valued member of our community, and we look forward to seeing you at the top!

                    Best regards,

                    [girls4you.co.nz]
                </p>
            </div>
        </>
    )
}

const Subscription = (props) => {
    const [upgradeSubsPlan, upgradeSubsPlanTo] = useState(props.Subscription.Subs_Plan);
    const upgradeSubscription = async () => {
        if (props.Subscription.Is_Affiliated === 'YES') {
            alert("This account is owned by " + props.Subscription.Affiliated_To + ". We kindly request that you ask the owner to upgrade their plan. Once the owner's plan is upgraded, all related accounts' plans will also be upgraded.");
            return;
        }
        if (upgradeSubsPlan !== props.Subscription.Subs_Plan) {
            if (window.confirm("Are you sure you want to change your subscription plan? If you do, your current plan will expire.")) {
                const data = {
                    "apiKey": "!IO23egz7!&!Gguow9x",
                    "function_called": "upgradeSubscription",
                    "accountType": props.Subscription.Account_Type,
                    "LogIn_ID": props.Subscription.LogIn_ID,
                    "upgradeSubsPlanTo": upgradeSubsPlan
                }

                const options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    header: {
                        "Content-Type": "application/json",
                    }
                };
                await fetch(process.env.REACT_APP_API, options)
                    .then(response => response.json())
                    .then(response => {
                        if (response["status"] === true) {
                            alert("Subscription Plan Changed Succesfully");
                            window.location.reload();
                        } else {
                            alert("Failed To Upgrade Subscription. Please Try Again");
                        }
                    }
                    )
                    .catch(err => console.error(err));
            }
        } else {
            alert("You are Already Subscribed To This Plan")
        }
    }
    return (
        <>
            <h2 className='secondary-text'>Subscription <i className='bx bxs-dollar-circle' ></i></h2>
            <div className="subscription-details grid-layout">
                <div>
                    <img src={subscriptionDashboard} alt="" />
                </div>
                <div className="grid-div">
                    <table>
                        <tbody>
                            <tr>
                                <td>Plan Type:</td>
                                <td>{props.Subscription.Subs_Category}</td>
                            </tr>
                            <tr>
                                <td>Amount:</td>
                                <td>$ {props.Subscription.Subs_Price}</td>
                            </tr>
                            <tr>
                                <td>Payment Status:</td>
                                <td>{props.Subscription.Payment_Status}</td>
                            </tr>
                            <tr>
                                <td>Account Created On:</td>
                                <td>{props.Subscription.Account_Created}</td>
                            </tr>
                            <tr>
                                <td>Subscription Started On:</td>
                                <td>{props.Subscription.Subscription_Activated_On}</td>
                            </tr>
                            <tr>
                                <td>Expiry:</td>
                                <td>{props.Subscription.Subscription_Expiration}</td>
                            </tr>

                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <tr>
                                <th>Upgrade Plan &nbsp;<i className='bx bx-reset'></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <div className='subscription_plans' style={{ marginTop: "12px", display: "flex", flexDirection: "column", justifyContent: "space-between", flexWrap: "wrap", gap: "1em" }}>
                                        {
                                            props.Subscription.Is_Affiliated === "NO" ?
                                                <>
                                                    <div className='plan_group'>
                                                        <div className="regular_plans">
                                                            <input className="radio isHidden" type="radio" name="subs_plan" id="BASIC365" value="BASIC365" onChange={(e) => { upgradeSubsPlanTo(e.target.value) }} checked={upgradeSubsPlan === 'BASIC365'} />
                                                            <label className='label' htmlFor="BASIC365">
                                                                <span>BASIC</span>
                                                                <span>$0 <sub>/ year</sub></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='plan_group'>
                                                        <div className="diamond_plans">
                                                            <input className="radio isHidden" type="radio" name="subs_plan" id="DIAMOND-30" value="DIAMOND-30" onChange={(e) => { upgradeSubsPlanTo(e.target.value) }} checked={upgradeSubsPlan === 'DIAMOND-30'} />
                                                            <label className="label" htmlFor="DIAMOND-30">
                                                                <span>DIAMOND</span>
                                                                <span>$49 <sub>/ month</sub></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='plan_group'>
                                                        <div className="supreme_plans">
                                                            <input className="radio isHidden" type="radio" name="subs_plan" id="SUPREME-30" value="SUPREME-30" onChange={(e) => { upgradeSubsPlanTo(e.target.value) }} checked={upgradeSubsPlan === "SUPREME-30"} />
                                                            <label className="label" htmlFor="SUPREME-30">
                                                                <span>SUPREME</span>
                                                                <span>$99 <sub>/ month</sub></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    <div className='plan_group'>
                                                        <div className="diamond_plans">
                                                            <input className="radio isHidden" type="radio" name="subs_plan" id="ADIAMOND-30" value="ADIAMOND-30" onChange={(e) => { upgradeSubsPlanTo(e.target.value) }} checked={upgradeSubsPlan === 'ADIAMOND-30'} />
                                                            <label className="label" htmlFor="ADIAMOND-30">
                                                                <span>DIAMOND</span>
                                                                <span>$149 <sub>/ month</sub></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='plan_group'>
                                                        <div className="supreme_plans">
                                                            <input className="radio isHidden" type="radio" name="subs_plan" id="ASUPREME-30" value="ASUPREME-30" onChange={(e) => { upgradeSubsPlanTo(e.target.value) }} checked={upgradeSubsPlan === "ASUPREME-30"} />
                                                            <label className="label" htmlFor="ASUPREME-30">
                                                                <span>SUPREME</span>
                                                                <span>$249 <sub>/ month</sub></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                        }

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <button style={{ backgroundColor: "var(--accent)", color: "white" }} onClick={upgradeSubscription}>Upgrade Plan<i className='bx bx-reset'></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const ResetPassword = (props) => {
    // Reset Password
    const resetPassFor = props.email;
    const [OTP, setOTP] = useState("");
    const [newPass, setNewPass] = useState("");
    const [OTPSentStatus, setOTPSentStatus] = useState(false);
    const [resetMessage, setResetMessage] = useState("");
    const [LoadingIcon, showLoadingIcon] = useState(false);
    const sendOTP = async (e) => {
        e.preventDefault();
        function isValidEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        }


        if (!isValidEmail(resetPassFor)) {
            // show an error message to the user
            alert('Please enter a valid email address');
            return;
        }
        showLoadingIcon(true);
        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "sendOTP",
            "email": resetPassFor.trim(),
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setOTPSentStatus(true);
                    setResetMessage("OTP Sent Successfully");
                    showLoadingIcon(false);
                } else {
                    setResetMessage(response["data"]);
                    showLoadingIcon(false);
                }
            }
            )
            .catch(err => console.error(err));

    }

    const changeUserPassword = async (e) => {
        e.preventDefault();
        function isValidEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        }


        if (!isValidEmail(resetPassFor)) {
            // show an error message to the user
            alert('Please enter a valid email address');
            return;
        }

        showLoadingIcon(true);

        const data = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "changeUserPassword",
            "email": resetPassFor.trim(),
            "otp": OTP,
            "newPassword": newPass
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    setResetMessage(response['data']);
                    setOTP("");
                    setNewPass("");
                    setOTPSentStatus("");
                    showLoadingIcon(false);
                } else {
                    setResetMessage(response['data']);
                    showLoadingIcon(false);
                }
            }
            )
            .catch(err => console.error(err));


    }
    return (
        <>
            <div className="grid-layout" style={{ alignItems: "center" }}>
                <div>
                    <img src={resetpassword} alt="" />
                </div>
                <div className='reser_Password_form_container'>
                    <form action="" style={{ width: "90%", margin: "0 auto" }}>
                        <h4>Reset Password <i className='bx bxs-lock-alt bx-tada' ></i></h4>
                        <input className='input' type="text" id='reset_login_id' placeholder='Email' value={resetPassFor} onChange={(e) => { }} required />
                        <input className='input' type="text" placeholder='Enter OTP' onChange={(e) => { setOTP(e.target.value) }} hidden={OTPSentStatus === true ? false : true} />
                        <input className='input' type="text" placeholder='New Password' onChange={(e) => { setNewPass(e.target.value) }} hidden={OTPSentStatus === true ? false : true} />
                        <button onClick={OTPSentStatus === true ? changeUserPassword : sendOTP} className='submit_btn'>{OTPSentStatus === true ? "Change Password" : "Send OTP"} {LoadingIcon === true ? <i className='bx bx-loader-circle bx-spin' ></i> : <></>}</button>
                        <span style={{ fontSize: "small", textAlign: "center" }}>{resetMessage}</span>
                    </form>
                </div>
            </div>
            <br /><br />
            <div style={{ borderRadius: "0.6em", backgroundColor: "var(--secondary-color)", padding: "8px", textAlign: "justify", lineHeight: "2em", fontSize: "small" }}>
                <p>
                    Dear User,
                    To Reset Your Password, Please follow the steps below:
                </p>
                <ul>
                    <li>Click on the "Send OTP" button.</li>
                    <li> Shortly, an OTP will be dispatched to your registered email address.</li>
                    <li>Retrieve the OTP from your inbox and enter it in the designated field.</li>
                    <li> Upon successful authentication, you will be prompted to set a new password for your account.</li>
                    <li> Enter your new password and click on the "Change Password" button.</li>
                    <li>Upon successfully resetting your password, a confirmation message will be displayed on your screen, indicating that the password has been changed. Additionally, you may also receive a confirmation email notifying you of the password change. Please ensure that you keep your new password safe and secure.</li>
                </ul>
                <p>
                    If you have any further questions or concerns, please feel free to contact our customer support team.
                    Thank you for choosing our service.
                    <br />
                    Best regards, <br />
                    Girls4You Team <br />
                </p>
            </div>
        </>
    )
}

export default function IndividualCpanel(props) {
    // Getting data from props
    const username = props.obj.username;
    const accountType = props.obj.accountType;
    // Fetching data from server
    const [serverResponse, updateServerResponse] = useState({
        LogIn_ID: "",
        Account_Type: "",
        User_Name: "",
        Region: "",
        City: "",
        Contact_No: "",
        Email_ID: "",
        Charges: "",
        Charges30min: "",
        Age: "",
        Height: "",
        Weight: "",
        Penis_Size: "",
        Breast_Size: "",
        Breast_Type: "",
        Body_Type: "",
        Hair_Color: "",
        Eye_Color: "",
        Pubic_Hair: "",
        Geography: "",
        Nationality: "",
        Languages_Spoken: "",
        Meeting_With: "",
        Preferred_Contact_Method: "",
        InCall: "",
        OutCall: "",
        Gender: "",
        thumbnail: "",
        Is_Affiliated: "",
        Affiliated_To: "",
        Account_Description: "",
        Tagline: "",
        Account_Created: "",
        Subscription_Activated_On: "",
        Subscription_Expiration: "",
        Payment_Status: "",
        Is_Active: "",
        Last_Logged_In: "",
        Subs_Plan: "",
        Credits_Used: "1",
        Profile_Boosted_At: "",
        Video_Url: null,
        Profile_Views: "",
        ST_ID: "",
        Subs_Duration: "",
        Subs_Price: "",
        Credits: "",
        Subs_Category: "",
        Profile_Position: ""
    });
    // Setting Up props for Basic Info Component
    const [BasicInfoComponentProps, updateBasicInfoComponentProps] = useState({
        profile: serverResponse,
        function: {
            setValue: function onChangeHandler(e) {
                if (e.target.name === "Tagline") {
                    if (e.target.value.length > 75) {
                        alert("Max. Limit Reached");
                        return;
                    }
                }

                if (e.target.name === "Account_Description") {
                    if (e.target.value.length > 1500) {
                        alert("Max. Limit Reached");
                        return;
                    }
                }
                updateBasicInfoComponentProps(prevState => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        [e.target.name]: e.target.value
                    }
                }));
            }
        }
    }
    )

    useEffect(() => {
        const fetchProfileData = async () => {
            // Data for API CALL
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "fetchProfileData",
                "username": username,
                "accountType": accountType
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        updateServerResponse(response["data"][0]);
                        updateBasicInfoComponentProps(prevState => ({
                            ...prevState,
                            profile: response["data"][0]
                        })
                        )
                    } else {

                    }
                }
                )
                .catch(err => console.error(err));
        }
        fetchProfileData();
    }, [username, accountType]);


    // Managing Services
    const [isServiceAdded, setIsServiceAdded] = useState(null);
    // Hook for storing services values from server
    const [services, setServices] = useState([]);

    // Fetching Service from Server
    useEffect(() => {
        if (serverResponse.LogIn_ID === null || serverResponse.LogIn_ID.length === 0) {
            return;
        }
        const fetchUserServices = async () => {
            // Data for Fetching user services
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "Services",
                "LogIn_ID": serverResponse.LogIn_ID
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                headers: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        setServices(response["data"]);
                    }
                }
                )
                .catch(err => console.error(err));
        }
        fetchUserServices();
    }, [serverResponse.LogIn_ID]);

    // When services are loaded from server update prop for Services Component
    useEffect(() => {
        if (services) {
            setServiceProps(prevState => ({
                ...prevState,
                services: services,
                LogIn_ID: serverResponse.LogIn_ID
            })
            )
        }
    }, [services, serverResponse.LogIn_ID]);

    // Declaring Service Hook for storing service value that is to be inserted
    const [serviceToBeInserted, setServiceToBeInserted] = useState({
        Service_Name: "",
        Service_Charge: ""
    });

    // When Input values are changed for adding service form update prop for Service Component
    useEffect(() => {
        setServiceProps(prevState => ({
            ...prevState,
            serviceToBeInserted: {
                ...prevState.serviceToBeInserted,
                "Service_Name": serviceToBeInserted["Service_Name"],
                "Service_Charge": serviceToBeInserted["Service_Charge"]
            }
        }));
    }, [serviceToBeInserted]);

    useEffect(() => {
        setServiceProps(prevState => ({
            ...prevState,
            isServiceAdded: isServiceAdded,
        }))
        if (isServiceAdded === false || isServiceAdded === null) {
            return
        }
        const fetchUserServices = async () => {
            // Data for Fetching user services
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "Services",
                "LogIn_ID": serverResponse.LogIn_ID
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                headers: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        setServices(response["data"]);
                    }
                }
                )
                .catch(err => console.error(err));
        }
        fetchUserServices();
    }, [isServiceAdded, serverResponse.LogIn_ID])

    // Setting Props For Services Component
    const [serviceProps, setServiceProps] = useState({
        LogIn_ID: serverResponse.LogIn_ID,
        services: services,
        serviceToBeInserted: serviceToBeInserted,
        isServiceAdded: isServiceAdded,
        functions: {
            onServiceInput: function getServiceToBeInsertedValue(e) {
                setServiceToBeInserted(prevState => ({
                    ...prevState, [e.target.name]: e.target.value
                }))
            },
            clearInputs: function clearInputs() {
                setServiceToBeInserted(prevState => ({
                    ...prevState, "Service_Name": "",
                    "Service_Charge": ""
                }))
            },
            setServiceStatus: function setServiceStatus(status) {
                setIsServiceAdded(status);
            }
        }
    });


    // Gallery -----------------------------------------------
    const [uploadingProgress, setUploadingProgress] = useState(null);
    const [ProfilePhotos, setProfilePhotos] = useState([]);
    useEffect(() => {
        const fetchProfilePhotos = async () => {
            // Data for Fetching user services
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "ProfilePhotos",
                "id": serverResponse.LogIn_ID
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                headers: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        setProfilePhotos(response["data"]);
                    }
                }
                )
                .catch(err => console.error(err));
        }
        fetchProfilePhotos();
        setGalleryProps(prevState => ({
            ...prevState,
            LogIn_ID: serverResponse.LogIn_ID,
            defaultThumbnail: serverResponse.thumbnail,
            Video_Url: serverResponse.Video_Url,
            Subs_Plan: serverResponse.Subs_Plan
        }));
    }, [serverResponse.LogIn_ID, serverResponse.thumbnail, serverResponse.Video_Url, serverResponse.Subs_Plan]);

    useEffect(() => {
        if (ProfilePhotos === null || ProfilePhotos.length === 0) {
            return;
        }
        setGalleryProps(prevState => ({
            ...prevState,
            ProfilePhotos: ProfilePhotos
        }))
    }, [ProfilePhotos])

    useEffect(() => {
        setGalleryProps(prevState => ({
            ...prevState,
            isUploading: uploadingProgress
        }))
        if (uploadingProgress === 'Done') {
            const fetchProfilePhotos = async () => {
                // Data for Fetching user services
                const dataForAPICall = {
                    "apiKey": "!IO23egz7!&!Gguow9x",
                    "function_called": "ProfilePhotos",
                    "id": serverResponse.LogIn_ID
                }

                const options = {
                    method: 'POST',
                    body: JSON.stringify(dataForAPICall),
                    headers: {
                        "Content-Type": "application/json",
                    }
                };
                await fetch(process.env.REACT_APP_API, options)
                    .then(response => response.json())
                    .then(response => {
                        if (response["status"] === true) {
                            setProfilePhotos(response["data"]);
                        }
                    }
                    )
                    .catch(err => console.error(err));
            }
            fetchProfilePhotos();
        }
    }, [uploadingProgress, serverResponse.LogIn_ID]);

    // Set Profile Thumbnail/Photo
    const setAsProfilePhoto = async (e) => {
        var thumbnail = e.target.getAttribute('photo-url');
        // Data for Fetching user services
        const dataForAPICall = {
            "apiKey": "!IO23egz7!&!Gguow9x",
            "function_called": "setProfilePhoto",
            "id": username,
            "thumbnail": thumbnail
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(dataForAPICall),
            header: {
                "Content-Type": "application/json",
            }
        };
        await fetch(process.env.REACT_APP_API, options)
            .then(response => response.json())
            .then(response => {
                if (response["status"] === true) {
                    updateServerResponse(prevState => ({
                        ...prevState,
                        thumbnail: thumbnail
                    }));
                    alert("Profile Photo Updated Successfully");
                } else {
                    alert("Unable To Update Profile Photo");
                }
            }
            )
            .catch(err => console.error(err));
    }
    // Set Video Profile
    const setVideoProfile = (url) => {
        setGalleryProps(prevState => ({
            ...prevState,
            Video_Url: url
        }));
    }

    // Setting Props For Gallery Component
    const [galleryProps, setGalleryProps] = useState({
        LogIn_ID: serverResponse.LogIn_ID,
        defaultThumbnail: serverResponse.thumbnail,
        ProfilePhotos: ProfilePhotos,
        isUploading: uploadingProgress,
        Subs_Plan: serverResponse.Subs_Plan,
        // Video
        Video_Url: serverResponse.Video_Url,
        functions: {
            setAsProfilePhoto: setAsProfilePhoto,
            setVideoProfile: setVideoProfile,
            progressStatus: function progressStatus(status) {
                setUploadingProgress(status);
            }
        }
    });

    // Logout
    const navigate = useNavigate();
    const logout = async () => {
        if (window.confirm("Are You Sure To Logout")) {
            const dataForAPICall = {
                "apiKey": "!IO23egz7!&!Gguow9x",
                "function_called": "logout",
                "id": username,
            }

            const options = {
                method: 'POST',
                body: JSON.stringify(dataForAPICall),
                header: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(process.env.REACT_APP_API, options)
                .then(response => response.json())
                .then(response => {
                    if (response["status"] === true) {
                        localStorage.removeItem("credentials");
                        navigate('/auth/login');
                    } else {
                        alert("Failed to logout");
                    }
                }
                )
                .catch(err => console.error(err));
        }
    }

    // Set Active Panel
    const [activePane, setActivePane] = useState('1');
    const handleActivePane = (e) => {
        setActivePane(e.target.id);
        const element = document.getElementById("cPanel-dashboard");
        const offset = 60; // Set the desired offset value
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    function ActivateAccountButton() {
        const buttonRef = useRef(null);

        useEffect(() => {
            const script = document.createElement('script');
            script.src = 'https://dev.shift4.com/checkout.js';
            script.async = true;
            script.setAttribute('data-env', 'sandbox');

            script.onload = () => {
                const buttonElement = buttonRef.current;

                const handleAccountActivation = async () => {
                    try {

                        if (serverResponse.thumbnail === "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg") {
                            alert("Just One More Step! Please Set Profile Photo To Proceed.");
                            setActivePane('4');
                            return;
                        }
                        if (((serverResponse.Subs_Plan === 'BASIC365') && (serverResponse.Payment_Status === 'Pending')) || (serverResponse.Is_Affiliated === "YES")) {
                            // Data for Fetching user services
                            const dataForAPICall = {
                                "apiKey": "!IO23egz7!&!Gguow9x",
                                "function_called": "ActivateAccount",
                                "id": serverResponse.LogIn_ID,
                                "Subs_Plan": serverResponse.Subs_Plan
                            }

                            const options = {
                                method: 'POST',
                                body: JSON.stringify(dataForAPICall),
                                header: {
                                    "Content-Type": "application/json",
                                }
                            };
                            await fetch(process.env.REACT_APP_API, options)
                                .then(response => response.json())
                                .then(response => {
                                    if (response["status"] === true) {
                                        alert(response["data"]);
                                        window.location.reload();
                                    } else {
                                        alert(response["data"])
                                    }
                                }
                                )
                                .catch(err => console.error(err));
                        } else {
                            const response = await fetch('https://www.girls4you.co.nz/backend/payments/create_checkout.php', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    payment_type: 'subscription',
                                    Subs_Plan: serverResponse.Subs_Plan
                                })
                            });
                            const data = await response.json();
                            window.Shift4Checkout.key = 'pk_test_7FmEbOnpzwTvklX9Tn2MRioc';
                            window.Shift4Checkout.success = function (result) {
                                // handle successful payment by hitting the API
                                fetch('https://www.girls4you.co.nz/backend/payments/payment_success.php', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        payment_type: 'subscription',
                                        Subs_Plan: serverResponse.Subs_Plan,
                                        LogIn_ID: username
                                    })
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        // handle response from API
                                        if (data["status"] === true) {
                                            window.location.reload();
                                        }
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            };
                            window.Shift4Checkout.error = function (errorMessage) {
                                // handle integration errors (e.g. send error notification to your server)
                            };

                            window.Shift4Checkout.open({
                                checkoutRequest: data.signedCheckoutRequest,
                                name: 'Buy Credits',
                                description: `Activate Account`,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                    }
                };

                if (buttonElement) {
                    buttonElement.addEventListener('click', handleAccountActivation);
                }

                return () => {
                    buttonElement.removeEventListener('click', handleAccountActivation);
                };
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }, []);

        return (
            <button className='highlight-link' ref={buttonRef}>Activate Profile <i className='bx bx-refresh' ></i></button>
        );
    }

    return (
        <>
            <Header handleActivePane={handleActivePane} link={"Mystery Box"} />
            <div className='cPanel-wrapper primary-bg'>
                <div className='cPanel'>
                    <div className="sidebar secondary-bg">
                        <div className="sidebar-top">
                            <img onClick={() => {
                                setActivePane('4');
                            }} className='profile-photo' src={serverResponse.thumbnail} alt="" />
                            <b><span style={{ fontSize: "small", display: "inline-flex", alignItems: "center", gap: "0.4em" }}><i className='bx bx-trending-up' ></i> Rank: {serverResponse.Profile_Boosted_At === null ? "N/A" : serverResponse.Profile_Position === '0' ? "1" : (parseInt(serverResponse.Profile_Position) + 1).toString()}</span> &nbsp; <span style={{ fontSize: "small", display: "inline-flex", alignItems: "center", gap: "0.4em" }}><i className='bx bxs-face-mask'></i>Views: {serverResponse.Profile_Views}</span></b>
                        </div>
                        <div className="sidebar-btm">
                            <ul className="dashboard-links">
                                {
                                    serverResponse.Payment_Status === "Pending" ? <ActivateAccountButton /> : <></>
                                }
                                <li id='2' className={activePane === '2' ? "active-pane-link" : "highlight-link"} onClick={handleActivePane}>Boost Profile <i id='2' className='bx bxs-rocket' ></i></li>
                                <li id='1' className={activePane === '1' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Personal Info <i id='1' className='bx bxs-info-circle' ></i></li>
                                <li id='3' className={activePane === '3' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Services &nbsp; <label id='3' style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", color: "black", width: "20px", height: "20px", borderRadius: "50%" }}>{services.length} </label></li>
                                <li id='4' className={activePane === '4' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Gallery <i id='4' className='bx bxs-photo-album' ></i></li>
                                <li id='5' className={activePane === '5' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Subscription <i id='5' className='bx bxs-dollar-circle' ></i></li>
                                <li id='6' className={activePane === '6' ? "active-pane-link" : "pane-link"} onClick={handleActivePane}>Reset Password <i id='6' className='bx bxs-lock-alt' ></i></li>
                                <li className="pane-link" onClick={logout}>Log Out <i className='bx bx-log-out' ></i></li>
                            </ul>
                        </div>
                    </div>
                    <div id='cPanel-dashboard' className="cPanel-dashboard">
                        {
                            (activePane === '1') ? <PersonalInfo RequiredData={BasicInfoComponentProps} />
                                : (activePane === '2') ? <BoostProfile Analytics={serverResponse} />
                                    : (activePane === '3') ? <Services RequiredData={serviceProps} />
                                        : (activePane === '4') ? <Gallery RequiredData={galleryProps} />
                                            : (activePane === '5') ? <Subscription Subscription={serverResponse} />
                                                : (activePane === '6') ? <ResetPassword email={serverResponse.Email_ID} />
                                                    : <PersonalInfo RequiredData={BasicInfoComponentProps} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
