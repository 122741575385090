import React, { useState, useEffect } from 'react';

const AdultContentWarning = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [visitedBefore, setVisitedBefore] = useState(localStorage.getItem('visitedBefore'));

  useEffect(() => {
    if (!visitedBefore) {
      setShowPopup(true);
      document.body.style.overflow = 'hidden';
    }
  }, [visitedBefore]);

  const closePopup = () => {
    setShowPopup(false);
    setVisitedBefore(true);
    localStorage.setItem('visitedBefore', true);
    document.body.style.overflow = 'auto';
  };

  const popupStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    },
    content: {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '5px',
      maxWidth: '400px',
      textAlign: 'center',
      margin: "0 1em"
    },
    heading: {
      fontSize: '24px',
      marginBottom: '10px',
      color: "crimson"
    },
    paragraph: {
      fontSize: '16px',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#333',
      color: '#fff',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#555',
    },
  };

  return (
    <>
      {showPopup && (
        <div style={popupStyles.overlay}>
          <div style={popupStyles.content}>
            <h2 style={popupStyles.heading}>R18 Warning</h2>
            <p style={popupStyles.paragraph}>
              This website contains explicit adult content. By proceeding, you confirm that you are 18 years old or above.
            </p>
            <button
              style={popupStyles.button}
              onClick={closePopup}
            >
              Enter
            </button>
          </div>
        </div>
      )}
      {/* Rest of your website content */}
    </>
  );
};

export default AdultContentWarning;
